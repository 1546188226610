import camelize from "camelcase-keys-deep"
export default class EventType {
  constructor(data) {
    const props = camelize(data)
    Object.assign(this, props)
  }

  entity() {
    let response = null
    if (this.entityId) {
      response = this.env().Entity.findBy("id", this.entityId)
    }
    return response
  }

  // entryLots() {
  //   const lots = this.env().Lot.findAllBy("destinyProcessId", this.id)
  //   return lots
  // }
}
