// @ts-nocheck
import React from "react"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core"

const DownloadFileModal = (props) => {
  let anchorElement = React.useRef(null)

  const download = () => {
    props.toggleForm()
    anchorElement.current.click()
  }

  return (
    <Dialog
      fullWidth
      onClose={props.toggleForm}
      aria-labelledby="simple-dialog-title"
      open={props.isOpen}
    >
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <Typography>{props.description}</Typography>
        <a style={{ display: "none" }} target="_blank" href={props.url} ref={anchorElement}></a>
      </DialogContent>
      <DialogActions>
        <Button onClick={download} color="primary">
          {props.labelAction}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DownloadFileModal.defaultProps = {
  title: "Archivo de exportación",
  description: "Su archivo de exportación esta listo para descargar",
  labelAction: "Descargar",
}

export default DownloadFileModal
