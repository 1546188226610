/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
import { createAction, createReducer } from "utils/reducer";

// Local constants
const PATH = "recoveryPassword/";
const DISABLE_ERRORS = `${PATH}DISABLE_ERRORS`;
// Global constants for saga
export const RECOVERYPASSWORD = {
  POST_VERIFY_EMAIL: `${PATH}POST_VERIFY_EMAIL`,
  POST_VERIFY_EMAIL_SUCCESS: `${PATH}POST_VERIFY_EMAIL_SUCCESS`,
  POST_UPDATE_PASSWORD: `${PATH}POST_UPDATE_PASSWORD`,
  POST_UPDATE_PASSWORD_SUCCESS: `${PATH}POST_UPDATE_PASSWORD_SUCCESS`,
};

// actions
export const recoveryPasswordActions = {
  verifyEmail: createAction(RECOVERYPASSWORD.POST_VERIFY_EMAIL, "controls"),
  updatePassword: createAction(
    RECOVERYPASSWORD.POST_UPDATE_PASSWORD,
    "controls"
  ),
  disableErrors: createAction(DISABLE_ERRORS)
};

const initialState = {
  controls: {
    successChangePassword: false,
    loading: false,
    message: "",
    error: false,
  },
};

const recoveryPassword = createReducer(initialState, {
  [`${DISABLE_ERRORS}`](state, action) {
    state.controls.error = false;
    state.controls.message = "";
  },
  // api
  [RECOVERYPASSWORD.POST_VERIFY_EMAIL](state) {
    state.controls.loading = true;
    state.controls.error = false;
    state.controls.message = "";
  },
  [RECOVERYPASSWORD.POST_VERIFY_EMAIL_SUCCESS](state, action) {
    state.controls.loading = false;
    state.controls.error = false;
    state.controls.message =
      "Si su e-mail se encuentra registrado, recibirá un correo con instrucciones para reiniciar su contraseña";
  },
  [RECOVERYPASSWORD.POST_UPDATE_PASSWORD](state) {
    state.controls.loading = true;
    state.controls.error = false;
  },
  [RECOVERYPASSWORD.POST_UPDATE_PASSWORD_SUCCESS](state, action) {
    state.controls.loading = false;
    state.controls.error = false;
    state.controls.successChangePassword = true;
    state.controls.message =
      "Su contraseña ha sido actualizada correctamente, por favor inicie sesión";
  },
});

export default recoveryPassword;
