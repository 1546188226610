import { Grid, Typography } from "@material-ui/core"
import React from "react"

const BudgetCostsSummary = (props) => {
  const { quotationLaborsTotalValue, quotationSparePartsTotalValue } =
    props

  const quotationTotalValue =
    quotationLaborsTotalValue + quotationSparePartsTotalValue

  const ivaValue = Math.round(quotationTotalValue * 0.19)

  return (
    <Grid container spacing={2} style={{ "margin-top": "10px" }}>
      <Grid item sm={12} xs={12} container alignItems="flex-end" justifyContent="center">
        <Typography style={{ fontWeight: "bold", fontSize: "20px" }}>Resumen</Typography>
      </Grid>
      <Grid item sm={6} xs={6} container alignItems="flex-end" justifyContent="flex-start">
        <Typography style={{ fontWeight: "bold" }}>VALOR NETO:</Typography>
      </Grid>
      <Grid item sm={6} xs={6} container alignItems="flex-end" justifyContent="flex-end">
        <Typography style={{ fontWeight: "bold" }}>
          $ {new Intl.NumberFormat("en-DE").format(quotationTotalValue)}
        </Typography>
      </Grid>
      <Grid item sm={6} xs={6} container alignItems="flex-end" justifyContent="flex-start">
        <Typography style={{ fontWeight: "bold" }}>IVA:</Typography>
      </Grid>
      <Grid item sm={6} xs={6} container alignItems="flex-end" justifyContent="flex-end">
        <Typography style={{ fontWeight: "bold" }}>
          $ {new Intl.NumberFormat("en-DE").format(ivaValue)}
        </Typography>
      </Grid>
      <Grid item sm={6} xs={6} container alignItems="flex-end" justifyContent="flex-start">
        <Typography style={{ fontWeight: "bold" }}>TOTAL:</Typography>
      </Grid>
      <Grid item sm={6} xs={6} container alignItems="flex-end" justifyContent="flex-end">
        <Typography style={{ fontWeight: "bold" }}>
          $ {new Intl.NumberFormat("en-DE").format(ivaValue + quotationTotalValue)}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default BudgetCostsSummary
