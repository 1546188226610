import React from "react"
import { Typography } from "@material-ui/core"
import CustomDateGrid from "commons/components/CustomDateGrid"
import TableActions from "./Actions"

const TablePreventiveMaintenance = ({
  className,
  data,
  onModal,
  handleOpenUpdatedModal,
  handleOpenDeletedModal,
}) => {
  const columns = [
    { field: "name", headerName: "Nombre", flex: 1, filterable: false, sortable: false },
    {
      field: "value",
      headerName: "Valor de la mantención",
      flex: 1,
      filterable: false,
      sortable: false,
    },
    {
      field: "Actions",
      headerName: "Acciones",
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Typography component="div">
          <div className="flex-section">
            <TableActions
              id={params.row?.id}
              handleOpenModal={onModal}
              handleOpenUpdatedModal={handleOpenUpdatedModal}
              handleOpenDeletedModal={handleOpenDeletedModal}
            />
          </div>
        </Typography>
      ),

      flex: 1,
    },
  ]

  return (
    <div>
      <CustomDateGrid rows={data} columns={columns} />
    </div>
  )
}

export default TablePreventiveMaintenance
