import colors from 'utils/colors';
import commonStyles from 'utils/commonStyles';

const styles = theme => ({
root:{
marginBottom:'50px',
},

...commonStyles
});

export default styles;