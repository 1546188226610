import { takeLatest, spawn, put } from "redux-saga/effects"
import { MAINTAINERVEHICLE } from "../reducer"
import { apiSuccess, destroy, get, post, update } from "utils/api"
import { APP } from "commons/reducer"

function* getStateFromApi() {
  yield takeLatest(MAINTAINERVEHICLE.GET_STATE_FROM_API, function* (action) {
    const response = yield get("api/v1/vehicles/model-env")
    yield put(apiSuccess(MAINTAINERVEHICLE.GET_STATE_FROM_API_SUCCESS, response))
  })
}

function* getVehicleTypes() {
  yield takeLatest(MAINTAINERVEHICLE.GET_VEHICLE_TYPES, function* (action) {
    const response = yield get("api/v1/vehicle-types")

    if (!response.error) {
      yield put(apiSuccess(MAINTAINERVEHICLE.GET_STATE_FROM_API_SUCCESS, response))
    } else {
      yield put(apiSuccess(APP.SET_ERROR, response))
    }
  })
}

function* addVehicle() {
  yield takeLatest(MAINTAINERVEHICLE.POST_ADD_VEHICLE, function* (action) {
    const body = action.controls
    const response = yield post("api/v1/vehicles", body)
    if (!response.error) {
      yield put(apiSuccess(MAINTAINERVEHICLE.POST_ADD_VEHICLE_SUCCESS, response))
      yield put(apiSuccess(APP.SET_SUCCESS, response))
    } else {
      yield put(apiSuccess(MAINTAINERVEHICLE.POST_ADD_VEHICLE_ERROR, response))
      yield put(apiSuccess(APP.SET_ERROR, response))
    }
  })
}

function* updateVehicle() {
  yield takeLatest(MAINTAINERVEHICLE.POST_UPDATE_VEHICLE, function* (action) {
    const body = JSON.stringify(action.controls)
    const response = yield update(`api/v1/vehicles/${action.id}`, body)
    if (!response.error) {
      yield put(apiSuccess(MAINTAINERVEHICLE.POST_UPDATE_VEHICLE_SUCCESS, response))
      yield put(apiSuccess(APP.SET_SUCCESS, response))
    } else {
      yield put(apiSuccess(MAINTAINERVEHICLE.POST_UPDATE_VEHICLE_ERROR, response))
      yield put(apiSuccess(APP.SET_ERROR, response))
    }
  })
}

function* deleteVehicle() {
  yield takeLatest(MAINTAINERVEHICLE.DELETE_VEHICLE, function* (action) {
    const response = yield destroy(`api/v1/vehicles/${action.id}`)
    if (!response.error) {
      yield put(
        apiSuccess(MAINTAINERVEHICLE.DELETE_VEHICLE_SUCCESS, {
          item: response,
          type: "vehicles",
        })
      )
      yield put(apiSuccess(APP.SET_SUCCESS, response))
    } else {
      yield put(apiSuccess(MAINTAINERVEHICLE.DELETE_VEHICLE_ERROR, response))
      yield put(apiSuccess(APP.SET_ERROR, response))
    }
  })
}

function* enableVehicle() {
  yield takeLatest(MAINTAINERVEHICLE.PATCH_ENABLE_VEHICLE, function* (action) {
    const body = JSON.stringify(action.controls)
    const response = yield update(`api/v1/vehicles/${action.id}/enable-or-disable`, body)
    if (!response.error) {
      yield put(apiSuccess(MAINTAINERVEHICLE.PATCH_ENABLE_VEHICLE_SUCCESS, response))
      yield put(apiSuccess(APP.SET_SUCCESS, response))
    } else {
      yield put(apiSuccess(MAINTAINERVEHICLE.PATCH_ENABLE_VEHICLE_ERROR, response))
      yield put(apiSuccess(APP.SET_ERROR, response))
    }
  })
}

export default function* root() {
  yield spawn(getStateFromApi)
  yield spawn(addVehicle)
  yield spawn(updateVehicle)
  yield spawn(deleteVehicle)
  yield spawn(enableVehicle)
  yield spawn(getVehicleTypes)
}
