// @ts-nocheck
import React from "react"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core"
import ButtonWithLoader from "commons/containers/ButtonWithLoader"
import CustomField from "commons/components/CustomField"

const FormModal = (props) => {
  const { className, style, onChange, submit, data, errors } = props

  const handleChange = (event) => {
    onChange(event)
  }

  return (
    <Dialog
      fullWidth
      onClose={props.toggleForm}
      aria-labelledby="simple-dialog-title"
      open={props.isOpen}
    >
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent className={style}>
        <form onSubmit={submit}>
          <Grid container spacing={1} justify="center">
            <Grid item xs={10}>
              <Typography>{props.description}</Typography>
            </Grid>
            <Grid item xs={10}>
              <CustomField
                variant="outlined"
                className={className.field}
                label="Observación"
                key="rejectedObservation"
                id="rejectedObservation"
                multiline
                rows={5}
                rowsMax={5}
                value={data.rejectedObservation}
                error={!!errors.rejectedObservation}
                helperText={errors.rejectedObservation}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.toggleForm} color="primary">
          Cancelar
        </Button>
        <ButtonWithLoader
          autoFocus
          color={props.colorActionBtn}
          onClick={props.submit}
          buttonId={props.buttonId}
          loading={props.loading}
        >
          {props.labelAction}
        </ButtonWithLoader>
      </DialogActions>
    </Dialog>
  )
}

FormModal.defaultProps = {
  toggleForm: () => {},
  isOpen: false,
  title: "Confirmar eliminación",
  description: "¿Esta seguro que desea eliminar este registro?",
  labelAction: "Confirmar",
  colorActionBtn: "secondary",
}

export default FormModal
