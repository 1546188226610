import { takeLatest, spawn, put } from "redux-saga/effects"

import apiRequest, { apiSuccess } from "utils/api"
import { falsy } from "utils/functions"
import { LOGIN } from "../reducer"
import { history } from "utils/history"

function* login() {
  yield takeLatest(LOGIN.LOGIN_USER, function* (action) {
    const body = JSON.stringify(action.controls)
    const response = yield apiRequest("login", { method: "post", body })

    if (!response.error) {
      yield put(apiSuccess(LOGIN.LOGIN_USER_SUCCESS, response))
      localStorage.setItem("user", JSON.stringify({ token: response.token }))
    } else {
      yield put(apiSuccess(LOGIN.LOGIN_USER_FAIL, response))
    }
  })
}

function* verify() {
  yield takeLatest(LOGIN.VERIFY, function* (action) {
    const body = JSON.stringify({ token: action.token })
    const response = yield apiRequest("api/v1/users/verify", {
      method: "post",
      body,
    })
    if (!response.error) {
      yield put(apiSuccess(LOGIN.VERIFY_SUCCESS, response))
      localStorage.setItem("user", JSON.stringify({ token: response.token }))
    } else {
      yield put(apiSuccess(LOGIN.VERIFY_FAIL, response))
    }
  })
}

export default function* root() {
  yield spawn(login)
  yield spawn(verify)
}
