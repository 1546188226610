import { createAction, createReducer } from "utils/reducer"

const PATH = "budgetDetails/"

export const BUDGETDETAILS = {
  GET_BUDGET: `${PATH}GET_BUDGET`,
  GET_BUDGET_ERROR: `${PATH}GET_BUDGET_ERROR`,
  GET_BUDGET_SUCCESS: `${PATH}GET_BUDGET_SUCCESS`,

  GENERATE_EXCEL: `${PATH}GENERATE_EXCEL`,
  GENERATE_EXCEL_SUCCESS: `${PATH}GENERATE_EXCEL_SUCCESS`,
}

export const budgetDetailsActions = {
  getBudget: createAction(BUDGETDETAILS.GET_BUDGET, "controls"),
  generateExcel: createAction(BUDGETDETAILS.GENERATE_EXCEL, "controls"),
}

const initialState = {
  controls: {
    budget: {},
    loading: false,
    getInfo: false,
    error: false,
  },
}

const budgetDetails = createReducer(initialState, {
  [BUDGETDETAILS.GET_BUDGET](state, action) {
    state.controls.loading = true
    state.controls.getInfo = true
    state.controls.error = false
  },
  [BUDGETDETAILS.GET_BUDGET_SUCCESS](state, action) {
    state.controls.loading = false
    state.controls.getInfo = false
    state.controls.error = false
    state.controls.budget = action.payload.budget[0]
  },
  [BUDGETDETAILS.GET_BUDGET_ERROR](state, action) {
    state.controls.loading = false
    state.controls.getInfo = false
    state.controls.error = true
  },
  [BUDGETDETAILS.GENERATE_EXCEL_SUCCESS](state, action) {
    state.controls.loading = false;    state.controls.error = true
  },
})

export default budgetDetails
