import React from "react"
//Material
import { Button, Grid, makeStyles, Typography } from "@material-ui/core"
import { DataGrid } from "@material-ui/data-grid"
//icons
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile"
//Utils
import { fDateUtc, getItemInStorage } from "utils/functions"

//Files
import doc1 from "commons/assets/files/CERTIFICADO_DE_REFRENDACIÓN_N°605 (1).pdf"
import doc2 from "commons/assets/files/CERTIFICADO_DE_REFRENDACIÓN_N°605 (3).pdf"
import doc3 from "commons/assets/files/CERTIFICADO_DE_REFRENDACIÓN_N°605.pdf"
import doc4 from "commons/assets/files/CONTRATO_143-2023_MANTENCIÓN_Y_REPARACIÓN_REAULT_TRUCKS_DEFENSE.pdf"
import doc5 from "commons/assets/files/Declaración_jurada_Salfa.pdf"
import doc6 from "commons/assets/files/GARANTÍA_DE_FIEL_Y_OPORTUNO_CUMPLIMIENTO.pdf"
import doc7 from "commons/assets/files/INFORME_DE_JUSTIFICACIÓN.pdf"
import doc8 from "commons/assets/files/Orden de Compra 5240-320-SE23.pdf"
import doc9 from "commons/assets/files/Presentación Valorización Salfa.pdf"
import doc10 from "commons/assets/files/REQUERIMIENTOS_TECNICOS_SERVICIO_MANT._Y_REPAR._VEHÍCULOS_RENAULT (1).pdf"
import doc11 from "commons/assets/files/RESOLUCIÓN_EXENTA_N°926_ACCEDE_A_TRATO_DIRECTO (1).pdf"
import doc12 from "commons/assets/files/RESOLUCIÓN_N°01_APRUEBA_CONTRATO_N°143-2023..pdf"
import doc13 from "commons/assets/files/Valorización Mano de obra PVP.pdf"
import doc14 from "commons/assets/files/Valorización Mano de obra Sherpa.pdf"
import doc15 from "commons/assets/files/Valorización repuestos Portal.pdf"
import doc16 from "commons/assets/files/Valorización Repuestos Sherpa.pdf"
import doc17 from "commons/assets/files/IPC Junio 2024 Mano de obra PVP Reajustabilidad.pdf"
import doc18 from "commons/assets/files/IPC Junio 2024 Repuestos equipo PVP Panhard.pdf"
import doc19 from "commons/assets/files/IPC Junio 2024 Repuestos Valorizados Sherpa Reajustabilidad.pdf"
import doc20 from "commons/assets/files/IPC Junio 2024 Valor Mano de Obra Sherpa Reajustabilidad.pdf"

//Styles
import styles from "./styles"

const useStyles = makeStyles(styles)

const TableDocuments = () => {
  const { code } = getItemInStorage("contract") || {}

  const classes = useStyles()

  const columns = [
    {
      field: "name",
      headerName: "Nombre",
      flex: 4,
      renderCell: (params) => (
        <Typography component="div">
          <div className="flex-section">
            <InsertDriveFileIcon color="primary" className={classes.mr10} />
            <Typography variant="body2">{params.getValue("name")}</Typography>
          </div>
        </Typography>
      ),
    },
    {
      field: "date",
      headerName: "Fecha",
      flex: 1,
      renderCell: (params) => (
        <Typography component="div">
          <div className="flex-section">
            <p>{fDateUtc(params.getValue("date"))}</p>
          </div>
        </Typography>
      ),
    },
    {
      field: "actions",
      headerName: "Acciones",
      flex: 1,
      renderCell: (params) => {
        const filename = getFileName(params.getValue("actions"))
        return (
          <Grid container justifyContent="center">
            <Button
              variant="contained"
              color="primary"
              size="small"
              href={params.getValue("actions")}
              target="_blank"
              download={filename}
            >
              Descargar
            </Button>
          </Grid>
        )
      },
    },
  ]

  const docs = {
    contract_1: [
      { file: doc20, date: "2024-08-05" },
      { file: doc19, date: "2024-08-05" },
      { file: doc18, date: "2024-08-05" },
      { file: doc17, date: "2024-08-05" },
      { file: doc16, date: "2024-02-12" },
      { file: doc15, date: "2024-02-12" },
      { file: doc14, date: "2024-02-12" },
      { file: doc13, date: "2024-02-12" },
      { file: doc12, date: "2024-02-12" },
      { file: doc11, date: "2024-02-12" },
      { file: doc10, date: "2024-02-12" },
      { file: doc9, date: "2024-02-12" },
      { file: doc8, date: "2024-02-12" },
      { file: doc7, date: "2024-02-12" },
      { file: doc6, date: "2024-02-12" },
      { file: doc5, date: "2024-02-12" },
      { file: doc4, date: "2024-02-12" },
      { file: doc3, date: "2024-02-12" },
      { file: doc2, date: "2024-02-12" },
      { file: doc1, date: "2024-02-12" },
    ],
    contract_2: [],
    contract_3: [],
  }

  const getFileName = (str) => str.replace(/^.*[\\\/]/, "")

  const rows = (docs[code] || []).map((doc, idx) => ({
    id: idx,
    name: getFileName(doc.file),
    date: new Date(doc.date),
    actions: doc.file,
  }))

  return (
    <div style={{ height: 450, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        disableColumnMenu
        pageSize={6}
        disableSelectionOnClick
      />
    </div>
  )
}

export default TableDocuments
