import { createAction, createReducer } from "utils/reducer"

// Local constants
const PATH = "maintainer/preventivemaintenance/"

const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`
const SET_INVALID_CONTROLS = `${PATH}SET_INVALID_CONTROLS`

// Global constants for saga
export const MAINTAINERPREVENTIVEMAINTENANCE = {
  GET_STATE_FROM_API: `${PATH}GET_STATE_FROM_API`,
  GET_STATE_FROM_API_SUCCESS: `${PATH}GET_STATE_FROM_API_SUCCESS`,
  POST_ADD_PREVENTIVEMAINTENANCE: `${PATH}POST_ADD_PREVENTIVEMAINTENANCE`,
  POST_ADD_PREVENTIVEMAINTENANCE_SUCCESS: `${PATH}POST_ADD_PREVENTIVEMAINTENANCE_SUCCESS`,
  POST_ADD_PREVENTIVEMAINTENANCE_ERROR: `${PATH}POST_ADD_PREVENTIVEMAINTENANCE_ERROR`,
  POST_UPDATE_PREVENTIVEMAINTENANCE: `${PATH}POST_UPDATE_PREVENTIVEMAINTENANCE`,
  POST_UPDATE_PREVENTIVEMAINTENANCE_SUCCESS: `${PATH}POST_UPDATE_PREVENTIVEMAINTENANCE_SUCCESS`,
  POST_UPDATE_PREVENTIVEMAINTENANCE_ERROR: `${PATH}POST_UPDATE_PREVENTIVEMAINTENANCE_ERROR`,
  DELETE_PREVENTIVEMAINTENANCE: `${PATH}DELETE_PREVENTIVEMAINTENANCE`,
  DELETE_PREVENTIVEMAINTENANCE_SUCCESS: `${PATH}DELETE_PREVENTIVEMAINTENANCE_SUCCESS`,
  DELETE_PREVENTIVEMAINTENANCE_ERROR: `${PATH}DELETE_PREVENTIVEMAINTENANCE_ERROR`,

  TOGGLE_CREATE_MODAL: `${PATH}TOGGLE_CREATE_MODAL`,
  TOGGLE_UPDATED_MODAL: `${PATH}TOGGLE_UPDATED_MODAL`,
  TOGGLE_DELETED_MODAL: `${PATH}TOGGLE_DELETED_MODAL`,
}

// actions
export const maintainerPreventiveMaintenanceActions = {
  getStateFromApi: createAction(MAINTAINERPREVENTIVEMAINTENANCE.GET_STATE_FROM_API),
  addPreventiveMaintenance: createAction(
    MAINTAINERPREVENTIVEMAINTENANCE.POST_ADD_PREVENTIVEMAINTENANCE,
    "controls"
  ),
  updatePreventiveMaintenance: createAction(
    MAINTAINERPREVENTIVEMAINTENANCE.POST_UPDATE_PREVENTIVEMAINTENANCE,
    "id",
    "controls"
  ),
  deletePreventiveMaintenance: createAction(
    MAINTAINERPREVENTIVEMAINTENANCE.DELETE_PREVENTIVEMAINTENANCE,
    "id"
  ),

  toggleCreateModal: createAction(MAINTAINERPREVENTIVEMAINTENANCE.TOGGLE_CREATE_MODAL, "item"),
  toggleUpdatedModal: createAction(MAINTAINERPREVENTIVEMAINTENANCE.TOGGLE_UPDATED_MODAL, "item"),
  toggleDeletedModal: createAction(MAINTAINERPREVENTIVEMAINTENANCE.TOGGLE_DELETED_MODAL, "id"),
  changeControls: createAction(CHANGE_CONTROLS, "controls", "invalidControls"),
  setInvalidControls: createAction(SET_INVALID_CONTROLS, "controls"),
}

const defaultControls = {
  name: "",
  value: "",
  file: {},
  files: [],
}

const defaultInvalidControls = {
  name: false,
  value: false,
  files: false,
}

const initialState = {
  formControls: {
    name: "",
    value: "",
    file: {},
    files: [],
  },
  invalidControls: {
    name: false,
    value: false,
    files: false,
  },
  controls: {
    toDeleteId: "",
    toEditId: "",
    loading: false,
    error: false,
    message: "",
  },
  modal: {
    form: {
      status: false,
    },
    deleted: {
      status: false,
    },
  },
  edit: false,
}

const maintainerPreventiveMaintenance = createReducer(initialState, {
  [CHANGE_CONTROLS](state, action) {
    state.formControls = {
      ...state.formControls,
      ...action.controls,
    }
    state.invalidControls = {
      ...state.invalidControls,
      ...(action.invalidControls || {}),
    }
  },
  [SET_INVALID_CONTROLS](state, action) {
    state.invalidControls = {
      ...state.invalidControls,
      ...action.controls,
    }
  },
  [MAINTAINERPREVENTIVEMAINTENANCE.POST_ADD_PREVENTIVEMAINTENANCE](state) {
    state.controls.loading = true
    state.controls.error = false
    state.controls.message = ""
  },
  [MAINTAINERPREVENTIVEMAINTENANCE.POST_ADD_PREVENTIVEMAINTENANCE_SUCCESS](state, action) {
    state.controls.loading = false
    state.controls.error = false
    state.controls.message = "Tipo de evento agregado al sistema."
    state.controls.toDeleteId = ""
    state.controls.toEditId = ""
    state.modal.form.status = !state.modal.form.status
  },
  [MAINTAINERPREVENTIVEMAINTENANCE.POST_ADD_PREVENTIVEMAINTENANCE_ERROR](state, action) {
    state.controls.loading = false
    state.controls.error = true
    state.controls.message = "."
  },

  [MAINTAINERPREVENTIVEMAINTENANCE.POST_UPDATE_PREVENTIVEMAINTENANCE](state) {
    state.controls.loading = true
    state.controls.error = false
  },
  [MAINTAINERPREVENTIVEMAINTENANCE.POST_UPDATE_PREVENTIVEMAINTENANCE_SUCCESS](state, action) {
    state.controls.loading = false
    state.controls.error = false
    state.controls.message = "Los datos del tipo de evento fueron actualizados con exito."
    state.controls.toDeleteId = ""
    state.controls.toEditId = ""
    state.modal.form.status = !state.modal.form.status
  },
  [MAINTAINERPREVENTIVEMAINTENANCE.POST_UPDATE_PREVENTIVEMAINTENANCE_ERROR](state, action) {
    state.controls.loading = false
    state.controls.error = true
    state.controls.message = "."
  },

  [MAINTAINERPREVENTIVEMAINTENANCE.DELETE_PREVENTIVEMAINTENANCE](state, action) {
    state.controls.loading = true
    state.deleteIsOpen = false
    state.toDeleteId = ""
  },
  [MAINTAINERPREVENTIVEMAINTENANCE.DELETE_PREVENTIVEMAINTENANCE_SUCCESS](state, action) {
    state.controls.loading = false
    state.controls.error = false
    state.controls.message = "El Typo de evento fue eliminado con exito."
    state.controls.toDeleteId = ""
    state.controls.toEditId = ""
    state.modal.deleted.status = !state.modal.deleted.status
  },
  [MAINTAINERPREVENTIVEMAINTENANCE.DELETE_PREVENTIVEMAINTENANCE_ERROR](state, action) {
    state.controls.loading = false
    state.controls.error = true
    state.controls.message = "."
  },

  [MAINTAINERPREVENTIVEMAINTENANCE.TOGGLE_CREATE_MODAL](state) {
    state.controls.toDeleteId = ""
    state.controls.toEditId = ""
    state.formControls = { ...defaultControls }
    state.invalidControls = { ...defaultInvalidControls }
    state.modal.form.status = !state.modal.form.status
    state.edit = false
  },
  [MAINTAINERPREVENTIVEMAINTENANCE.TOGGLE_UPDATED_MODAL](state, action) {
    state.invalidControls = { ...defaultInvalidControls }
    state.controls.toDeleteId = ""
    state.controls.toEditId = action?.item?.id || ""
    state.formControls.name = action?.item?.name
    state.formControls.value = action?.item?.value
    state.formControls.file = action?.item?.file
    state.modal.form.status = !state.modal.form.status
    state.edit = true
  },
  [MAINTAINERPREVENTIVEMAINTENANCE.TOGGLE_DELETED_MODAL](state, action) {
    state.controls.toDeleteId = action?.id || ""
    state.controls.toEditId = ""
    state.modal.deleted.status = !state.modal.deleted.status
  },
})

export default maintainerPreventiveMaintenance
