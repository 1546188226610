import React, { useState } from "react"

import { Typography } from "@material-ui/core"
import SearchIcon from "@material-ui/icons/Search"
import CustomSelectField from "commons/components/CustomSelectField"
import ButtonWithLoader from "commons/containers/ButtonWithLoader"
import DatePicker from "commons/components/DatePicker"
import DatePickerLanguage from "commons/components/DatePickerLanguage"
import CustomField from "commons/components/CustomField"

const TableFilter = ({ className, onFilter, loading, helperFilters }) => {
  const [state, setState] = useState({
    startDate: "",
    endDate: "",
    cotizationNumber: "",
    workshopId: "",
    plateNumberVehicle: "",
    statusService: "",
    statusBudget: "",
    statusInvoice: "",
    maintenanceType: "",
    dateService: "",
  })

  const onChangeCustom = (event) => {
    setState({ ...state, [event.target.name]: event.target.value })
  }

  const handleSubmit = (event) => {
    onFilter(event, state)
  }

  return (
    <>
      <form onSubmit={handleSubmit} className={className.containerFilter}>
        <Typography component="div">
          <div className={`flex-section-wraper `}>
            <DatePickerLanguage
              style={{
                width: "auto",
                marginRight: "10px",
                margin: "8px",
                maxWidth: "220px",
              }}
              onChange={onChangeCustom}
              name={"startDate"}
              id={"startDate"}
              label={"Fecha inicial"}
              value={state.startDate}
            />
            <DatePickerLanguage
              style={{
                width: "auto",
                marginRight: "10px",
                margin: "8px",
                maxWidth: "220px",
              }}
              onChange={onChangeCustom}
              name={"endDate"}
              id={"endDate"}
              label={"Fecha final"}
              value={state.endDate}
            />

            <CustomField
              className={className.field}
              autoComplete={"off"}
              variant={"standard"}
              label={"N° Cotización"}
              key={"cotizationNumber"}
              id={"cotizationNumber"}
              name={"cotizationNumber"}
              type={"text"}
              value={state.cotizationNumber}
              onChange={onChangeCustom}
            />

            <CustomSelectField
              onChange={onChangeCustom}
              label={"Taller"}
              name={"workshopId"}
              data={helperFilters.workshops}
            />

            <CustomSelectField
              onChange={onChangeCustom}
              label={"Vehículo"}
              name={"plateNumberVehicle"}
              data={helperFilters.plateNumberVehicles}
            />

            <CustomSelectField
              onChange={onChangeCustom}
              label={"Estado del servicio"}
              name={"statusService"}
              data={helperFilters.statusServices}
            />

            <CustomSelectField
              onChange={onChangeCustom}
              label={"Estado del presupuesto"}
              name={"statusBudget"}
              data={helperFilters.statusBudgets}
            />

            <CustomSelectField
              onChange={onChangeCustom}
              label={"Estado de facturación"}
              name={"statusInvoice"}
              data={helperFilters.statusInvoices}
            />

            <CustomSelectField
              onChange={onChangeCustom}
              label={"Tipo de servicio"}
              name={"maintenanceType"}
              data={helperFilters.maintenanceTypes}
            />





            <ButtonWithLoader
              onClick={handleSubmit}
              className={className.button}
              loading={loading}
              startIcon={<SearchIcon fontSize="small" />}
            >
              Buscar
            </ButtonWithLoader>
          </div>
        </Typography>
      </form>
    </>
  )
}

export default TableFilter
