import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react"
import { getItemInStorage } from "utils/functions";
import styles from "./styles";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom'; 
const useStyles = makeStyles(styles)

const ContractHeader = () => {
  const { name, logo } = getItemInStorage("contract") || {}
  const classes = useStyles()
  const history = useHistory();
  const handleBack = () => {
    history.goBack();
  }

  return (
    <Grid container className={classes.container}>
      <Grid item>
        <Button
          onClick={handleBack} 
          startIcon={<ArrowBackIcon />}
          className={classes.back}
        >
          Volver
        </Button>
      </Grid>
      <Grid item spacing={2} container xs={12} alignItems="center">
        <Grid item>
          <img src={logo} alt={`${name} logo`} />
      </Grid>
        <Grid item>
          <Typography variant="h6" className={classes.contractName}>{name}</Typography>
        </Grid>
      </Grid>
    </Grid>
  )
};

export default ContractHeader;