import React from "react"
import { useHistory } from "react-router-dom"
import AuthUserContext from "commons/context/user"
import DropDownButton from "commons/components/DropDownButton"

const TableActions = ({ id, status }) => {
  const history = useHistory()
  const authUser = React.useContext(AuthUserContext)

  const isConfirmed = () => {
    return status?.localeCompare("Borrador")
  }

  const draftOptions = [
    {
      action: () => {
        history.push(`/budget/${id}`)
      },
      title: "Ver detalle",
      roles: ["Administrator"],
    },
    {
      action: () => {
        history.push(`/budget/${id}/edit`)
      },
      title: "Editar",
      roles: ["Administrator"],
    },
  ]

  const confirmedOptions = [
    {
      action: () => {
        history.push(`/budget/${id}`)
      },
      title: "Ver detalle",
      roles: ["Administrator"],
    },
  ]

  const filterActions = (isConfirmed() ? confirmedOptions : draftOptions).filter((option) =>
    option.roles.includes(authUser?.rol?.name)
  )

  return (
    <>
      <DropDownButton title={"Acción"} options={filterActions} />
    </>
  )
}

export default TableActions
