import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core"
import React from "react"
import WorkshopTableItem from "../WorkshopTableItem"

const WorkshopTable = (props) => {
  const { workshopLabors, updateBudgetLabor, removeBudgetLabor } = props
  const columns = [
    { id: "code", label: "CÓDIGO", align: "left" },
    { id: "description", label: "DESCRIPCIÓN", align: "center" },
    { id: "quantity", label: "UNIDADES", align: "center" },
    { id: "cost", label: "VALOR UNITARIO", align: "center" },
    { id: "total", label: "TOTAL", align: "left" },
    { id: "actions", label: "ACCIÓN", align: "center" },
  ]

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead style={{ backgroundColor: "#185517" }}>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.id} align="left" style={{ color: "white", fontSize: "15px" }}>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {workshopLabors.map((workshopLabor) => (
          <WorkshopTableItem
            key={workshopLabor.id}
            workshopLabor={workshopLabor}
            updateBudgetLabor={updateBudgetLabor}
            removeBudgetLabor={removeBudgetLabor}
          />
        ))}
      </Table>
      {workshopLabors.length === 0 && (
        <Typography
          component="div"
          align="center"
          style={{ marginBottom: "7px", marginTop: "7px" }}
        >
          No se han añadido cotizaciones
        </Typography>
      )}
    </TableContainer>
  )
}

export default WorkshopTable
