import React from "react"
import { withStyles, TextField } from "@material-ui/core"

const StyleField = withStyles({
  root: {
    paddingBottom: "0px",
  },
})(TextField)

const CustomField = (props) => {
  return (
    <>
      <StyleField variant="outlined" className={props.className} {...props} />
    </>
  )
}

CustomField.defaultProps = {
  variant: "standard",
}

export default CustomField
