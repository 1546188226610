import { createAction, createReducer } from "utils/reducer"

// Local constants
const PATH = "maintainer/vehicle/"

const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`
const SET_INVALID_CONTROLS = `${PATH}SET_INVALID_CONTROLS`

// Global constants for saga
export const MAINTAINERVEHICLE = {
  GET_STATE_FROM_API: `${PATH}GET_STATE_FROM_API`,
  GET_STATE_FROM_API_SUCCESS: `${PATH}GET_STATE_FROM_API_SUCCESS`,

  POST_ADD_VEHICLE: `${PATH}POST_ADD_VEHICLE`,
  POST_ADD_VEHICLE_SUCCESS: `${PATH}POST_ADD_VEHICLE_SUCCESS`,
  POST_ADD_VEHICLE_ERROR: `${PATH}POST_ADD_VEHICLE_ERROR`,
  POST_UPDATE_VEHICLE: `${PATH}POST_UPDATE_VEHICLE`,
  POST_UPDATE_VEHICLE_SUCCESS: `${PATH}POST_UPDATE_VEHICLE_SUCCESS`,
  POST_UPDATE_VEHICLE_ERROR: `${PATH}POST_UPDATE_VEHICLE_ERROR`,
  DELETE_VEHICLE: `${PATH}DELETE_VEHICLE`,
  DELETE_VEHICLE_SUCCESS: `${PATH}DELETE_VEHICLE_SUCCESS`,
  DELETE_VEHICLE_ERROR: `${PATH}DELETE_VEHICLE_ERROR`,
  PATCH_ENABLE_VEHICLE: `${PATH}PATCH_ENABLE_VEHICLE`,
  PATCH_ENABLE_VEHICLE_SUCCESS: `${PATH}PATCH_ENABLE_VEHICLE_SUCCESS`,
  PATCH_ENABLE_VEHICLE_ERROR: `${PATH}PATCH_ENABLE_VEHICLE_ERROR`,

  TOGGLE_CREATE_MODAL: `${PATH}TOGGLE_CREATE_MODAL`,
  TOGGLE_UPDATED_MODAL: `${PATH}TOGGLE_UPDATED_MODAL`,
  TOGGLE_DELETED_MODAL: `${PATH}TOGGLE_DELETED_MODAL`,
  TOGGLE_ENABLE_MODAL: `${PATH}TOGGLE_ENABLE_MODAL`,

  GET_VEHICLE_TYPES: `${PATH}GET_VEHICLE_TYPES`,
  CLOSE_VEHICLE_MODAL: `${PATH}CLOSE_VEHICLE_MODAL`,
}

// actions
export const maintainerVehicleActions = {
  getStateFromApi: createAction(MAINTAINERVEHICLE.GET_STATE_FROM_API),
  addVehicle: createAction(MAINTAINERVEHICLE.POST_ADD_VEHICLE, "controls"),
  updateVehicle: createAction(MAINTAINERVEHICLE.POST_UPDATE_VEHICLE, "id", "controls"),
  deleteVehicle: createAction(MAINTAINERVEHICLE.DELETE_VEHICLE, "id"),
  enableVehicle: createAction(MAINTAINERVEHICLE.PATCH_ENABLE_VEHICLE, "id", "controls"),

  toggleCreateModal: createAction(MAINTAINERVEHICLE.TOGGLE_CREATE_MODAL, "item"),
  toggleUpdatedModal: createAction(MAINTAINERVEHICLE.TOGGLE_UPDATED_MODAL, "item"),
  toggleDeletedModal: createAction(MAINTAINERVEHICLE.TOGGLE_DELETED_MODAL, "id"),
  toggleEnableModal: createAction(MAINTAINERVEHICLE.TOGGLE_ENABLE_MODAL, "id", "info"),
  changeControls: createAction(CHANGE_CONTROLS, "controls", "invalidControls"),
  setInvalidControls: createAction(SET_INVALID_CONTROLS, "controls"),

  getVehicleTypes: createAction(MAINTAINERVEHICLE.GET_VEHICLE_TYPES),
  closeVehicleModal: createAction(MAINTAINERVEHICLE.CLOSE_VEHICLE_MODAL),
}

const defaultControls = {
  vehicleTypeId: [],
  plateNumber: "",
  model: "",
  vin: "",
}

const defaultInvalidControls = {
  vehicleTypeId: false,
  plateNumber: false,
  model: false,
  vin: false,
}

const initialState = {
  formControls: {
    vehicleTypeId: null,
    plateNumber: "",
    model: "",
    vin: "",
  },
  invalidControls: {
    vehicleTypeId: false,
    plateNumber: false,
    model: false,
    vin: false,
  },
  controls: {
    toDeleteId: "",
    toEditId: "",
    toEnableId: "",
    loading: false,
    error: false,
    message: "",
  },
  modal: {
    form: {
      status: false,
    },
    deleted: {
      status: false,
    },
    enable: {
      status: false,
      info: {
        title: "",
        description: "",
        labelAction: "",
        colorActionBtn: "",
        enable: false,
      },
    },
  },
  edit: false,
}

const maintainerVehicle = createReducer(initialState, {
  [CHANGE_CONTROLS](state, action) {
    state.formControls = {
      ...state.formControls,
      ...action.controls,
    }
    state.invalidControls = {
      ...state.invalidControls,
      ...(action.invalidControls || {}),
    }
  },
  [SET_INVALID_CONTROLS](state, action) {
    state.invalidControls = {
      ...state.invalidControls,
      ...action.controls,
    }
  },
  [MAINTAINERVEHICLE.POST_ADD_VEHICLE](state) {
    state.controls.loading = true
    state.controls.error = false
    state.controls.message = ""
  },
  [MAINTAINERVEHICLE.POST_ADD_VEHICLE_SUCCESS](state, action) {
    state.controls.loading = false
    state.controls.error = false
    state.controls.message = "Taller agregado al sistema."
    state.controls.toDeleteId = ""
    state.controls.toEditId = ""
    state.modal.form.status = !state.modal.form.status
  },
  [MAINTAINERVEHICLE.POST_ADD_VEHICLE_ERROR](state, action) {
    state.controls.loading = false
    state.controls.error = true
    state.controls.message = "."
  },

  [MAINTAINERVEHICLE.POST_UPDATE_VEHICLE](state) {
    state.controls.loading = true
    state.controls.error = false
  },
  [MAINTAINERVEHICLE.POST_UPDATE_VEHICLE_SUCCESS](state, action) {
    state.controls.loading = false
    state.controls.error = false
    state.controls.message = "Los datos del taller fueron actualizados con exito."
    state.controls.toDeleteId = ""
    state.controls.toEditId = ""
    state.modal.form.status = !state.modal.form.status
  },
  [MAINTAINERVEHICLE.POST_UPDATE_VEHICLE_ERROR](state, action) {
    state.controls.loading = false
    state.controls.error = true
    state.controls.message = "."
  },

  [MAINTAINERVEHICLE.PATCH_ENABLE_VEHICLE](state) {
    state.controls.loading = true
    state.controls.error = false
  },
  [MAINTAINERVEHICLE.PATCH_ENABLE_VEHICLE_SUCCESS](state, action) {
    state.controls.loading = false
    state.controls.error = false
    state.controls.message = "---"
    state.controls.toDeleteId = ""
    state.controls.toEditId = ""
    state.controls.toEnableId = ""
    state.modal.enable.status = !state.modal.enable.status
  },
  [MAINTAINERVEHICLE.PATCH_ENABLE_VEHICLE_ERROR](state, action) {
    state.controls.loading = false
    state.controls.error = true
    state.controls.message = "."
  },

  [MAINTAINERVEHICLE.DELETE_VEHICLE](state, action) {
    state.controls.loading = true
    state.deleteIsOpen = false
    state.toDeleteId = ""
  },
  [MAINTAINERVEHICLE.DELETE_VEHICLE_SUCCESS](state, action) {
    state.controls.loading = false
    state.controls.error = false
    state.controls.message = "El taller fue eliminado con exito."
    state.controls.toDeleteId = ""
    state.controls.toEditId = ""
    state.modal.deleted.status = !state.modal.deleted.status
  },
  [MAINTAINERVEHICLE.DELETE_VEHICLE_ERROR](state, action) {
    state.controls.loading = false
    state.controls.error = true
    state.controls.message = "."
  },

  [MAINTAINERVEHICLE.TOGGLE_CREATE_MODAL](state) {
    state.controls.toDeleteId = ""
    state.controls.toEditId = ""
    state.invalidControls = { ...defaultInvalidControls }
    state.modal.form.status = !state.modal.form.status
    state.edit = false
  },
  [MAINTAINERVEHICLE.TOGGLE_UPDATED_MODAL](state, action) {
    state.invalidControls = { ...defaultInvalidControls }
    state.controls.toDeleteId = ""
    state.controls.toEditId = action?.item?.id || ""
    state.formControls.vehicleTypeId = action?.item?.vehicleTypeId
    state.formControls.plateNumber = action?.item?.plateNumber
    state.formControls.model = action?.item?.model
    state.formControls.vin = action?.item?.vin
    state.modal.form.status = !state.modal.form.status
    state.edit = true
  },
  [MAINTAINERVEHICLE.TOGGLE_DELETED_MODAL](state, action) {
    state.controls.toDeleteId = action?.id || ""
    state.controls.toEditId = ""
    state.modal.deleted.status = !state.modal.deleted.status
  },
  [MAINTAINERVEHICLE.TOGGLE_ENABLE_MODAL](state, action) {
    state.controls.toEnableId = action?.id || ""
    state.modal.enable.info = { ...action.info }
    state.controls.toDeleteId = ""
    state.controls.toEditId = ""
    state.modal.enable.status = !state.modal.enable.status
  },
  [MAINTAINERVEHICLE.GET_VEHICLE_TYPES_SUCCESS](state, action) {
    state.vehicleTypes = action.payload.vehicleTypes
  },
  [MAINTAINERVEHICLE.CLOSE_VEHICLE_MODAL](state, action) {
    state.modal.form.status = false
    state.formControls = { ...defaultControls }
  },
})

export default maintainerVehicle
