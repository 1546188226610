import React, { useEffect, useState } from 'react';
import AuthUserContext from 'commons/context/user';
import { Redirect } from 'react-router-dom';

// Material
import { withStyles } from '@material-ui/core';

// Project
import { contractDocumentsActions } from './reducer';
import saga from './saga';
import { appActions } from "commons/reducer";

//Components
import LoadingScreenCircular from 'commons/components/LoadingScreenCircular';
import ContractDocumentsComponent from './components';

//Utils
import { composedComponent } from 'utils/functions';
import Paths from 'utils/paths';

//Styles
import styles from './styles';

const ContractDocuments = props => {
  const { classes } = props;

  const authUser = React.useContext(AuthUserContext)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!isLoading) return;
    authUser?.rol?.name && setIsLoading(false);
  }, [authUser])

  if (!isLoading && !['Administrator'].includes(authUser?.rol?.name))
    return <Redirect to={Paths.CONTRACTS} />

  return (
    <>
      {isLoading ?
        <LoadingScreenCircular /> :
        <ContractDocumentsComponent classes={classes} />
      }
    </>
  );
};

export default composedComponent(ContractDocuments, saga, {
  saga: 'sagaContractDocuments',
  middlewares: [withStyles(styles)],
  states: [
    'app.user',
    'app.objects',
    'contractDocuments.controls',
    'contractDocuments.invalidControls'],
  actions: [contractDocumentsActions, appActions]
});