// @ts-nocheck
import React, { Fragment } from "react"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { compose } from "redux"
// Project
import { appActions } from "commons/reducer"
import { actionProps, selectState } from "utils/reduxActions"
import { ReactReduxContext } from "config/configureStore"
import connectSaga from "utils/saga"
import saga from "commons/saga"
// material
import MuiAlert from "@material-ui/lab/Alert"
import Snackbar from "@material-ui/core/Snackbar"
import Grid from "@material-ui/core/Grid"
// components
import NavBar from "commons/components/NavBar"
import Footer from "commons/components/Footer"
import SideBar from "commons/components/SideBar"
import DownloadFileModal from "commons/components/DownloadFileModal"
import Paths from "./../../../utils/paths"

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const Layout = (props) => {
  function logOut() {
    localStorage.clear()
    props.actions.setUser({})
    props.history.push(Paths.LOGIN)
  }

  // useEffect(() => {
  //   props.actions.searchPrinter();
  // }, [props.actions]);

  function toggleDrawer() {
    props.actions.toggleDrawer()
  }

  function toggleError() {
    props.actions.toggleError()
  }

  function toggleSuccess() {
    props.actions.toggleSuccess()
  }

  function toggleModalDownload() {
    props.actions.toggleModalDownload()
  }

  function handleItemClick(module) {
    return () => {
      props.history.push(module)
      props.actions.toggleDrawer()
    }
  }
  return (
    <Fragment>
      <NavBar logOut={logOut} toggleDrawer={toggleDrawer} user={props.user} />
      <SideBar
        isOpen={props.drawerIsOpen}
        toggleDrawer={toggleDrawer}
        handleItemClick={handleItemClick}
      />
      <div className="content-container">
        <Grid container justify="center">
          <Grid item md={11} sm={12}>
            {props.children}
          </Grid>
        </Grid>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={props.error}
        autoHideDuration={3000}
        onClose={toggleError}
      >
        <Alert onClose={toggleError} severity="error">
          {props.errorMsg}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={props.success}
        autoHideDuration={3000}
        onClose={toggleSuccess}
      >
        <Alert onClose={toggleSuccess} severity="success">
          {props.successMsg}
        </Alert>
      </Snackbar>

      <DownloadFileModal
        toggleForm={toggleModalDownload}
        isOpen={props.modalDownload}
        url={props.url_file}
      />

      <Footer />
    </Fragment>
  )
}

const withSaga = connectSaga({ key: "sagaApp", saga })

const withConnect = connect(
  selectState(
    "app.url_file",
    "app.modalDownload",
    "app.drawerIsOpen",
    "app.errorMsg",
    "app.error",
    "app.successMsg",
    "app.success",
    "app.user"
  ),
  actionProps(appActions),
  null,
  { context: ReactReduxContext }
)

export default compose(withRouter, withConnect)(withSaga(Layout))
