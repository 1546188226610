// @ts-nocheck
import React from "react"
// material
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Typography from "@material-ui/core/Typography"

import ButtonWithLoader from "commons/containers/ButtonWithLoader"
// styles
// import styles from './styles';

// const useStyles = makeStyles(styles);

const DeleteModal = (props) => {
  return (
    <Dialog
      fullWidth
      onClose={props.toggleForm}
      aria-labelledby="simple-dialog-title"
      open={props.isOpen}
    >
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <Typography>{props.description}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.toggleForm} color="primary">
          Cancelar
        </Button>
        <ButtonWithLoader
          autoFocus
          color={props.colorActionBtn}
          onClick={props.submit}
          buttonId={props.buttonId}
        >
          {props.labelAction}
        </ButtonWithLoader>
      </DialogActions>
    </Dialog>
  )
}

DeleteModal.defaultProps = {
  toggleForm: () => {},
  isOpen: false,
  title: "Confirmar eliminación",
  description: "¿Esta seguro que desea eliminar este registro?",
  labelAction: "Confirmar",
  colorActionBtn: "secondary",
}

export default DeleteModal
