import { takeLatest, spawn, put } from "redux-saga/effects"
import { MAINTAINEREVENTTYPE } from "../reducer"
import { apiSuccess, destroy, get, post, update } from "utils/api"
import { APP } from "commons/reducer"

function* getStateFromApi() {
  yield takeLatest(MAINTAINEREVENTTYPE.GET_STATE_FROM_API, function* (action) {
    const response = yield get("api/v1/event-types/model-env")
    yield put(apiSuccess(MAINTAINEREVENTTYPE.GET_STATE_FROM_API_SUCCESS, response))
  })
}

function* addEventType() {
  yield takeLatest(MAINTAINEREVENTTYPE.POST_ADD_EVENTTYPE, function* (action) {
    const body = action.controls
    const response = yield post("api/v1/event-types", body)
    if (!response.error) {
      yield put(apiSuccess(MAINTAINEREVENTTYPE.POST_ADD_EVENTTYPE_SUCCESS, response))
      yield put(apiSuccess(APP.SET_SUCCESS, response))
    } else {
      yield put(apiSuccess(MAINTAINEREVENTTYPE.POST_ADD_EVENTTYPE_ERROR, response))
      yield put(apiSuccess(APP.SET_ERROR, response))
    }
  })
}

function* updateEventType() {
  yield takeLatest(MAINTAINEREVENTTYPE.POST_UPDATE_EVENTTYPE, function* (action) {
    const body = JSON.stringify(action.controls)
    const response = yield update(`api/v1/event-types/${action.id}`, body)
    if (!response.error) {
      yield put(apiSuccess(MAINTAINEREVENTTYPE.POST_UPDATE_EVENTTYPE_SUCCESS, response))
      yield put(apiSuccess(APP.SET_SUCCESS, response))
    } else {
      yield put(apiSuccess(MAINTAINEREVENTTYPE.POST_UPDATE_EVENTTYPE_ERROR, response))
      yield put(apiSuccess(APP.SET_ERROR, response))
    }
  })
}

function* deleteEventType() {
  yield takeLatest(MAINTAINEREVENTTYPE.DELETE_EVENTTYPE, function* (action) {
    const response = yield destroy(`api/v1/event-types/${action.id}`)
    if (!response.error) {
      yield put(
        apiSuccess(MAINTAINEREVENTTYPE.DELETE_EVENTTYPE_SUCCESS, {
          item: response,
          type: "eventTypes",
        })
      )
      yield put(apiSuccess(APP.SET_SUCCESS, response))
    } else {
      yield put(apiSuccess(MAINTAINEREVENTTYPE.DELETE_EVENTTYPE_ERROR, response))
      yield put(apiSuccess(APP.SET_ERROR, response))
    }
  })
}

export default function* root() {
  yield spawn(getStateFromApi)
  yield spawn(addEventType)
  yield spawn(updateEventType)
  yield spawn(deleteEventType)
}
