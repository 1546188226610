// @ts-nocheck
import React from "react"
// material
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core"
import ButtonWithLoader from "commons/containers/ButtonWithLoader"
import CustomField from "commons/components/CustomField"
import MultifileField from "commons/components/MultifileField"
import Parrafo from "commons/components/Parrafo"

import FileCopyIcon from "@material-ui/icons/FileCopy"

const PreventiveMaintenanceForm = (props) => {
  const renderAttachAcceptFiles = () => {
    return !!props.controls?.file?.path ? (
      <div className="attach-files">
        <Parrafo title={"Archivo adjunto"} value={""} />
        <a target="_blank" rel="noopener noreferrer" href={props.controls?.file.path}>
          <FileCopyIcon />
          {props.controls?.file.name}
        </a>
      </div>
    ) : (
      <div className="attach-files">
        <Parrafo title={"Archivo adjunto"} value={""} />
        <p>Sin archivos adjuntos</p>
      </div>
    )
  }

  return (
    <Dialog
      fullWidth
      onClose={props.toggleForm}
      aria-labelledby="simple-dialog-title"
      open={props.isOpen}
    >
      <DialogTitle>{`${props.edit ? "Editar" : "Crear"} mantención preventiva`}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item sm={12} xs={12}>
            <CustomField
              className={props.className.textfield}
              value={props.controls.name}
              onChange={props.changeControls}
              fullWidth
              id="name"
              label="Nombre"
              error={props.errors.name}
              helperText={props.errors.name && "Debe ingresar un nombre para la mantención"}
            />
            <CustomField
              className={props.className.textfield}
              value={props.controls.value}
              onChange={props.changeControls}
              fullWidth
              id="value"
              label="Valor de la mantención"
              error={props.errors.value}
              helperText={
                props.errors.value && "Debe ingresar un valor numérico para la mantención"
              }
            />

            {renderAttachAcceptFiles()}

            <MultifileField
              className={props.className.textfield}
              value={props.controls.files}
              onChange={props.changeControls}
              fullWidth
              variant="outlined"
              label="Archivos"
              name="files"
              key="files"
              id="files"
              error={props.errors.files}
              helperText={
                props.errors.files && "Debe ingresar un valor numérico para la mantención"
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.toggleForm} color="primary">
          Cancelar
        </Button>
        <ButtonWithLoader onClick={props.submit} autoFocus buttonId="create-edit-client">
          {props.edit ? "Editar" : "Agregar"}
        </ButtonWithLoader>
      </DialogActions>
    </Dialog>
  )
}

export default PreventiveMaintenanceForm
