import { Box, TextField, Typography } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import React, { useState } from "react"
import { budgetActions } from "screens/Budget/reducer"
import { composedComponent } from "utils/functions"
import saga from "../../saga"

const SparePartsSearcher = (props) => {
  const { sparePartsCosts, addBudgetSparePart } = props
  const [inputValue, setInputValue] = useState("")

  const handler = (event, newValue) => {
    if (newValue !== null) {
      const sparePartCostSelected = sparePartsCosts.filter(
        (item) => item.code === newValue.split(" ")[0]
      )
      if (sparePartCostSelected[0]) {
        addBudgetSparePart(sparePartCostSelected[0])
      }
    }
    setInputValue('')
  }

  return (
    <Box style={{ marginBottom: "30px", marginTop: "20px" }}>
      <Typography style={{ fontWeight: "bold", fontSize: "20px" }}>Repuestos:</Typography>
      <Autocomplete
        freeSolo
        value={''}
        inputValue={inputValue}
        onChange={handler}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue)
        }}
        options={sparePartsCosts.map((option) => `${option.code} - ${option.description}`)}
        /* getOptionLabel={(option) => option.description} */
        style={{ minWidth: "300px", marginTop: "10px", paddingLeft: "10vh", paddingRight: "10vh" }}
        renderInput={(params) => (
          <TextField {...params} label="Buscar Repuestos" variant="outlined" />
        )}
      />
    </Box>
  )
}

export default SparePartsSearcher
