import React, { useState, useEffect } from "react"
import { CardContent, withStyles, Card, Grid, Typography } from "@material-ui/core"
import saga from "./saga"
import { maintainerUserActions } from "./reducer"
import { composedComponent, falsy, validateEmail } from "utils/functions"
import { appActions } from "commons/reducer"
import styles from "./styles"

import TableUser from "./components/TableUser"
import Modal from "commons/components/DeleteModal"
import UserFormModal from "./components/UserForm"
import MenuButton from "commons/components/MenuButton"
import { VALIDATE } from "utils/validations"

const MaintainerUser = (props) => {
  const { classes, actions, controls } = props
  useEffect(() => {
    actions.getStateFromApiUsers()
    actions.getStateFromApiRoles()
  }, [])

  const [clientList, setClientList] = useState([])
  useEffect(() => {
    const filteredClients = Object.values(props.objects.users)
    setClientList(filteredClients)
  }, [props.objects.users])

  function onChangeControls(event) {
    const property = !!event.target.name ? event.target.name : event.currentTarget.id
    actions.changeControls({ [property]: event.target.value }, { [property]: false })
  }

  const onToggleModelDetail = () => {}

  const onToggleModel = () => {
    actions.toggleCreateModal()
  }

  const onToggleUpdatedModal = (id = "") => {
    if (typeof id === "number" && !controls.toEditId.localeCompare("")) {
      const item = Object.values(props.objects.users).find((item) => item.id === Number(id))
      actions.toggleUpdatedModal(item)
    } else {
      actions.toggleUpdatedModal()
    }
  }

  const handleOpenDeletedModal = (id = "") => {
    actions.toggleDeletedModal(id)
  }

  const handleOpenEnableMailerModal = (id = "", enable = true) => {
    let info = {
      title: "Desactivar correos",
      description: "¿Desea desactivar el envio de correos?",
      labelAction: "Desactivar",
      colorActionBtn: "secondary",
      enable: enable,
    }
    if (!enable) {
      info = {
        title: "Activar correos",
        description: "¿Desea activar el envio de correos?",
        labelAction: "Activar",
        colorActionBtn: "primary",
        enable: enable,
      }
    }
    actions.toggleEnableMailerModal(id, info)
  }

  const submit = () => {
    console.log(
      falsy(props.formControls.email) || !VALIDATE.EMAIL(props.formControls.email),
      VALIDATE.EMAIL(props.formControls.email),
      props.formControls.email
    )
    if (
      falsy(props.formControls.name) ||
      falsy(props.formControls.roleId) ||
      falsy(props.formControls.email) ||
      !VALIDATE.EMAIL(props.formControls.email)
    ) {
      props.actions.setInvalidControls({
        name: falsy(props.formControls.name),
        roleId: falsy(props.formControls.roleId),
        email: falsy(props.formControls.email) || !VALIDATE.EMAIL(props.formControls.email),
      })
    } else {
      if (props.edit) {
        actions.updateUser(controls.toEditId, props.formControls)
      } else {
        actions.addUser(props.formControls)
      }
    }
  }

  const submitEnableMailer = () => {
    actions.enableMailer(controls.toEnableId, props.modal.enableMailer.info)
  }

  const submitDelete = () => {
    actions.deleteUser(controls.toDeleteId)
  }

  const normalizeToSelect = (data) => {
    data = Object.values(data)
    const selectData = data.map((model) => ({ value: model.id, label: model.label }))
    return selectData
  }

  return (
    <>
      <Grid container justify="center" alignItems="center" style={{ margin: 0, height: "100vh" }}>
        <Grid item md={12} lg={12} xs={12}>
          <Card>
            <CardContent>
              <Typography className={classes.title} variant="h3" component="h3">
                Usuarios
              </Typography>
              <TableUser
                className={{ icon: classes.icon, p: classes.p }}
                data={clientList}
                onModal={onToggleModelDetail}
                handleOpenUpdatedModal={onToggleUpdatedModal}
                handleOpenDeletedModal={handleOpenDeletedModal}
                handleOpenEnableMailerModal={handleOpenEnableMailerModal}
              />
            </CardContent>
          </Card>
        </Grid>

        <Modal
          toggleForm={handleOpenDeletedModal}
          isOpen={props.modal.deleted.status}
          title="Eliminar usuario"
          description="¿Desea eliminar este usuario?"
          labelAction="eliminar"
          submit={submitDelete}
        />

        <Modal
          toggleForm={handleOpenEnableMailerModal}
          isOpen={props.modal.enableMailer.status}
          title={props.modal.enableMailer.info.title}
          description={props.modal.enableMailer.info.description}
          labelAction={props.modal.enableMailer.info.labelAction}
          colorActionBtn={props.modal.enableMailer.info.colorActionBtn}
          submit={submitEnableMailer}
        />

        <UserFormModal
          className={classes}
          isOpen={props.modal.form.status}
          edit={props.edit}
          controls={props.formControls}
          errors={props.invalidControls}
          helperFilters={{ roles: normalizeToSelect(props.objects.roles) }}
          changeControls={onChangeControls}
          submit={submit}
          toggleForm={props.edit ? onToggleUpdatedModal : onToggleModel}
        />
      </Grid>

      <MenuButton className={classes.fab} handleToggle={onToggleModel} />
    </>
  )
}

export default composedComponent(MaintainerUser, saga, {
  saga: "sagaMaintainerUser",
  middlewares: [withStyles(styles)],
  states: [
    "maintainerUser.formControls",
    "maintainerUser.invalidControls",
    "maintainerUser.controls",
    "maintainerUser.modal",
    "maintainerUser.edit",
    "app.objects",
  ],
  actions: [maintainerUserActions, appActions],
})
