import React, { useRef, useState } from "react"

import { Typography, withStyles } from "@material-ui/core"
import CloudUploadIcon from "@material-ui/icons/CloudUpload"
import DescriptionIcon from "@material-ui/icons/Description"
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline"

const DropDown = withStyles({
  root: {
    width: "100%",
    height: "150px",
    zIndex: "2",
    border: "2px dashed white",
    fontWeight: "600",
    background: "rgba(0, 0, 0, 0.05)",
    letterSpacing: "1px",
    margin: "auto;",
    fontSize: "16px",
  },
})((props) => <Typography variant="body2" component="div" className="drag-box" {...props} />)

const DropDownContainer = withStyles({
  root: {
    width: "100%",
    height: "100%",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    color: "#979797",
    fontSize: "30px",
  },
})(
  React.forwardRef((props, ref) => (
    <Typography
      ref={ref}
      variant="body2"
      component="div"
      className="container-drag-box"
      {...props}
    />
  ))
)

const DropDownContent = withStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
})((props) => (
  <Typography variant="body2" component="div" className="content-drag-box" {...props} />
))

const MultifileField = (props) => {
  const [state, setState] = useState([])
  // const [isVisible, setIsVisible] = useState(false)
  const fileInput = useRef(null)
  const dragBoxInput = React.createRef()

  const _onDragEnter = (e) => {
    // setIsVisible(true)
    e.stopPropagation()
    e.preventDefault()
  }
  const _onDragOver = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }
  const _onDragLeave = (e) => {
    // setIsVisible(false)
    e.stopPropagation()
    e.preventDefault()
  }

  const _onDrop = (e) => {
    // setIsVisible(false)
    e.preventDefault()
    const files = e.dataTransfer.files
    setOnState(files)
  }

  const onFileChange = (e) => {
    const files = e.target.files
    setOnState(files)
  }

  const setOnState = (files) => {
    setState([...state, ...files])
    props.onChange(_createObjectValue([...state, ...files]))
  }

  function _createObjectValue(value) {
    return {
      persist: () => {},
      target: {
        value: value,
        id: props.name,
        name: props.name,
        type: "mfiles",
      },
    }
  }

  React.useEffect(() => {
    dragBoxInput.current.addEventListener("mouseup", _onDragLeave)
    dragBoxInput.current.addEventListener("dragenter", _onDragEnter)
    dragBoxInput.current.addEventListener("dragover", _onDragOver)
    dragBoxInput.current.addEventListener("drop", _onDrop)
    return () => {}
  }, [_onDragEnter, _onDragLeave, _onDragOver, _onDrop])

  // const onSubmit = (e) => {
  // e.preventDefault()
  // var formData = new FormData();
  // for (const key of Object.keys(this.state.imgCollection)) {
  //     formData.append('imgCollection', this.state.imgCollection[key])
  // }
  // axios.post("http://localhost:4000/api/upload-images", formData, {
  // }).then(res => {
  //     console.log(res.data)
  // })
  // }

  const renderFileNames = () => {
    return state.map((file, key) => {
      return (
        <p key={key}>
          <DescriptionIcon />
          {file.name}
          <RemoveCircleOutlineIcon
            onClick={() => {
              removeFile(key)
            }}
          />
        </p>
      )
    })
  }

  const removeFile = (key) => {
    state.splice(key, 1)
    setState([...state])
    props.onChange(_createObjectValue([...state]))
  }

  return (
    <>
      <DropDown>
        <DropDownContainer
          ref={dragBoxInput}
          onClick={() => {
            fileInput.current.click()
          }}
        >
          <DropDownContent>
            <CloudUploadIcon fontSize="large" />
            Subir archivos
            <input
              title="-"
              style={{ width: "100%", height: "100%", display: "none" }}
              ref={fileInput}
              onChange={onFileChange}
              type="file"
              name="dragBoxInput"
              multiple
            />
          </DropDownContent>
        </DropDownContainer>
        {props.error ? props.helperText : ""}
        <Typography variant="body2" component="div">
          {renderFileNames()}
        </Typography>
      </DropDown>
    </>
  )
}

export default MultifileField
