import { takeLatest, spawn, put } from "redux-saga/effects"
import { APP } from "commons/reducer"
import apiRequest, { apiSuccess, get, post } from "utils/api"
import { falsy } from "utils/functions"

// function* logout() {
//   yield takeLatest(LOG_OUT, function* () {
//     yield apiRequest('/logout', { method: 'get' });
//   });
// }

function* getUser() {
  yield takeLatest(APP.GET_USER, function* () {
    const response = yield apiRequest("api/v1/users/user", { method: "get" }, true)
    if (falsy(response?.error)) {
      yield put(apiSuccess(APP.GET_USER_SUCCESS, response))
    } else {
      yield put(apiSuccess(APP.GET_USER_FAIL))
    }
  })
}

export default function* root() {
  yield spawn(getUser)
}
