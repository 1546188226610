import React, { useState, useEffect, useCallback } from "react"
import { Route, Switch, useHistory, useLocation } from "react-router-dom"
import produce from "immer"
import { CardContent, Snackbar, withStyles, CardMedia } from "@material-ui/core"
import Card from "@material-ui/core/Card"
import Grid from "@material-ui/core/Grid"
// Project
import saga from "./saga"
import { recoveryPasswordActions } from "./reducer"
import { getParameterByName, composedComponent, truthty } from "utils/functions"
import { appActions } from "commons/reducer"
import styles from "./styles"
import Alert from "@material-ui/lab/Alert"
import { useForm } from "utils/hooks/useForm"
// import LOGO from "utils/images/logo-salfa-h-color300.png"
import LOGO from "utils/images/logo-carabinero-h-color300.png"
import Paths from "utils/paths"
import RecoveryForm from "./components/recoveryForm"
import UpdatePasswordForm from "./components/updatePasswordForm"
import AlertSnackbar from "commons/components/AlertSnackbar"
import { VALIDATIONS } from "utils/validations"

const RecoveryPassword = (props) => {
  const { classes, actions, controls } = props
  const history = useHistory()
  const location = useLocation()
  const [state, setState] = useState({
    email: "",
    password: "",
    verifyPassword: "",
    token: "",
  })

  useEffect(() => {
    const token = getParameterByName("token", props.location.search)
    const email = getParameterByName("email", props.location.search)
    setState({ ...state, token, email })
    if (token) {
      // props.actions.verify(token);
    }
  }, [props.actions, props.location.seaprops, props.location.search])

  const onChangeText = useCallback((id, value) => {
    setState(
      produce((draft) => {
        draft[id] = value?.trim()
      })
    )
  }, [])

  const { onChange, onSubmit, errors } = useForm(state, null, {
    onChange: onChangeText,
    validations: {
      email: [{ ...VALIDATIONS.REQUIRED, message: "Email obligatorio" }, VALIDATIONS.IS_EMAIL],
    },
  })

  const toggleError = () => {
    props.actions.toggleError()
  }

  const toggleSuccess = () => {
    props.actions.toggleSuccess()
  }

  const onClickVerifyEmail = (event) => {
    event.preventDefault()
    onSubmit(() => props.actions.verifyEmail(state))
  }

  const onClickUpdatePassword = (event) => {
    event.preventDefault()
    onSubmit(() => props.actions.updatePassword(state))
  }

  return (
    <div style={styles.container}>
      <div style={styles.containerContent}>
        <Grid container justify="center" alignItems="center" style={{ margin: 0, height: "100vh" }}>
          <Grid item md={4} lg={4} xs={12}>
            <Card>
              <CardContent style={styles.cardContent}>
                <CardMedia className={classes.image} image={LOGO} title="Salfa" />
                <Switch>
                  <Route
                    exact
                    path={Paths.RECOVERY_PASSWORD}
                    render={() => (
                      <RecoveryForm
                        {...props}
                        onClickVerifyEmail={onClickVerifyEmail}
                        onChange={onChange}
                        email={state.email}
                        errors={errors}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={Paths.UPDATE_PASSWORD}
                    render={() => (
                      <UpdatePasswordForm
                        {...props}
                        onClickUpdatePassword={onClickUpdatePassword}
                        onChange={onChange}
                        password={state.password}
                        verifyPassword={state.verifyPassword}
                      />
                    )}
                  />
                </Switch>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
      <AlertSnackbar
        pop={truthty(controls.message)}
        duration={3000}
        severity="success"
        message={controls.message}
        onClose={() => {
          if (controls.successChangePassword) {
            history.push(Paths.LOGIN)
          }
          actions.disableErrors()
        }}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={props.error}
        autoHideDuration={3000}
        onClose={toggleError}
      >
        <Alert onClose={toggleError} severity="error">
          {props.errorMsg}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={props.success}
        autoHideDuration={3000}
        onClose={toggleSuccess}
      >
        <Alert onClose={toggleSuccess} severity="success">
          {props.successMsg}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default composedComponent(RecoveryPassword, saga, {
  saga: "sagaRecoveryPassword",
  middlewares: [withStyles(styles)],
  states: [
    "recoveryPassword.controls",
    "app.user",
    "app.errorMsg",
    "app.error",
    "app.successMsg",
    "app.success",
  ],
  actions: [recoveryPasswordActions, appActions],
})
