import { Grid } from "@material-ui/core"
import React from "react"

const BudgetSummary = (props) => {
  const { total, vat, net_value, className } = props
  return (
    <Grid
      container
      md={12}
      lg={12}
      xs={12}
      justifyContent="center"
      alignItems="center"
      className={className.containerTopMargin}
    >
      <Grid item md={10} lg={10} xs={10} className={className.alignedLeft}>
        NETO
      </Grid>
      <Grid
        container
        md={2}
        lg={2}
        xs={2}
        justifyContent="flex-end"
        className={className.alignedRightBold}
      >
        $ {new Intl.NumberFormat("en-DE").format(net_value)}
      </Grid>
      <Grid item md={10} lg={10} xs={10} className={className.alignedLeft}>
        IVA
      </Grid>
      <Grid
        container
        md={2}
        lg={2}
        xs={2}
        justifyContent="flex-end"
        className={className.alignedRightBold}
      >
        $ {new Intl.NumberFormat("en-DE").format(vat)}
      </Grid>
      <Grid item md={10} lg={10} xs={10} className={className.alignedLeft}>
        TOTAL
      </Grid>
      <Grid
        container
        md={2}
        lg={2}
        xs={2}
        justifyContent="flex-end"
        className={className.alignedRightBold}
      >
        $ {new Intl.NumberFormat("en-DE").format(total)}
      </Grid>
    </Grid>
  )
}

export default BudgetSummary
