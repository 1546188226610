import { Grid } from "@material-ui/core"
import React from "react"

const BudgetLaborsTable = (props) => {
  const { budgetLabors, className } = props

  let totalBudgetLabors = budgetLabors ? budgetLabors : []
  let totalValue = 0
  let manHoursTotalValue = 0
  let budgetLaborsFiltered = []

  if (totalBudgetLabors.length) {
    budgetLaborsFiltered = totalBudgetLabors.filter(
      (element) => element.labor_cost.laborTypeId !== 12
    )
    totalValue = budgetLaborsFiltered.reduce(
      (acc, currentLabor) => acc + currentLabor.price,
      0
    )
    manHoursTotalValue = budgetLaborsFiltered.reduce(
      (acc, currentLabor) => acc + currentLabor.manHours,
      0
    )
  }

  return (
    <>
      <Grid
        container
        item
        md={12}
        lg={12}
        xs={12}
        justifyContent="start"
        className={className.containerTopMargin}
      >
        <Grid item md={3} lg={3} xs={3} className={className.tableHeader}>
          Mano de Obra
        </Grid>
      </Grid>

      <Grid container item md={12} lg={12} xs={12} justifyContent="center">
        <Grid item md={2} lg={2} xs={2} className={className.borderCell}>
          Código
        </Grid>
        <Grid item md={7} lg={7} xs={7} className={className.borderCell}>
          Descripción
        </Grid>
        <Grid item md={1} lg={1} xs={1} className={className.borderCell}>
          Tempario
        </Grid>
        <Grid item md={2} lg={2} xs={2} className={className.borderCell}>
          Valor neto
        </Grid>
        {budgetLaborsFiltered.map((budgetLabor) => (
          <>
            <Grid item md={2} lg={2} xs={2} className={className.leftCell}>
              {budgetLabor.labor_cost.code}
            </Grid>
            <Grid item md={7} lg={7} xs={7} className={className.alignedLeft}>
              {budgetLabor.labor_cost.description}
            </Grid>
            <Grid
              container
              md={1}
              lg={1}
              xs={1}
              justifyContent="flex-end"
              className={className.alignedRight}
            >
              {" "}
              {budgetLabor.labor_cost?.laborCostByVehicleType?.manHours}
            </Grid>
            <Grid
              container
              md={2}
              lg={2}
              xs={2}
              justifyContent="flex-end"
              className={className.rightCell}
            >
              $ {new Intl.NumberFormat("en-DE").format(budgetLabor.price)}
            </Grid>
          </>
        ))}
        <Grid item md={9} lg={9} xs={9} className={className.borderCell}>
          SUBTOTAL
        </Grid>
        <Grid
          container
          md={1}
          lg={1}
          xs={1}
          className={className.borderCell}
          justifyContent="flex-end"
        >
          {manHoursTotalValue}
        </Grid>
        <Grid
          container
          md={2}
          lg={2}
          xs={2}
          className={className.borderCell}
          justifyContent="flex-end"
        >
          $ {new Intl.NumberFormat("en-DE").format(totalValue)}
        </Grid>
      </Grid>
    </>
  )
}

export default BudgetLaborsTable
