const Paths = {
  LOGIN: "/",
  HOME: "/home",
  BUDGETS: "/budgets/",
  SERVICE_DETAILS: "/service/:id/",
  CREATE_BUDGET: "/service/:id/createbudget/",
  BUDGET_DETAILS: "/budget/:id",
  EDIT_BUDGET: "/budget/:id/edit",
  RECOVERY_PASSWORD: "/reset-password",
  UPDATE_PASSWORD: "/update-password",
  USERS_MANTAINER: "/mantainer/users/",
  WORKSHOPS_MANTAINER: "/mantainer/workshops/",
  VEHICLES_MANTAINER: "/mantainer/vehicles/",
  EVENTTYPES_MANTAINER: "/mantainer/eventtypes/",
  PREVENTIVEMAINTENANCES_MANTAINER: "/mantainer/preventivemaintenances/",
  CONTRACT_DOCUMENTS: "/documents/",
  CONTRACTS: "/contracts/",
}

export default Paths
