/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
import { createAction, createReducer } from 'utils/reducer';


// Local constants
const PATH = 'contractDocuments/';

const DISABLE_ERRORS = `${PATH}DISABLE_ERRORS`;
const RESET = `${PATH}RESET`;
const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`;

// Global constants for saga
export const CONTRACT_DOCUMENTS = {
  GET_STATE_FROM_API: `${PATH}GET_STATE_FROM_API`,
  GET_STATE_FROM_API_SUCCESS: `${PATH}GET_STATE_FROM_API_SUCCESS`,
  GET_STATE_FROM_API_FAIL: `${PATH}GET_STATE_FROM_API_FAIL`,
  SUBMIT: `${PATH}SUBMIT`,
  SUBMIT_SUCCESS: `${PATH}SUBMIT_SUCCESS`,
  SUBMIT_FAIL: `${PATH}SUBMIT_FAIL`,
};

// actions
export const contractDocumentsActions = {
  disableErrors: createAction(DISABLE_ERRORS),
  changeControls: createAction(CHANGE_CONTROLS, 'controls', 'invalidControls'),
  // api
  getStateFromApi: createAction(CONTRACT_DOCUMENTS.GET_STATE_FROM_API),
  getStateFromApiSuccess: createAction(CONTRACT_DOCUMENTS.GET_STATE_FROM_API_SUCCESS, 'payload'),
  getStateFromApiFail: createAction(CONTRACT_DOCUMENTS.GET_STATE_FROM_API_FAIL, 'payload'),
  submit: createAction(CONTRACT_DOCUMENTS.SUBMIT, 'controls'),
  submitSuccess: createAction(CONTRACT_DOCUMENTS.SUBMIT_SUCCESS, 'payload'),
  submitFail: createAction(CONTRACT_DOCUMENTS.SUBMIT_FAIL, 'payload'),
};

const defaultControls = {
  name: '',
}

const defaultInvalidControls = {
  name: false,
}

const initialState = {
  error: false,
  errorMsg: '',
  controls: {
    ...defaultControls
  },
  invalidControls: {
    ...defaultInvalidControls
  },
};

const contractDocuments = createReducer(initialState, {
  [DISABLE_ERRORS](state, action) {
    state.error = false;
    state.errorMsg = '';
  },
  [RESET](state, action) {
    state.controls = { ...defaultControls };
    state.invalidControls = { ...defaultInvalidControls };
  },
  [CHANGE_CONTROLS](state, action) {
    state.controls = {
      ...state.controls,
      ...action.controls
    };
    state.invalidControls = {
      ...state.invalidControls,
      ...(action.invalidControls || {})
    };
  },
});

export default contractDocuments;