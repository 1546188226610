import DropDownButton from "commons/components/DropDownButton"
import React from "react"
import { useHistory } from "react-router"
import AuthUserContext from "commons/context/user"

const TableActions = ({
  id,
  enable,
  handleOpenModal,
  handleOpenUpdatedModal,
  handleOpenDeletedModal,
  handleOpenEnableModal,
}) => {
  const authUser = React.useContext(AuthUserContext)

  const enableOptions = [
    {
      action: () => {
        handleOpenUpdatedModal(id)
      },
      title: "Ver",
      roles: ["Administrator"],
    },
    {
      action: () => {
        handleOpenEnableModal(id, enable)
      },
      title: "Activar",
      roles: ["Administrator"],
    },
    {
      action: () => {
        handleOpenDeletedModal(id)
      },
      title: "Eliminar",
      roles: ["Administrator"],
    },
  ]

  const disableOptions = [
    {
      action: () => {
        handleOpenUpdatedModal(id)
      },
      title: "Ver",
      roles: ["Administrator"],
    },
    {
      action: () => {
        handleOpenEnableModal(id)
      },
      title: "Desactivar",
      roles: ["Administrator"],
    },
    {
      action: () => {
        handleOpenDeletedModal(id)
      },
      title: "Eliminar",
      roles: ["Administrator"],
    },
  ]

  const filterRole = (!enable ? enableOptions : disableOptions).filter((option) =>
    option.roles.includes(authUser?.rol?.name)
  )

  return (
    <>
      <DropDownButton title={"Acción"} options={filterRole} />
    </>
  )
}

export default TableActions
