import { Button, Grid } from "@material-ui/core"
import React from "react"

const BudgetOptionButtons = (props) => {
  const { handleCreateBudget, handleCancelBudget, className } = props

  return (
    <Grid container spacing={2} className={className.buttonsContainer}>
      <Grid item sm={4} xs={4} container alignItems="center" justifyContent="center">
        <Button
          variant="outlined"
          style={{ color: "red", borderColor: "red" }}
          onClick={() => handleCancelBudget()}
        >
          Cancelar
        </Button>
      </Grid>
      <Grid item sm={4} xs={4} container alignItems="center" justifyContent="center">
        <Button
          variant="contained"
          style={{ color: "white", borderColor: "#185517", backgroundColor: "#185517" }}
          onClick={() => handleCreateBudget("Borrador")}
        >
          Guardar como borrador
        </Button>
      </Grid>
      <Grid item sm={4} xs={4} container alignItems="center" justifyContent="center">
        <Button
          variant="contained"
          style={{ color: "white", borderColor: "#185517", backgroundColor: "#185517" }}
          onClick={() => handleCreateBudget("Confirmado")}
        >
          Confirmar
        </Button>
      </Grid>
    </Grid>
  )
}

export default BudgetOptionButtons
