import { takeLatest, spawn, put } from "redux-saga/effects"
import { APP } from "../../../commons/reducer"
import { HOME } from "../reducer"
import { apiSuccess, postForm, update, get, downloadFileBlob } from "utils/api"
import { falsy, objectToQuery } from "utils/functions"

function* getServices() {
  yield takeLatest(HOME.GET_SERVICES, function* (action) {
    let query = action.controls ? objectToQuery(action.controls) : ""
    const response = yield get(`api/v1/services/?${query}`)
    if (!response.error) {
      yield put(apiSuccess(HOME.GET_SERVICES_SUCCESS, response))
    } else {
      yield put(apiSuccess(HOME.GET_SERVICES_ERROR, response))
    }
  })
}

function* createService() {
  yield takeLatest(HOME.POST_CREATE_SERVICE, function* (action) {
    const body = JSON.stringify(action.controls)
    const response = yield postForm("api/v1/services/", body)
    if (!response.error) {
      yield put(apiSuccess(HOME.POST_CREATE_SERVICE_SUCCESS, response))
      yield put(apiSuccess(APP.SET_SUCCESS, response))
      yield put(apiSuccess(HOME.TOGGLE_MODAL_SERVICES))
    } else {
      yield put(apiSuccess(HOME.POST_CREATE_SERVICE_ERROR, response))
      yield put(apiSuccess(APP.SET_ERROR, response))
    }
  })
}

function* updateService() {
  yield takeLatest(HOME.PATCH_UPDATE_SERVICE, function* (action) {
    const body = JSON.stringify({ ...action.controls, edit: true })
    const response = yield update(`api/v1/services/${action.controls.serviceId}`, body)
    if (!response.error) {
      yield put(apiSuccess(HOME.PATCH_UPDATE_SERVICE_SUCCESS, response))
      yield put(apiSuccess(APP.SET_SUCCESS, response))
    } else {
      yield put(apiSuccess(HOME.PATCH_UPDATE_SERVICE_ERROR, response))
      yield put(apiSuccess(APP.SET_ERROR, response))
    }
  })
}

function* approvatedService() {
  yield takeLatest(HOME.PATCH_ACCEPT_SERVICE, function* (action) {
    const body = JSON.stringify({ ...action.controls, edit: false })
    const response = yield update(`api/v1/services/${action.controls.serviceId}`, body)
    if (!response.error) {
      yield put(apiSuccess(HOME.PATCH_ACCEPT_SERVICE_SUCCESS, response))
      yield put(apiSuccess(APP.SET_SUCCESS, response))
      yield put(apiSuccess(HOME.TOGGLE_MODAL_SERVICE_ACCEPT))
    } else {
      yield put(apiSuccess(HOME.PATCH_ACCEPT_SERVICE_ERROR, response))
      yield put(apiSuccess(APP.SET_ERROR, response))
    }
  })
}

function* rejectService() {
  yield takeLatest(HOME.PATCH_REJECT_SERVICE, function* (action) {
    const body = JSON.stringify({ ...action.controls, edit: false })
    const response = yield update(`api/v1/services/${action.controls.serviceId}`, body)
    if (!response.error) {
      yield put(apiSuccess(HOME.PATCH_REJECT_SERVICE_SUCCESS, response))
      yield put(apiSuccess(APP.SET_SUCCESS, response))
      yield put(apiSuccess(HOME.TOGGLE_MODAL_SERVICE_REJECT))
    } else {
      yield put(apiSuccess(HOME.PATCH_REJECT_SERVICE_ERROR, response))
      yield put(apiSuccess(APP.SET_ERROR, response))
    }
  })
}

function* getServiceWorkShop() {
  yield takeLatest(HOME.GET_SERVICE_WORKSHOP, function* (action) {
    const response = yield get(`api/v1/work-shops`)
    if (!response.error) {
      yield put(apiSuccess(HOME.GET_SERVICE_WORKSHOP_SUCCESS, response))
    } else {
      yield put(apiSuccess(HOME.GET_SERVICE_WORKSHOP_ERROR, response))
      yield put(apiSuccess(APP.SET_ERROR, response))
    }
  })
}

function* getServiceVehicle() {
  yield takeLatest(HOME.GET_SERVICE_VEHICLE, function* (action) {
    const response = yield get(`api/v1/vehicles`)
    if (!response.error) {
      yield put(apiSuccess(HOME.GET_SERVICE_VEHICLE_SUCCESS, response))
    } else {
      yield put(apiSuccess(HOME.GET_SERVICE_VEHICLE_ERROR, response))
      yield put(apiSuccess(APP.SET_ERROR, response))
    }
  })
}

function* getServiceMaintenance() {
  yield takeLatest(HOME.GET_SERVICE_MAINTENENCE, function* (action) {
    const response = yield get(`api/v1/preventive-maintenances`)
    if (!response.error) {
      yield put(apiSuccess(HOME.GET_SERVICE_MAINTENENCE_SUCCESS, response))
    } else {
      yield put(apiSuccess(HOME.GET_SERVICE_MAINTENENCE_ERROR, response))
      yield put(apiSuccess(APP.SET_ERROR, response))
    }
  })
}

function* getServiceFilters() {
  yield takeLatest(HOME.GET_SERVICE_FILTERS, function* (action) {
    const response = yield get(`api/v1/services/field/filters`)
    if (!response.error) {
      yield put(apiSuccess(HOME.GET_SERVICE_FILTERS_SUCCESS, response))
    } else {
      yield put(apiSuccess(HOME.GET_SERVICE_FILTERS_ERROR, response))
      yield put(apiSuccess(APP.SET_ERROR, response))
    }
  })
}

function* updateBudgetAccept() {
  yield takeLatest(HOME.PATCH_SBUDGET_ACCEPT, function* (action) {
    let body = new FormData()
    for (const key of Object.keys(action.controls)) {
      body.append(`${key}`, action.controls[key])
    }
    if (action.controls.budgetFiles.length > 0) {
      action.controls.budgetFiles.map((file, key) => {
        body.append(`file${key}`, file)
      })
    }

    const response = yield update(
      `api/v1/services/${action.controls.serviceId}/budget/${action.controls.budgetId}/accept`,
      body,
      false
    )
    if (!response.error) {
      yield put(apiSuccess(HOME.PATCH_SBUDGET_ACCEPT_SUCCESS, response))
      yield put(apiSuccess(APP.SET_SUCCESS, response))
    } else {
      yield put(apiSuccess(HOME.PATCH_SBUDGET_ACCEPT_ERROR, response))
      yield put(apiSuccess(APP.SET_ERROR, response))
    }
  })
}

function* updateBudgetReject() {
  yield takeLatest(HOME.PATCH_SBUDGET_REJECT, function* (action) {
    let body = new FormData()
    for (const key of Object.keys(action.controls)) {
      body.append(`${key}`, action.controls[key])
    }
    if (action.controls.budgetFiles.length > 0) {
      action.controls.budgetFiles.map((file, key) => {
        body.append(`file${key}`, file)
      })
    }

    const response = yield update(
      `api/v1/services/${action.controls.serviceId}/budget/${action.controls.budgetId}/reject`,
      body,
      false
    )
    if (!response.error) {
      yield put(apiSuccess(HOME.PATCH_SBUDGET_REJECT_SUCCESS, response))
      yield put(apiSuccess(APP.SET_SUCCESS, response))
    } else {
      yield put(apiSuccess(HOME.PATCH_SBUDGET_REJECT_ERROR, response))
      yield put(apiSuccess(APP.SET_ERROR, response))
    }
  })
}

function* downloadServicesExport() {
  yield takeLatest(HOME.GET_SERVICE_EXPORT, function* (action) {
    let query = action.controls ? objectToQuery(action.controls) : ""
    const response = yield get(`api/v1/services/excel/extract-data?${query}`)
    if (!response.error) {
      yield put(apiSuccess(HOME.GET_SERVICE_EXPORT_SUCCESS, response))
      yield put(apiSuccess(APP.SET_SUCCESS, response))
    } else {
      yield put(apiSuccess(HOME.GET_SERVICE_EXPORT_ERROR, response))
      yield put(apiSuccess(APP.SET_ERROR, response))
    }
  })
}

export default function* root() {
  yield spawn(getServices)
  yield spawn(createService)
  yield spawn(updateService)
  yield spawn(approvatedService)
  yield spawn(rejectService)
  yield spawn(getServiceWorkShop)
  yield spawn(getServiceVehicle)
  yield spawn(getServiceMaintenance)
  yield spawn(getServiceFilters)
  yield spawn(updateBudgetAccept)
  yield spawn(updateBudgetReject)
  yield spawn(downloadServicesExport)
}
