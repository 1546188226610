import DropDownButton from "commons/components/DropDownButton"
import React from "react"
import { useHistory } from "react-router"
import AuthUserContext from "commons/context/user"

const TableActions = ({ id, enableMailer, handleOpenModal, handleOpenUpdatedModal, handleOpenDeletedModal, handleOpenEnableMailerModal }) => {
  const authUser = React.useContext(AuthUserContext)

  const enableOptions = [
    {
      action: () => {
        handleOpenUpdatedModal(id)
      },
      title: "Ver",
      roles: ["Administrator"],
    },
    {
      action: () => {
        handleOpenEnableMailerModal(id, enableMailer)
      },
      title: "Activar recepción",
      roles: ["Administrator"],
    },
    {
      action: () => {
        handleOpenDeletedModal(id)
      },
      title: "Eliminar",
      roles: ["Administrator"],
    },
  ]

  const disableOptions = [
    {
      action: () => {
        handleOpenUpdatedModal(id)
      },
      title: "Ver",
      roles: ["Administrator"],
    },
    {
      action: () => {
        handleOpenEnableMailerModal(id)
      },
      title: "Desactivar recepción",
      roles: ["Administrator"],
    },
    {
      action: () => {
        handleOpenDeletedModal(id)
      },
      title: "Eliminar",
      roles: ["Administrator"],
    },
  ]

  const filterRole = (!enableMailer ? enableOptions : disableOptions).filter((option) =>
    option.roles.includes(authUser?.rol?.name)
  )

  return (
    <>
      <DropDownButton title={"Acción"} options={filterRole} />
    </>
  )
}

export default TableActions
