import React from "react"
import Button from "@material-ui/core/Button"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"

const DropDownButton = ({ options, title }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const renderOptions = (options = [{ action: () => {}, title: "" }]) => {
    return options.map((option, key) => {
      const clickAction = () => {
        option.action()
        handleClose()
      }
      return (
        <MenuItem key={key} onClick={clickAction}>
          {option.title}
        </MenuItem>
      )
    })
  }

  return (
    <>
      <Button aria-controls="actions-menu" aria-haspopup="true" onClick={handleClick}>
        {title}
      </Button>
      <Menu
        id="actions-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {renderOptions(options)}
      </Menu>
    </>
  )
}

DropDownButton.defaultProps = {
  title: "Actions",
  options: [
    {
      action: () => {},
      title: "default",
    },
  ],
}

export default DropDownButton
