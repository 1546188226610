const styles = (theme) => ({
  root: {
    flexGrow: 1,
    justifyContent: "space-between",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  containerSiderBar: {
    display: "inherit",
  },
  printStatus: {
    marginRight: theme.spacing(2),
    fontFamily: ["Roboto"],
  },
  icon: {
    width: "44px",
    height: "50px",
    display: "inline-block",
    marginRight: "5px",
  },
})

export default styles
