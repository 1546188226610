import React from "react"
import { Tooltip } from "@material-ui/core"
import Parrafo from "../Parrafo"

const ToolTips = ({ style, className, title, value, type }) => {
  return (
    <>
      <Tooltip
        title={
          <React.Fragment>
            <Parrafo style={{ fontSize: "15px", color: "white" }} title={title} value={value} />
          </React.Fragment>
        }
      >
        <div>
          <Parrafo className={{ p: className.p }} title={title} value={value} />
        </div>
      </Tooltip>
    </>
  )
}

ToolTips.defaultProps = {
  type: "block",
  className: { boxLineaCard: "", p: "" },
  style: {},
}

export default ToolTips
