import React, { useState, useEffect } from "react"
import { CardContent, withStyles, Card, Grid, Typography } from "@material-ui/core"

import saga from "./saga"
import { maintainerPreventiveMaintenanceActions } from "./reducer"
import { composedComponent, falsy } from "utils/functions"
import { appActions } from "commons/reducer"
import styles from "./styles"

import TablePreventiveMaintenance from "./components/TablePreventiveMaintenance"
import DeleteModal from "commons/components/DeleteModal"
import PreventiveMaintenanceForm from "./components/PreventiveMaintenanceForm"
import MenuButton from "commons/components/MenuButton"
import { VALIDATE } from "utils/validations"

const PreventiveMaintenance = (props) => {
  const { classes, actions, controls } = props
  useEffect(() => {
    actions.getStateFromApi()
  }, [])

  const [preventiveMaintenanceList, setPreventiveMaintenancesList] = useState([])
  useEffect(() => {
    const filteredPreventiveMaintenances = Object.values(props.objects.preventiveMaintenances)
    setPreventiveMaintenancesList(filteredPreventiveMaintenances)
  }, [props.objects.preventiveMaintenances, props.objects.preventiveMaintenanceFiles])

  function onChangeControls(event) {
    const property = !!event.target.name ? event.target.name : event.currentTarget.id
    actions.changeControls({ [property]: event.target.value }, { [property]: false })
  }

  const onToggleModelDetail = () => {}

  const onToggleModel = () => {
    actions.toggleCreateModal()
  }

  const onToggleUpdatedModal = (id = "") => {
    if (typeof id === "number" && !controls.toEditId.localeCompare("")) {
      const item = Object.values(props.objects.preventiveMaintenances).find(
        (item) => item.id === Number(id)
      )
      actions.toggleUpdatedModal(item)
    } else {
      actions.toggleUpdatedModal()
    }
  }

  const handleOpenDeletedModal = (id = "") => {
    actions.toggleDeletedModal(id)
  }

  const submit = () => {
    if (
      falsy(props.formControls.name) ||
      falsy(props.formControls.value) ||
      !VALIDATE.NUMBER(props.formControls.value)
    ) {
      props.actions.setInvalidControls({
        name: falsy(props.formControls.name),
        value: falsy(props.formControls.value) || !VALIDATE.NUMBER(props.formControls.value),
      })
    } else {
      if (props.edit) {
        actions.updatePreventiveMaintenance(controls.toEditId, props.formControls)
      } else {
        actions.addPreventiveMaintenance(props.formControls)
      }
    }
  }

  const submitDelete = () => {
    actions.deletePreventiveMaintenance(controls.toDeleteId)
  }

  const normalizeToSelect = (data) => {
    data = Object.values(data)
    const selectData = data.map((model) => ({ value: model.id, label: model.label }))
    return selectData
  }

  return (
    <>
      <Grid container justify="center" alignItems="center" style={{ margin: 0, height: "100vh" }}>
        <Grid item md={12} lg={12} xs={12}>
          <Card>
            <CardContent>
              <Typography className={classes.title} variant="h3" component="h3">
                Mantenciones preventivas
              </Typography>
              <TablePreventiveMaintenance
                className={{ icon: classes.icon, p: classes.p }}
                data={preventiveMaintenanceList}
                onModal={onToggleModelDetail}
                handleOpenUpdatedModal={onToggleUpdatedModal}
                handleOpenDeletedModal={handleOpenDeletedModal}
              />
            </CardContent>
          </Card>
        </Grid>
        <DeleteModal
          toggleForm={handleOpenDeletedModal}
          isOpen={props.modal.deleted.status}
          title="Eliminar Mantención"
          description="¿Desea eliminar esta mantención?"
          labelAction="eliminar"
          submit={submitDelete}
        />

        <PreventiveMaintenanceForm
          className={classes}
          isOpen={props.modal.form.status}
          edit={props.edit}
          controls={props.formControls}
          errors={props.invalidControls}
          helperFilters={{ roles: normalizeToSelect(props.objects.roles) }}
          changeControls={onChangeControls}
          submit={submit}
          toggleForm={props.edit ? onToggleUpdatedModal : onToggleModel}
        />
      </Grid>

      <MenuButton className={classes.fab} handleToggle={onToggleModel} />
    </>
  )
}

export default composedComponent(PreventiveMaintenance, saga, {
  saga: "sagaPreventiveMaintenance",
  middlewares: [withStyles(styles)],
  states: [
    "maintainerPreventiveMaintenance.formControls",
    "maintainerPreventiveMaintenance.invalidControls",
    "maintainerPreventiveMaintenance.controls",
    "maintainerPreventiveMaintenance.modal",
    "maintainerPreventiveMaintenance.edit",
    "app.objects",
  ],
  actions: [maintainerPreventiveMaintenanceActions, appActions],
})
