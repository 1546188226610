import { Grid, Typography } from "@material-ui/core"
import CustomDateGrid from "commons/components/CustomDateGrid"
import React from "react"

const BudgetSparePartsTable = (props) => {
  const { budgetSpareParts, className } = props
  let totalBudgetSpareParts = budgetSpareParts ? budgetSpareParts : []
  let totalValue = 0
  const height = `${totalBudgetSpareParts.length * 100}px`
  totalValue = totalBudgetSpareParts.reduce((acc, sparePart) => acc + sparePart.price, 0)

  return (
    <>
      <Grid
        container
        item
        md={12}
        lg={12}
        xs={12}
        justifyContent="start"
        className={className.containerTopMargin}
      >
        <Grid container md={3} lg={3} xs={3} className={className.tableHeader}>
          Repuestos
        </Grid>
      </Grid>
      <Grid container item md={12} lg={12} xs={12} justifyContent="center">
        <Grid item md={1} lg={1} xs={1} className={className.borderCell}>
          Código
        </Grid>
        <Grid item md={6} lg={6} xs={6} className={className.borderCell}>
          Descripción
        </Grid>
        <Grid item md={1} lg={1} xs={1} className={className.borderCell}>
          Unidades
        </Grid>
        <Grid item md={2} lg={2} xs={2} className={className.borderCell}>
          Valor neto
        </Grid>
        <Grid item md={2} lg={2} xs={2} className={className.borderCell}>
          Total
        </Grid>
        {totalBudgetSpareParts.map((sparePart) => (
          <>
            <Grid item md={1} lg={1} xs={1} className={className.leftCell}>
              {sparePart.spare_parts_cost.code}
            </Grid>
            <Grid item md={6} lg={6} xs={6} className={className.alignedLeft}>
              {sparePart.spare_parts_cost.description}
            </Grid>
            <Grid
              container
              md={1}
              lg={1}
              xs={1}
              justifyContent="flex-end"
              className={className.alignedRight}
            >
              {sparePart.quantity}
            </Grid>
            <Grid
              container
              md={2}
              lg={2}
              xs={2}
              justifyContent="flex-end"
              className={className.alignedRight}
            >
              $ {new Intl.NumberFormat("en-DE").format(sparePart.price / sparePart.quantity)}
            </Grid>
            <Grid
              container
              md={2}
              lg={2}
              xs={2}
              justifyContent="flex-end"
              className={className.rightCell}
            >
              $ {new Intl.NumberFormat("en-DE").format(sparePart.price)}
            </Grid>
          </>
        ))}
        <Grid item md={10} lg={10} xs={10} className={className.borderCell}>
          SUBTOTAL
        </Grid>
        <Grid
          container
          md={2}
          lg={2}
          xs={2}
          justifyContent="flex-end"
          className={className.borderCell}
        >
          $ {new Intl.NumberFormat("en-DE").format(totalValue)}
        </Grid>
      </Grid>
    </>
  )
}

export default BudgetSparePartsTable
