import { takeLatest, spawn, put } from "redux-saga/effects"
import { SERVICE } from "../reducer"
import apiRequest, { apiSuccess, postForm, get, update } from "utils/api"
import { APP } from "commons/reducer"

function* getService() {
  yield takeLatest(SERVICE.GET_SERVICE_DETAILS, function* (action) {
    const response = yield apiRequest(`api/v1/services/${action.controls.id}`, {
      method: "get",
    })
    if (!response.error) {
      yield put(apiSuccess(SERVICE.GET_SERVICE_DETAILS_SUCCESS, response))
    } else {
      yield put(apiSuccess(SERVICE.GET_SERVICE_DETAILS_ERROR, response))
    }
  })
}

function* getEventTypes() {
  yield takeLatest(SERVICE.GET_SERVICE_EVENTS, function* () {
    const response = yield get(`api/v1/event-types`)
    if (!response.error) {
      yield put(apiSuccess(SERVICE.GET_SERVICE_EVENTS_SUCCESS, response))
    } else {
      yield put(apiSuccess(SERVICE.GET_SERVICE_EVENTS_ERROR, response))
    }
  })
}

function* createBudget() {
  yield takeLatest(SERVICE.POST_CREATE_SBUDGET, function* (action) {
    let body = new FormData()
    for (const key of Object.keys(action.controls)) {
      body.append(`${key}`, action.controls[key])
    }

    action.controls.files.map((file, key) => {
      body.append(`file${key}`, file)
    })

    const response = yield postForm("api/v1/budgets/", body, false)
    if (!response.error) {
      yield put(apiSuccess(SERVICE.POST_CREATE_SBUDGET_SUCCESS, response))
      yield put(apiSuccess(APP.SET_SUCCESS, response))
    } else {
      yield put(apiSuccess(SERVICE.POST_CREATE_SBUDGET_ERROR, response))
      yield put(apiSuccess(APP.SET_ERROR, response))
    }
  })
}

function* updateBudget() {
  yield takeLatest(SERVICE.PATCH_SBUDGET_UPDATE, function* (action) {
    let body = new FormData()
    for (const key of Object.keys(action.controls)) {
      body.append(`${key}`, action.controls[key])
    }
    if (action.controls.files.length > 0) {
      action.controls.files.map((file, key) => {
        body.append(`file${key}`, file)
      })
    }
    const response = yield update(
      `api/v1/services/${action.controls.serviceId}/budget/${action.controls.budgetId}`,
      body,
      false
    )
    if (!response.error) {
      yield put(apiSuccess(SERVICE.PATCH_SBUDGET_UPDATE_SUCCESS, response))
      yield put(apiSuccess(APP.SET_SUCCESS, response))
    } else {
      yield put(apiSuccess(SERVICE.PATCH_SBUDGET_UPDATE_ERROR, response))
      yield put(apiSuccess(APP.SET_ERROR, response))
    }
  })
}

function* createEvent() {
  yield takeLatest(SERVICE.POST_CREATE_SEVENT, function* (action) {
    let body = new FormData()
    for (const key of Object.keys(action.controls)) {
      body.append(`${key}`, action.controls[key])
    }
    action.controls.files.map((file, key) => {
      body.append(`file${key}`, file)
    })

    const response = yield postForm("api/v1/events/", body, false)
    if (!response.error) {
      yield put(apiSuccess(SERVICE.POST_CREATE_SEVENT_SUCCESS, response))
      yield put(apiSuccess(APP.SET_SUCCESS, response))
    } else {
      yield put(apiSuccess(SERVICE.POST_CREATE_SEVENT_ERROR, response))
      yield put(apiSuccess(APP.SET_ERROR, response))
    }
  })
}

function* getBudget() {
  yield takeLatest(SERVICE.GET_SBUDGET, function* (action) {
    const response = yield get(
      `api/v1/services/${action.controls.idService}/budget/${action.controls.id}`
    )
    if (!response.error) {
      yield put(apiSuccess(SERVICE.GET_SBUDGET_SUCCESS, response))
    } else {
      yield put(apiSuccess(SERVICE.GET_SBUDGET_ERROR, response))
      yield put(apiSuccess(APP.SET_ERROR, response))
    }
  })
}

function* getEvent() {
  yield takeLatest(SERVICE.GET_SEVENT, function* (action) {
    const response = yield get(
      `api/v1/services/${action.controls.idService}/event/${action.controls.id}`
    )
    if (!response.error) {
      yield put(apiSuccess(SERVICE.GET_SEVENT_SUCCESS, response))
    } else {
      yield put(apiSuccess(SERVICE.GET_SEVENT_ERROR, response))
      yield put(apiSuccess(APP.SET_ERROR, response))
    }
  })
}

function* updateBudgetAccept() {
  yield takeLatest(SERVICE.PATCH_SBUDGET_ACCEPT, function* (action) {
    let body = new FormData()
    for (const key of Object.keys(action.controls)) {
      body.append(`${key}`, action.controls[key])
    }
    if (action.controls.budgetFiles.length > 0) {
      action.controls.budgetFiles.map((file, key) => {
        body.append(`file${key}`, file)
      })
    }

    const response = yield update(
      `api/v1/services/${action.controls.serviceId}/budget/${action.controls.budgetId}/accept`,
      body,
      false
    )
    if (!response.error) {
      yield put(apiSuccess(SERVICE.PATCH_SBUDGET_ACCEPT_SUCCESS, response))
      yield put(apiSuccess(APP.SET_SUCCESS, response))
    } else {
      yield put(apiSuccess(SERVICE.PATCH_SBUDGET_ACCEPT_ERROR, response))
      yield put(apiSuccess(APP.SET_ERROR, response))
    }
  })
}

function* updateBudgetReject() {
  yield takeLatest(SERVICE.PATCH_SBUDGET_REJECT, function* (action) {
    let body = new FormData()
    for (const key of Object.keys(action.controls)) {
      body.append(`${key}`, action.controls[key])
    }
    const response = yield update(
      `api/v1/services/${action.controls.serviceId}/budget/${action.controls.budgetId}/reject`,
      body,
      false
    )
    if (!response.error) {
      yield put(apiSuccess(SERVICE.PATCH_SBUDGET_REJECT_SUCCESS, response))
      yield put(apiSuccess(APP.SET_SUCCESS, response))
    } else {
      yield put(apiSuccess(SERVICE.PATCH_SBUDGET_REJECT_ERROR, response))
      yield put(apiSuccess(APP.SET_ERROR, response))
    }
  })
}

export default function* root() {
  yield spawn(getService)
  yield spawn(getEventTypes)
  yield spawn(createBudget)
  yield spawn(updateBudget)
  yield spawn(createEvent)
  yield spawn(getBudget)
  yield spawn(getEvent)
  yield spawn(updateBudgetAccept)
  yield spawn(updateBudgetReject)
}
