import DropDownButton from "commons/components/DropDownButton"
import AuthUserContext from "commons/context/user"
import React from "react"
import { useHistory } from "react-router-dom"

const TableActions = ({
  id,
  cotizationNumber,
  status,
  serviceStatus,
  handleOpenModal,
  handleOpenModalEdit,
  handleOpenModalModelBudgetAccept,
  handleOpenModalBudgetRecject,
  withFiles,
}) => {
  const authUser = React.useContext(AuthUserContext)

  const history = useHistory()

  const isOnHold = () => {
    return !status?.localeCompare("En espera")
  }

  const isOnApprovatedService = () => {
    return !serviceStatus?.localeCompare("Aprobado")
  }

  const isOnHoldService = () => {
    return !serviceStatus?.localeCompare("En espera")
  }

  const options = [
    {
      action: () => {
        if (withFiles) handleOpenModal(id)
        else history.push(`/budget/${id}`)
      },
      title: "Ver detalle",
      roles: ["Administrator", "Approver", "Observer", "Scheduler"],
    },
  ]

  const generalOptions = [
    {
      action: () => {
        if (withFiles) handleOpenModal(id)
        else history.push(`/budget/${id}`)
      },
      title: "Ver detalle",
      roles: ["Administrator", "Approver", "Observer", "Scheduler"],
    },
    {
      action: () => {
        handleOpenModalEdit(id)
      },
      title: "Editar",
      roles: ["Administrator", "Approver"],
    },
    {
      action: () => {
        handleOpenModalModelBudgetAccept(id)
      },
      title: "Aprobar",
      roles: ["Administrator", "Approver"],
    },
    {
      action: () => {
        handleOpenModalBudgetRecject(id)
      },
      title: "Rechazar",
      roles: ["Administrator", "Approver"],
    },
  ]

  // const filterRole = (isOnHold() ? options : generalOptions).filter((option) =>
  //   option.roles.includes(authUser?.rol?.name)
  // )

  // Si esta aprobado o en espera, y budget esta en espera:  budgets editar / rechazar / aprobar
  // Sino solo ver budgets ver
  const filterRole = (
    (isOnApprovatedService() || isOnHoldService()) && isOnHold() ? generalOptions : options
  ).filter((option) => option.roles.includes(authUser?.rol?.name))

  return (
    <>
      <DropDownButton title={"Acción"} options={filterRole} />
    </>
  )
}

export default TableActions
