import React from "react"

import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import moment from "moment"
import { truthty } from "utils/functions"
import { IconButton } from "@material-ui/core"
import HighlightOffIcon from "@material-ui/icons/HighlightOff"

const DatePickerLanguage = (props) => {
  const [value, setValue] = React.useState("")
  const [active, setActive] = React.useState(false)

  React.useEffect(() => {
    if (truthty(props.value)) {
      setValue(moment(props.value).format("DD-MM-YYYY"))
    } else {
      setValue("")
    }
  }, [props.value])

  function changeControls(value) {
    setValue(value)
    props.onChange(
      _createObjectValue(value),
      !active && props.disableBox ? null : moment(value).format("YYYY-MM-DD")
    )
  }

  function handleClear() {
    setValue(null)
    props.onChange(_createObjectValue(""))
  }

  function _createObjectValue(value) {
    value = !value ? "" : moment(value).format("YYYY-MM-DD")
    return {
      persist: () => {},
      target: { value: value, id: props.name, name: props.name, type: "date" },
    }
  }

  return (
    <>
      <KeyboardDatePicker
        // disableScrollLock={true}
        style={props.style}
        variant="inline"
        disableToolbar
        value={value}
        onChange={changeControls}
        autoOk={true}
        format="dd/MM/yyyy"
        margin="normal"
        placeholder="dd/mm/aaaa"
        id={props.id || "date"}
        label={props.label || "date"}
        key={`${props.label}-key`}
        name={props.id || ""}
        error={props.error}
        helperText={props.helperText}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          startAdornment: (
            <IconButton
              onClick={() => handleClear()}
              disabled={!value}
              hidden={!value}
              style={{ order: 1, padding: 0 }}
            >
              <HighlightOffIcon fontSize="small"></HighlightOffIcon>
            </IconButton>
          ),
        }}
      />
    </>
  )
}

export default DatePickerLanguage
