import { createAction, createReducer } from "utils/reducer"

const PATH = "maintainerBudget/"

export const BUDGETSMAINTAINER = {
  GET_BUDGETS: `${PATH}GET_BUDGETS`,
  GET_BUDGETS_SUCCESS: `${PATH}GET_BUDGETS_SUCCESS`,
  GET_BUDGETS_ERROR: `${PATH}GET_BUDGETS_ERROR`,

  CHANGE_FILTERS: `${PATH}CHANGE_FILTERS`,
  CLEAR_FILTERS: `${PATH}CLEAR_FILTERS`,
}

export const budgetsMaintainerActions = {
  getBudgets: createAction(BUDGETSMAINTAINER.GET_BUDGETS, "controls"),
  changeFilters: createAction(BUDGETSMAINTAINER.CHANGE_FILTERS, "controls"),
  clearFilters: createAction(BUDGETSMAINTAINER.CLEAR_FILTERS, "controls"),
}

const initialState = {
  controls: {
    loading: false,
    getInfo: false,
    error: false,
    details: {},
    budgets: [],
    filters: {
      schedulerName: "",
      cotizationNumber: "",
      dateService: null,
      addedDate: null,
      statusBudget: "",
      vehicleBrand: "",
      vehicleModel: "",
    },
  },
}

const maintainerBudget = createReducer(initialState, {
  [BUDGETSMAINTAINER.GET_BUDGETS](state, action) {
    state.controls.loading = true
    state.controls.getInfo = true
    state.controls.error = false
  },
  [BUDGETSMAINTAINER.GET_BUDGETS_SUCCESS](state, action) {
    state.controls.loading = false
    state.controls.getInfo = false
    state.controls.error = false
    state.controls.budgets = action.payload.budgets
  },
  [BUDGETSMAINTAINER.GET_BUDGETS_ERROR](state, action) {
    state.controls.loading = false
    state.controls.getInfo = false
    state.controls.error = true
  },
  [BUDGETSMAINTAINER.CHANGE_FILTERS](state, action) {
    state.controls.loading = false
    state.controls.getInfo = false
    state.controls.error = false
    state.controls.filters[action.controls.name] = action.controls.value
  },
  [BUDGETSMAINTAINER.CLEAR_FILTERS](state, action) {
    state.controls.loading = false
    state.controls.getInfo = false
    state.controls.error = false
    state.controls.filters = { ...initialState.controls.filters }
  },
})

export default maintainerBudget
