// @ts-nocheck
import React from "react"

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core"

import FileCopyIcon from "@material-ui/icons/FileCopy"

import Parrafo from "commons/components/Parrafo"
import { fCurrency, fDateUtc } from "utils/functions"

const FormModalBudget = (props) => {
  const { style, submit, data } = props

  const renderStatus = () => {
    switch (data?.status) {
      case "Aprobado":
        return <Parrafo style={{ color: "green" }} title={"Estado"} value={data.status} />
      case "Rechazado":
        return <Parrafo style={{ color: "red" }} title={"Estado"} value={data.status} />
      case "En espera":
        return <Parrafo style={{ color: "#ff7d00" }} title={"Estado"} value={data.status} />
    }
  }

  const renderAttachAcceptFiles = () => {
    return !!data?.confirmationFiles?.length ? (
      data?.confirmationFiles.map((file, key) => (
        <p key={key}>
          <a target="_blank" rel="noopener noreferrer" href={file.path}>
            <FileCopyIcon />
            {file.name}
          </a>
        </p>
      ))
    ) : (
      <p>Sin archivos adjuntos</p>
    )
  }

  const renderAttachFiles = () => {
    return !!data?.files?.length ? (
      data?.files.map((file, key) => (
        <p key={key}>
          <a target="_blank" rel="noopener noreferrer" href={file.path}>
            <FileCopyIcon />
            {file.name}
          </a>
        </p>
      ))
    ) : (
      <p>Sin archivos adjuntos</p>
    )
  }

  const isAprobado = () => {
    return !!data?.status?.localeCompare("En espera")
  }

  return (
    <Dialog
      fullWidth
      onClose={props.toggleForm}
      aria-labelledby="simple-dialog-title"
      open={props.isOpen}
    >
      <DialogTitle>Presupuesto</DialogTitle>
      <DialogContent className={style}>
        <form onSubmit={submit}>
          <Grid container spacing={0} justify="center">
            <Grid item xs={10}>
              <Parrafo title={"Fecha"} value={fDateUtc(data?.date)} />
            </Grid>

            <Grid item xs={10}>
              <Parrafo title={"Número  de cotización"} value={data?.cotizationNumber} />
            </Grid>

            <Grid item xs={10}>
              <Parrafo title={"Total"} value={fCurrency.format(data.total)} />
            </Grid>

            <Grid item xs={10}>
              <Parrafo title={"Comentario"} value={data.commentary ?? "N/A"} />
            </Grid>

            <Grid item xs={10}>
              {renderStatus()}
            </Grid>

            <Grid item xs={10}>
              <Parrafo title={"Observación"} value={data.observation ?? "N/A"} />
            </Grid>

            {isAprobado() ? (
              <Grid item xs={10}>
                <Parrafo title={"Archivos firmados"} value={""} />
                {renderAttachAcceptFiles()}
              </Grid>
            ) : (
              ""
            )}

            <Grid item xs={10}>
              <Parrafo title={"Archivos adjuntos"} value={""} />
              {renderAttachFiles()}
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.toggleForm} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default FormModalBudget
