import React from "react"
import { Typography } from "@material-ui/core"
import CustomDateGrid from "commons/components/CustomDateGrid"
import TableActions from "./Actions"
import CheckIcon from "@material-ui/icons/Check"
import ClearIcon from "@material-ui/icons/Clear"

const TableWorkShop = ({
  className,
  data,
  onModal,
  handleOpenUpdatedModal,
  handleOpenDeletedModal,
  handleOpenEnableModal,
}) => {
  const columns = [
    { field: "plateNumber", headerName: "Patente", flex: 1, filterable: false, sortable: false },
    { field: "model", headerName: "Modelo", flex: 1, filterable: false, sortable: false },
    { field: "vin", headerName: "Vin", flex: 1, filterable: false, sortable: false },
    {
      field: "enable",
      headerName: "Estado",
      flex: 1,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Typography component="div">
          <div className="flex-section">
            {params?.row?.enable ? (
              <CheckIcon color={"primary"} />
            ) : (
              <ClearIcon color={"secondary"} />
            )}
          </div>
        </Typography>
      ),
    },
    {
      field: "Actions",
      headerName: "Acciones",
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Typography component="div">
          <div className="flex-section">
            <TableActions
              id={params.row?.id}
              enable={params.row?.enable}
              handleOpenModal={onModal}
              handleOpenUpdatedModal={handleOpenUpdatedModal}
              handleOpenDeletedModal={handleOpenDeletedModal}
              handleOpenEnableModal={handleOpenEnableModal}
            />
          </div>
        </Typography>
      ),

      flex: 1,
    },
  ]

  return (
    <div>
      <CustomDateGrid rows={data} columns={columns} />
    </div>
  )
}

export default TableWorkShop
