import camelize from "camelcase-keys-deep"
export default class PreventiveMaintenance {
  id = 0

  constructor(data) {
    const props = camelize(data)
    Object.assign(this, props)
  }

  entity() {
    let response = null
    if (this.entityId) {
      response = this.env().Entity.findBy("id", this.entityId)
    }
    return response
  }

  get file() {
    let preventiveMaintenanceFiles = this.env().PreventiveMaintenanceFile.findBy(
      "preventiveMaintenanceId",
      this.id
    )
    if (preventiveMaintenanceFiles) {
      const file = this.env().File.findBy("id", preventiveMaintenanceFiles.fileId)
      return file
    }
    return []
  }
}
