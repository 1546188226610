import React from 'react';

//Material
import { Box, CircularProgress, Container, Grid } from '@material-ui/core';

const LoadingScreenCircular = () => {
  return (
    <Container style={{height:'100%'}}>
      <Grid container justifyContent='center' alignItems='center' component={Box} height='100%'>
        <CircularProgress />
      </Grid>
    </Container>
  );
};

export default LoadingScreenCircular;
