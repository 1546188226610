import DropDownButton from "commons/components/DropDownButton"
import React from "react"
import { useHistory } from "react-router"

const TableActions = ({ id, handleOpenModal }) => {
  // const history = useHistory();

  const options = [
    {
      action: () => {
        handleOpenModal(id)
      },
      title: "Ver detalle",
    },
  ]

  return (
    <>
      <DropDownButton title={"Acción"} options={options} />
    </>
  )
}

export default TableActions
