import { takeLatest, spawn, put } from "redux-saga/effects"
import { MAINTAINERPREVENTIVEMAINTENANCE } from "../reducer"
import { apiSuccess, destroy, get, post, postForm, update } from "utils/api"
import { APP } from "commons/reducer"

function* getStateFromApi() {
  yield takeLatest(MAINTAINERPREVENTIVEMAINTENANCE.GET_STATE_FROM_API, function* (action) {
    const response = yield get("api/v1/preventive-maintenances/model-env")
    yield put(apiSuccess(MAINTAINERPREVENTIVEMAINTENANCE.GET_STATE_FROM_API_SUCCESS, response))
  })
}

function* addPreventiveMaintenance() {
  yield takeLatest(
    MAINTAINERPREVENTIVEMAINTENANCE.POST_ADD_PREVENTIVEMAINTENANCE,
    function* (action) {
      // const body = action.controls
      let body = new FormData()
      for (const key of Object.keys(action.controls)) {
        body.append(`${key}`, action.controls[key])
      }
      action.controls.files.map((file, key) => {
        body.append(`file${key}`, file)
      })
      const response = yield postForm("api/v1/preventive-maintenances", body, false)
      if (!response.error) {
        yield put(
          apiSuccess(
            MAINTAINERPREVENTIVEMAINTENANCE.POST_ADD_PREVENTIVEMAINTENANCE_SUCCESS,
            response
          )
        )
        yield put(apiSuccess(APP.SET_SUCCESS, response))
      } else {
        yield put(
          apiSuccess(MAINTAINERPREVENTIVEMAINTENANCE.POST_ADD_PREVENTIVEMAINTENANCE_ERROR, response)
        )
        yield put(apiSuccess(APP.SET_ERROR, response))
      }
    }
  )
}

function* updatePreventiveMaintenance() {
  yield takeLatest(
    MAINTAINERPREVENTIVEMAINTENANCE.POST_UPDATE_PREVENTIVEMAINTENANCE,
    function* (action) {
      let body = new FormData()
      for (const key of Object.keys(action.controls)) {
        if (key == "file") {
          body.append(`fileId`, action.controls[key]["id"] || null)
        } else {
          body.append(`${key}`, action.controls[key])
        }
      }
      action.controls.files.map((file, key) => {
        body.append(`file${key}`, file)
      })
      const response = yield update(`api/v1/preventive-maintenances/${action.id}`, body, false)
      if (!response.error) {
        yield put(
          apiSuccess(
            MAINTAINERPREVENTIVEMAINTENANCE.POST_UPDATE_PREVENTIVEMAINTENANCE_SUCCESS,
            response
          )
        )
        yield put(apiSuccess(APP.SET_SUCCESS, response))
      } else {
        yield put(
          apiSuccess(
            MAINTAINERPREVENTIVEMAINTENANCE.POST_UPDATE_PREVENTIVEMAINTENANCE_ERROR,
            response
          )
        )
        yield put(apiSuccess(APP.SET_ERROR, response))
      }
    }
  )
}

function* deletePreventiveMaintenance() {
  yield takeLatest(
    MAINTAINERPREVENTIVEMAINTENANCE.DELETE_PREVENTIVEMAINTENANCE,
    function* (action) {
      const response = yield destroy(`api/v1/preventive-maintenances/${action.id}`)
      if (!response.error) {
        yield put(
          apiSuccess(MAINTAINERPREVENTIVEMAINTENANCE.DELETE_PREVENTIVEMAINTENANCE_SUCCESS, {
            item: response,
            type: "preventiveMaintenances",
          })
        )
        yield put(apiSuccess(APP.SET_SUCCESS, response))
      } else {
        yield put(
          apiSuccess(MAINTAINERPREVENTIVEMAINTENANCE.DELETE_PREVENTIVEMAINTENANCE_ERROR, response)
        )
        yield put(apiSuccess(APP.SET_ERROR, response))
      }
    }
  )
}

export default function* root() {
  yield spawn(getStateFromApi)
  yield spawn(addPreventiveMaintenance)
  yield spawn(updatePreventiveMaintenance)
  yield spawn(deletePreventiveMaintenance)
}
