import { Button, TableBody, TableCell } from "@material-ui/core"
import React from "react"

const LaborsTableItem = (props) => {
  const { laborSelected, removeBudgetLabor } = props
  return (
    <TableBody>
      <TableCell>{laborSelected.code}</TableCell>
      <TableCell>{laborSelected.description}</TableCell>
      <TableCell>{laborSelected?.laborCostByVehicleType[0]?.manHours}</TableCell>
      <TableCell>$ {new Intl.NumberFormat("en-DE").format(laborSelected.total)}</TableCell>
      <TableCell>
        <Button
          variant="outlined"
          style={{ color: "red", borderColor: "red" }}
          onClick={() => removeBudgetLabor(laborSelected.id)}
        >
          Eliminar
        </Button>
      </TableCell>
    </TableBody>
  )
}
export default LaborsTableItem
