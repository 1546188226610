import React from "react"
import Skeleton from "@material-ui/lab/Skeleton"
import { Container, Grid, Card, CardContent } from "@material-ui/core"

const Skeletop = (props) => {
  const { classes } = props
  return (
    <Container maxWidth={false}>
      <Grid
        container
        spacing={3}
        justify="center"
        alignItems="center"
        className={classes.container}
      >
        <Grid item md={12} lg={12} xs={12}>
          <Grid item md={12} lg={4} xs={4}>
            <Skeleton variant="rect" height={"50px"} style={{ marginBottom: "20px" }} />
          </Grid>

          <Card>
            <CardContent>
              <Grid item md={12} lg={4} xs={4}>
                <Skeleton variant="rect" height={"50px"} style={{ marginBottom: "20px" }} />
              </Grid>
              <Grid item md={12} lg={12} xs={12}>
                <Skeleton variant="rect" height={"50px"} style={{ marginBottom: "20px" }} />
              </Grid>

              <Skeleton variant="rect" height={"400px"} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Skeletop
