import React from "react"
import { withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn"
import AnnouncementIcon from "@material-ui/icons/Announcement"
import { Fab, Menu, MenuItem } from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"
import AuthUserContext from "commons/context/user"

const StyledMenu = withStyles({
  paper: {
    maxHeight: "100%",
    overflow: "auto",
    border: "none",
    background: "none",
    "& > .MuiMenu-list": {
      maxHeight: "100%",
      overflow: "auto",
      padding: "0px",
    },
  },
})((props) => (
  <Menu
    // disableScrollLock={true}
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    {...props}
  />
))

const StyledMenuItem = withStyles((theme) => ({
  root: {
    width: "45px",
    borderRadius: "40px",
    marginBottom: "15px",
    padding: "0",
    border: "0",
    margin: "0",
  },
}))(MenuItem)

const StyledButton = withStyles((theme) => ({
  root: {
    width: "100%",
    minWidth: "45px",
    height: "45px",
    borderRadius: "40px",
    // backgroundColor: "#072140",
    backgroundColor: "rgb(24 85 23)",
    color: theme.palette.common.white,
    padding: "0",
    border: "0",
    margin: "0",
    "&:hover": { backgroundColor: "rgb(24 85 23)" },
    "&:focus": {
      backgroundColor: "rgb(24 85 23)",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: "rgb(24 85 23)",
      },
    },
  },
}))(Button)

const MenuToggleButton = ({ className, onToggleModelBudget, onToggleModelEvent }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const authUser = React.useContext(AuthUserContext)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleToggleModelEvent = () => {
    onToggleModelEvent()
    setAnchorEl(null)
  }

  const handleToggleModelBudget = () => {
    onToggleModelBudget()
    setAnchorEl(null)
  }

  const options = [
    {
      render: (
        <StyledButton onClick={handleToggleModelEvent}>
          <AnnouncementIcon fontSize="small" />
        </StyledButton>
      ),
      roles: ["Administrator", "Approver", "Scheduler"],
    },
    {
      render: (
        <StyledButton onClick={handleToggleModelBudget}>
          <MonetizationOnIcon fontSize="small" />
        </StyledButton>
      ),
      roles: ["Administrator"],
    },
  ]

  const filterRole = options.filter((option) => option.roles.includes(authUser?.rol?.name))

  if (!filterRole.length) return <></>
  return (
    <>
      <Fab color="primary" aria-label="add" className={className.fixedBtnAdd} onClick={handleClick}>
        <AddIcon />
      </Fab>

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {filterRole.map((option, key) => {
          return <StyledMenuItem key={key}>{option.render}</StyledMenuItem>
        })}
      </StyledMenu>
    </>
  )
}

export default MenuToggleButton
