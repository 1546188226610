import DropDownButton from "commons/components/DropDownButton"
import AuthUserContext from "commons/context/user"
import React from "react"
import { useHistory } from "react-router"

const TableActions = ({
  id,
  budgetId,
  status,
  budgetStatus,
  handleOpenEditModal,
  handleOpenModalReject,
  handleOpenModalAccept,
  handleOpenModalModelBudgetAccept,
  handleOpenModalBudgetRecject,
}) => {
  const history = useHistory()
  const authUser = React.useContext(AuthUserContext)

  const isOnHold = () => {
    return !status?.localeCompare("En espera")
  }

  const haveNotBudget = () => {
    return !budgetStatus?.localeCompare("En espera")
  }

  const isBudgetOnHoldAndServiceApproved = () => {
    return !budgetStatus?.localeCompare("En espera") && !status?.localeCompare("Aprobado")
  }

  const options = [
    {
      action: () => {
        history.push(`/service/${id}/`)
      },
      title: "Ver detalle",
      roles: ["Administrator", "Approver", "Observer", "Scheduler"],
    },
  ]

  const serviceOptions = [
    {
      action: () => {
        history.push(`/service/${id}/`)
      },
      title: "Ver detalle",
      roles: ["Administrator", "Approver", "Observer", "Scheduler"],
    },
    {
      action: () => {
        handleOpenEditModal(id)
      },
      title: "Actualizar servicio",
      roles: ["Administrator"],
    },
    {
      action: () => {
        handleOpenModalAccept(id)
      },
      title: "Aprobar servicio",
      roles: ["Administrator"],
    },
    {
      action: () => {
        handleOpenModalReject(id)
      },
      title: "Rechazar servicio",
      roles: ["Administrator"],
    },
  ]

  const budgetOptions = [
    {
      action: () => {
        history.push(`/service/${id}/`)
      },
      title: "Ver detalle",
      roles: ["Administrator", "Approver", "Observer", "Scheduler"],
    },
    {
      action: () => {
        handleOpenModalModelBudgetAccept(id, budgetId)
      },
      title: "Aprobar presupuesto",
      roles: ["Administrator", "Approver"],
    },
    {
      action: () => {
        handleOpenModalBudgetRecject(id, budgetId)
      },
      title: "Rechazar presupuesto",
      roles: ["Administrator", "Approver"],
    },
  ]

  const filterRole = (
    isOnHold() ? serviceOptions : isBudgetOnHoldAndServiceApproved() ? budgetOptions : options
  ).filter((option) => option.roles.includes(authUser?.rol?.name))

  return (
    <>
      <DropDownButton title={"Acción"} options={filterRole} />
    </>
  )
}

export default TableActions
