import { createAction, createReducer } from "utils/reducer"

// Local constants
const PATH = "contracts/"

// Global constants for saga
export const CONTRACTS = {
  GET_CONTRACTS: `${PATH}GET_CONTRACTS`,
  GET_CONTRACTS_SUCCESS: `${PATH}GET_CONTRACTS_SUCCESS`,
  GET_CONTRACTS_ERROR: `${PATH}GET_CONTRACTS_ERROR`,
}

export const contractActions = {
  getContracts: createAction(CONTRACTS.GET_CONTRACTS),
  getContractsSuccess: createAction(CONTRACTS.GET_CONTRACTS_SUCCESS),
  getContractsError: createAction(CONTRACTS.GET_CONTRACTS_ERROR),
}

const initialState = {
  loading: true,
  error: null,
}

const contractsReducer = createReducer(initialState, {
  [CONTRACTS.GET_CONTRACTS]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [CONTRACTS.GET_CONTRACTS_SUCCESS]: (state) => ({
    ...state,
    loading: false,
  }),
  [CONTRACTS.GET_CONTRACTS_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  }),
})

export default contractsReducer
