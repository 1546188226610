// @ts-nocheck
import React from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core"
import ButtonWithLoader from "commons/containers/ButtonWithLoader"
import DatePicker from "commons/components/DatePicker"

const FormModal = (props) => {
  const { className, style, onChange, submit, data, errors } = props

  const handleChange = (event) => {
    onChange(event)
  }

  return (
    <Dialog
      fullWidth
      onClose={props.toggleForm}
      aria-labelledby="simple-dialog-title"
      open={props.isOpen}
    >
      <DialogTitle>Aprobar servicio</DialogTitle>
      <DialogContent className={style}>
        <form onSubmit={submit}>
          <Grid container spacing={1} justify="center">
            <Grid item xs={10}>
              <DatePicker
                className={className.field}
                variant="outlined"
                onChange={handleChange}
                id={"approvedDate"}
                label={"Fecha para el agendamiento"}
                value={data.approvedDate}
                error={!!errors.approvedDate}
                helperText={errors.approvedDate}
                helperText={"Ingrese la fecha aprobada para el servicio"}
                fullWidth={true}
                autoComplete="off"
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.toggleForm} color="primary">
          Cancelar
        </Button>
        <ButtonWithLoader
          onClick={props.submit}
          autoFocus
          buttonId={props.buttonId}
          loading={props.loading}
        >
          Aprobar
        </ButtonWithLoader>
      </DialogActions>
    </Dialog>
  )
}

export default FormModal
