const styles = theme => ({
  container: {
    marginTop: '32px',
  },
  contractName: {
    fontWeight: "bold",
    fontSize: "32px",
    color: '#222222',
    fontFamily: 'Open Sans',
  },
  back: {
    color: '#017328',
    fontSize: '16px',
  },
})

export default styles