import colors from './colors';

const fpFix = (n) => Math.round(n * 100) / 100;

const memoize = (fn) => {
  let cache = {};
  return (...args) => {
    let n = args[0];
    if (n in cache) {
      // console.log('Fetching from cache', n);
      return cache[n];
    }
    else {
      // console.log('Calculating result', n);
      let result = fn(...args);
      cache[n] = result;
      return result;
    }
  };
};

export const createStylePatten = memoize((prefix, attr, length, multiply = 10, unit = 'px') => {
  return Object.assign(...[...Array(length).keys()]
    .map(k => ({ [`${prefix}${fpFix((k + 1 ) * multiply).toString().replace('.', '')}`]: { [attr]: `${fpFix((k + 1) * multiply)}${unit}` } })), {});
});

const mt = createStylePatten('mt', 'marginTop', 11);
const mb = createStylePatten('mb', 'marginBottom', 10);
const p = createStylePatten('p', 'padding', 11);
const pb = createStylePatten('pb', 'paddingBottom', 11);
const h = createStylePatten('h', 'height', 201, 5);
const minh = createStylePatten('minh', 'minHeight', 201, 5);
const w = createStylePatten('w', 'width', 201, 5);

const ml = createStylePatten('ml', 'marginLeft', 101, 5);
const mr = createStylePatten('mr', 'marginRight', 101, 5);
const opacity = createStylePatten('opacity', 'opacity', 10, 0.1, '');
const weight = createStylePatten('weight', 'fontWeight', 9, 100, '');

const additionalStyles = {
  fullHeight: { height: '100%' },
  fullWidth: { width: '100%' },
  colorPrimary: { color: colors.primary },
  flex1: { flex: '1' },
  absolute: { position: 'absolute' },
  relative: { position: 'relative' },
  bgOrange: { background: 'orange' },
  bgPurple: { background: 'purple' },
  bgPink: { background: 'pink' },
  bgPrimary: { background: colors.primary },
  decorationNone: { textDecoration: 'none'},
};

const obj = {
  ...mt,
  ...mb,
  ...p,
  ...pb,
  ...h,
  ...minh,
  ...w,
  ...ml,
  ...mr,
  ...opacity,
  ...weight,
  ...additionalStyles,
};

export default obj;