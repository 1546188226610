import { takeLatest, spawn, put } from "redux-saga/effects"
import { MAINTAINERWORKSHOP } from "../reducer"
import { apiSuccess, destroy, get, post, update } from "utils/api"
import { APP } from "commons/reducer"

function* getStateFromApi() {
  yield takeLatest(MAINTAINERWORKSHOP.GET_STATE_FROM_API, function* (action) {
    const response = yield get("api/v1/work-shops/model-env")
    yield put(apiSuccess(MAINTAINERWORKSHOP.GET_STATE_FROM_API_SUCCESS, response))
  })
}

function* addWorkshop() {
  yield takeLatest(MAINTAINERWORKSHOP.POST_ADD_WORKSHOP, function* (action) {
    const body = action.controls
    const response = yield post("api/v1/work-shops", body)
    if (!response.error) {
      yield put(apiSuccess(MAINTAINERWORKSHOP.POST_ADD_WORKSHOP_SUCCESS, response))
      yield put(apiSuccess(APP.SET_SUCCESS, response))
    } else {
      yield put(apiSuccess(MAINTAINERWORKSHOP.POST_ADD_WORKSHOP_ERROR, response))
      yield put(apiSuccess(APP.SET_ERROR, response))
    }
  })
}

function* updateWorkshop() {
  yield takeLatest(MAINTAINERWORKSHOP.POST_UPDATE_WORKSHOP, function* (action) {
    const body = JSON.stringify(action.controls)
    const response = yield update(`api/v1/work-shops/${action.id}`, body)
    if (!response.error) {
      yield put(apiSuccess(MAINTAINERWORKSHOP.POST_UPDATE_WORKSHOP_SUCCESS, response))
      yield put(apiSuccess(APP.SET_SUCCESS, response))
    } else {
      yield put(apiSuccess(MAINTAINERWORKSHOP.POST_UPDATE_WORKSHOP_ERROR, response))
      yield put(apiSuccess(APP.SET_ERROR, response))
    }
  })
}

function* deleteWorkshop() {
  yield takeLatest(MAINTAINERWORKSHOP.DELETE_WORKSHOP, function* (action) {
    const response = yield destroy(`api/v1/work-shops/${action.id}`)
    if (!response.error) {
      yield put(
        apiSuccess(MAINTAINERWORKSHOP.DELETE_WORKSHOP_SUCCESS, {
          item: response,
          type: "workshops",
        })
      )
      yield put(apiSuccess(APP.SET_SUCCESS, response))
    } else {
      yield put(apiSuccess(MAINTAINERWORKSHOP.DELETE_WORKSHOP_ERROR, response))
      yield put(apiSuccess(APP.SET_ERROR, response))
    }
  })
}

export default function* root() {
  yield spawn(getStateFromApi)
  yield spawn(addWorkshop)
  yield spawn(updateWorkshop)
  yield spawn(deleteWorkshop)
}
