// @ts-nocheck
import React from "react"
// material
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core"
import ButtonWithLoader from "commons/containers/ButtonWithLoader"
import CustomField from "commons/components/CustomField"

const EventTypeForm = (props) => {
  return (
    <Dialog
      fullWidth
      onClose={props.toggleForm}
      aria-labelledby="simple-dialog-title"
      open={props.isOpen}
    >
      <DialogTitle>{`${props.edit ? "Editar" : "Crear"} tipo de eventos`}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item sm={12} xs={12}>
            <CustomField
              value={props.controls.name}
              onChange={props.changeControls}
              fullWidth
              id="name"
              label="Nombre"
              error={props.errors.name}
              helperText={props.errors.name && "Debe ingresar un nombre para el tipo de evento"}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.toggleForm} color="primary">
          Cancelar
        </Button>
        <ButtonWithLoader onClick={props.submit} autoFocus buttonId="create-edit-client">
          {props.edit ? "Editar" : "Agregar"}
        </ButtonWithLoader>
      </DialogActions>
    </Dialog>
  )
}

export default EventTypeForm
