import React from "react"
import { Grid, Card, CardContent, Tooltip, Button } from "@material-ui/core"
import Parrafo from "commons/components/Parrafo"
import { fNumber, fDateUtc } from "utils/functions"
import ToolTips from "commons/components/ToolTips"

const InfoService = ({ details, className, titleCard1, titleCard2 }) => {
  const showServiceDescription = (type, maintenance) => {
    return getMaintenanceType(type, maintenance)?.length > 150 ? (
      <ToolTips
        className={{ p: className.pEllipsis }}
        title={"Descripción"}
        value={getMaintenanceType(type, maintenance)}
      />
    ) : (
      <Parrafo
        className={{ p: className.p }}
        title={"Descripción"}
        value={getMaintenanceType(type, maintenance)}
      />
    )
  }

  const getMaintenanceType = (type, maintenance) => {
    switch (type) {
      case "preventiva":
        return maintenance?.preventiveMaintenance?.name
      case "correctiva":
        return maintenance?.description
        break
      default:
        return maintenance?.description
        break
    }
  }

  const showDetailsToPreventiveMaintenance = (type, maintenance) => {
    if (type === "preventiva") {
      return <Parrafo title={"Nota de detalle"} value={maintenance?.description ?? "N/A"} />
    }
    return ""
  }

  const showFileToPreventiveMaintenance = (type, maintenance) => {
    if (type === "preventiva") {
      return (
        <Parrafo
          title={"Pauta de Mantención"}
          value={
            maintenance?.preventiveMaintenance?.files?.length > 0 ? (
              <a href={maintenance?.preventiveMaintenance?.files[0]?.path}>
                {maintenance?.preventiveMaintenance?.files[0]?.name}
              </a>
            ) : (
              "N/A"
            )
          }
        />
      )
    }
    return ""
  }

  const renderStatus = (details) => {
    switch (details?.status) {
      case "Aprobado":
        return <Parrafo style={{ color: "green" }} title={"Estado"} value={details?.status} />
      case "Rechazado":
        return <Parrafo style={{ color: "red" }} title={"Estado"} value={details?.status} />
      case "En espera":
        return <Parrafo style={{ color: "#ff7d00" }} title={"Estado"} value={details?.status} />
    }
  }

  const showRejectObservation = (details) => {
    switch (details?.status) {
      case "Rechazado":
        return <Parrafo title={"Observación"} value={details?.observation ?? "N/A"} />
    }
  }

  const showInvoiceReport = (details) => {
    switch (details?.hasInvoices) {
      case "Facturado":
        return (
          <Parrafo style={{ color: "black" }} title={"Facturación"} value={details?.hasInvoices} />
        )
      case "No facturado":
        return (
          <Parrafo
            style={{ color: "#d2d2d2" }}
            title={"Facturación"}
            value={details?.hasInvoices}
          />
        )
    }
  }

  return (
    <Grid container spacing={2} style={{ marginBottom: "20px" }} alignItems="stretch">
      <Grid item md={4} lg={4} xs={12}>
        <Card className={className.card}>
          <CardContent>
            <Parrafo
              style={{ fontSize: "15px", textTransform: "uppercase" }}
              title={titleCard1}
              value={""}
            />
            <Parrafo title={"Fecha solicitada del servicio"} value={fDateUtc(details?.date)} />
            <Parrafo
              title={"Fecha de aprobación servicio"}
              value={details?.approvedDate ? fDateUtc(details?.approvedDate) : "No agendado"}
            />
            <Parrafo title={"Taller"} value={details?.workshop?.name} />
          </CardContent>
        </Card>
      </Grid>

      <Grid item md={4} lg={4} xs={12}>
        <Card className={className.card}>
          <CardContent>
            <Parrafo
              style={{ fontSize: "15px", textTransform: "uppercase" }}
              title={titleCard2}
              value={""}
            />
            <Parrafo
              className={{ p: className.p }}
              title={"Tipo de servicio"}
              value={details?.maintenanceType ?? details.type}
            />

            {showServiceDescription(details?.maintenanceType, details)}

            <Parrafo type="inline" title={"DOE"} value={details?.doe ?? "-"} />

            <Grid container spacing={1}>
              <Grid item md={6} lg={6} xs={6}>
                {showFileToPreventiveMaintenance(details?.maintenanceType, details)}
              </Grid>
              <Grid item md={6} lg={6} xs={6}>
                {showDetailsToPreventiveMaintenance(details?.maintenanceType, details)}
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item md={6} lg={6} xs={6}>
                {renderStatus(details)}
              </Grid>
              <Grid item md={6} lg={6} xs={6}>
                {showInvoiceReport(details)}
              </Grid>
            </Grid>

            {showRejectObservation(details)}
          </CardContent>
        </Card>
      </Grid>

      <Grid item md={4} lg={4} xs={12}>
        <Card className={className.card}>
          <CardContent>
            <Parrafo
              style={{ fontSize: "15px", textTransform: "uppercase" }}
              title={"Vehículo"}
              value={""}
            />
            <Parrafo
              type="inline"
              title={"Patente"}
              value={!details?.maintenanceType ? "--" : details?.vehicle?.plateNumber}
            />
            <Parrafo
              type="inline"
              title={"Modelo"}
              value={!details?.maintenanceType ? "--" : details?.vehicle?.model}
            />
            <Parrafo
              type="inline"
              title={"VIN"}
              value={!details?.maintenanceType ? "--" : details?.vehicle?.vin}
            />
            <Parrafo
              type="inline"
              title={"Kilometraje"}
              value={`${fNumber.format(details?.mileage)} km`}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default InfoService
