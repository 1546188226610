/* eslint-disable import/order */
// @ts-nocheck
/* eslint-disable import/no-unresolved */
import { createStore, compose, combineReducers } from "redux"
import { persistReducer } from "redux-persist"
import { createContext } from "react"
import localForage from "localforage"
// project
import { clone } from "utils/functions"
import enhancers, { sagaMiddleware } from "./middleware"
// reducers

import app from "commons/reducer"
import login from "screens/Login/reducer"
import home from "screens/Home/reducer"
import service from "screens/Service/reducer"
import budget from "screens/Budget/reducer"
import recoveryPassword from "screens/RecoveryPassword/reducer"
import maintainerUser from "screens/Maintainers/User/reducer"
import maintainerWorkshop from "screens/Maintainers/WorkShop/reducer"
import maintainerVehicle from "screens/Maintainers/Vehicle/reducer"
import maintainerEventType from "screens/Maintainers/EventType/reducer"
import maintainerPreventiveMaintenance from "screens/Maintainers/PreventiveMaintenance/reducer"
import contractDocuments from "screens/ContractDocuments/reducer"
import maintainerBudget from "screens/BudgetMaintainer/reducer"
import budgetDetails from "screens/BudgetDetails/reducer"
import contracts from "screens/Contracts/reducer"

//-----------------------------------------------------------

export const ReactReduxContext = createContext({})

export default function configureStore(initialState = {}) {
  const persistConfig = {
    key: "root",
    storage: localForage,
    whitelist: [],
  }

  // create reducer
  const reducers = combineReducers(
    clone(clone.OBJECT, {
      maintainerUser,
      maintainerWorkshop,
      maintainerVehicle,
      maintainerEventType,
      maintainerPreventiveMaintenance,
      maintainerBudget,
      contractDocuments,
      recoveryPassword,
      budget,
      budgetDetails,
      service,
      home,
      login,
      app,
      contracts,
    })
  )

  const pReducer = persistReducer(persistConfig, reducers)

  const store = createStore(pReducer, initialState, compose(enhancers))

  // Extensions
  store.runSaga = sagaMiddleware.run
  store.injectedSagas = {} // Saga registry

  return store
}
