// @ts-nocheck
import React from "react"

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core"
import FileCopyIcon from "@material-ui/icons/FileCopy"
import Parrafo from "commons/components/Parrafo"
import { fDateUtc } from "utils/functions"

const FormModalBudget = (props) => {
  const { style, submit, data } = props

  const renderAttachFiles = () => {
    return !!data?.files?.length ? (
      data?.files.map((file, key) => (
        <p key={key}>
          <a target="_blank" rel="noopener noreferrer" href={file.path}>
            <FileCopyIcon />
            {file.name}
          </a>
        </p>
      ))
    ) : (
      <p>Sin archivos adjuntos</p>
    )
  }

  return (
    <Dialog
      fullWidth
      onClose={props.toggleForm}
      aria-labelledby="simple-dialog-title"
      open={props.isOpen}
    >
      <DialogTitle>Evento</DialogTitle>
      <DialogContent className={style}>
        <form onSubmit={submit}>
          <Grid container spacing={0} justify="center">
            <Grid item xs={10}>
              <Parrafo title={"Fecha de ingreso"} value={fDateUtc(data?.date)} />
            </Grid>

            <Grid item xs={10}>
              <Parrafo title={"Tipo de evento"} value={data?.eventType?.name} />
            </Grid>

            <Grid item xs={10}>
              <Parrafo title={"Descripción"} value={data?.description} />
            </Grid>

            <Grid item xs={10}>
              <Parrafo title={"Archivos Adjuntos"} value={""} />
              {renderAttachFiles()}
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.toggleForm} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default FormModalBudget
