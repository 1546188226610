import { createAction, createReducer } from "utils/reducer"

// Local constants
const PATH = "service/"

// Global constants for saga
export const SERVICE = {
  GET_SERVICE_DETAILS: `${PATH}GET_SERVICE_DETAILS`,
  GET_SERVICE_DETAILS_SUCCESS: `${PATH}GET_SERVICE_DETAILS_SUCCESS`,
  GET_SERVICE_DETAILS_ERROR: `${PATH}GET_SERVICE_DETAILS_ERROR`,
  GET_SERVICE_EVENTS: `${PATH}GET_SERVICE_EVENTS`,
  GET_SERVICE_EVENTS_SUCCESS: `${PATH}GET_SERVICE_EVENTS_SUCCESS`,
  GET_SERVICE_EVENTS_ERROR: `${PATH}GET_SERVICE_EVENTS_ERROR`,

  POST_CREATE_SBUDGET: `${PATH}POST_CREATE_SBUDGET`,
  POST_CREATE_SBUDGET_SUCCESS: `${PATH}POST_CREATE_SBUDGET_SUCCESS`,
  POST_CREATE_SBUDGET_ERROR: `${PATH}POST_CREATE_SBUDGET_ERROR`,

  PATCH_SBUDGET_UPDATE: `${PATH}PATCH_SBUDGET_UPDATE`,
  PATCH_SBUDGET_UPDATE_SUCCESS: `${PATH}PATCH_SBUDGET_UPDATE_SUCCESS`,
  PATCH_SBUDGET_UPDATE_ERROR: `${PATH}PATCH_SBUDGET_UPDATE_ERROR`,

  POST_CREATE_SEVENT: `${PATH}POST_CREATE_SEVENT`,
  POST_CREATE_SEVENT_SUCCESS: `${PATH}POST_CREATE_SEVENT_SUCCESS`,
  POST_CREATE_SEVENT_ERROR: `${PATH}POST_CREATE_SEVENT_ERROR`,

  GET_SBUDGET: `${PATH}GET_SBUDGET`,
  GET_SBUDGET_SUCCESS: `${PATH}GET_SBUDGET_SUCCESS`,
  GET_SBUDGET_ERROR: `${PATH}GET_SBUDGET_ERROR`,

  GET_SEVENT: `${PATH}GET_SEVENT`,
  GET_SEVENT_SUCCESS: `${PATH}GET_SEVENT_SUCCESS`,
  GET_SEVENT_ERROR: `${PATH}GET_SEVENT_ERROR`,

  PATCH_SBUDGET_ACCEPT: `${PATH}PATCH_SBUDGET_ACCEPT`,
  PATCH_SBUDGET_ACCEPT_SUCCESS: `${PATH}PATCH_SBUDGET_ACCEPT_SUCCESS`,
  PATCH_SBUDGET_ACCEPT_ERROR: `${PATH}PATCH_SBUDGET_ACCEPT_ERROR`,
  PATCH_SBUDGET_REJECT: `${PATH}PATCH_SBUDGET_REJECT`,
  PATCH_SBUDGET_REJECT_SUCCESS: `${PATH}PATCH_SBUDGET_REJECT_SUCCESS`,
  PATCH_SBUDGET_REJECT_ERROR: `${PATH}PATCH_SBUDGET_REJECT_ERROR`,

  TOGGLE_MODAL_SBUDGET: `${PATH}TOGGLE_MODAL_SBUDGET`,
  TOGGLE_MODAL_SBUDGET_UPDATE: `${PATH}TOGGLE_MODAL_SBUDGET_UPDATE`,
  TOGGLE_MODAL_SEVENT: `${PATH}TOGGLE_MODAL_SEVENT`,
  TOGGLE_MODAL_SBUDGET_DETAILS: `${PATH}TOGGLE_MODAL_SBUDGET_DETAILS`,
  TOGGLE_MODAL_SEVENT_DETAILS: `${PATH}TOGGLE_MODAL_SEVENT_DETAILS`,
  TOGGLE_MODAL_SBUDGET_ACCEPT_REJECT: `${PATH}TOGGLE_MODAL_SBUDGET_ACCEPT_REJECT`,
}

// actions
export const serviceActions = {
  getService: createAction(SERVICE.GET_SERVICE_DETAILS, "controls"),
  createBudget: createAction(SERVICE.POST_CREATE_SBUDGET, "controls"),
  updateBudget: createAction(SERVICE.PATCH_SBUDGET_UPDATE, "controls"),

  createEvent: createAction(SERVICE.POST_CREATE_SEVENT, "controls"),
  getBudget: createAction(SERVICE.GET_SBUDGET, "controls"),
  getEvent: createAction(SERVICE.GET_SEVENT, "controls"),
  getEventTypes: createAction(SERVICE.GET_SERVICE_EVENTS),
  updateBudgetAccept: createAction(SERVICE.PATCH_SBUDGET_ACCEPT, "controls"),
  updateBudgetReject: createAction(SERVICE.PATCH_SBUDGET_REJECT, "controls"),
  toggleSbudgetModal: createAction(SERVICE.TOGGLE_MODAL_SBUDGET),
  toggleSbudgetUpdateModal: createAction(SERVICE.TOGGLE_MODAL_SBUDGET_UPDATE),
  toggleSeventModal: createAction(SERVICE.TOGGLE_MODAL_SEVENT),
  toggleSbudgetDetailsModal: createAction(SERVICE.TOGGLE_MODAL_SBUDGET_DETAILS),
  toggleSeventDetailsModal: createAction(SERVICE.TOGGLE_MODAL_SEVENT_DETAILS),
  toggleSbudgetAcceptRejectModal: createAction(SERVICE.TOGGLE_MODAL_SBUDGET_ACCEPT_REJECT),
}

const initialState = {
  controls: {
    loading: false,
    getInfo: false,
    error: false,
    id: null,
    details: {},
    budgets: [],
    events: [],
    eventTypes: [],
    budgetDetails: {},
    eventDetails: {},
    form: {
      budgets: {
        serviceId: "",
        status: "",
        cotizationNumber: "",
        sapNumber: "",
        total: "",
        commentary: "",
        date: "",
      },
      event: {
        serviceId: "",
        eventTypeId: "",
        date: "",
        description: "",
      },
    },
    modal: {
      budget: {
        status: false,
      },
      event: {
        status: false,
      },
      budgetDetails: {
        status: false,
      },
      eventDetails: {
        status: false,
      },
      budgetAcceptReject: {
        status: false,
      },
    },
  },
  edit: {
    budget: false,
    event: false,
  },
}

const service = createReducer(initialState, {
  [SERVICE.GET_SERVICE_DETAILS](state, action) {
    state.controls.loading = true
    state.controls.getInfo = true
    state.controls.error = false
    state.controls.message = ""
    state.controls.id = action.controls.id
  },
  [SERVICE.GET_SERVICE_DETAILS_SUCCESS](state, action) {
    state.controls.loading = false
    state.controls.getInfo = false
    state.controls.error = false
    state.controls.details = action.payload.service
    state.controls.budgets = action.payload.service.budgets
    state.controls.events = action.payload.service.events
  },
  [SERVICE.GET_SERVICE_DETAILS_ERROR](state, action) {
    state.controls.loading = false
    state.controls.getInfo = false
    state.controls.error = true
    state.controls.message = ""
  },
  [SERVICE.GET_SERVICE_EVENTS](state, action) {
    // state.controls.loading = true
    // state.controls.error = false
  },
  [SERVICE.GET_SERVICE_EVENTS_SUCCESS](state, action) {
    // state.controls.loading = false
    // state.controls.error = false
    state.controls.eventTypes = action.payload.eventTypes.map((eventType) => eventType.name)
  },
  [SERVICE.GET_SERVICE_EVENTS_ERROR](state, action) {
    // state.controls.loading = false
    // state.controls.error = true
  },

  [SERVICE.POST_CREATE_SBUDGET](state) {
    state.controls.loading = true
    state.controls.error = false
    state.controls.message = ""
  },
  [SERVICE.POST_CREATE_SBUDGET_SUCCESS](state, action) {
    state.controls.loading = false
    state.controls.error = false
    state.controls.budgets.push(action.payload.budget)
    state.controls.modal.budget.status = false
    state.controls.message = ""
  },
  [SERVICE.POST_CREATE_SBUDGET_ERROR](state, action) {
    state.controls.loading = false
    state.controls.error = true
    state.controls.message = "err"
  },
  [SERVICE.PATCH_SBUDGET_UPDATE](state, action) {
    state.controls.loading = true
    state.controls.error = false
    state.controls.message = ""
  },
  [SERVICE.PATCH_SBUDGET_UPDATE_SUCCESS](state, action) {
    state.controls.loading = false
    state.controls.error = false
    state.controls.budgets = state.controls.budgets.map((budget) =>
      action.payload.budget.id === budget.id ? action.payload.budget : budget
    )
    state.controls.modal.budget.status = false
    state.controls.message = ""
  },
  [SERVICE.PATCH_SBUDGET_UPDATE_ERROR](state, action) {
    state.controls.loading = false
    state.controls.error = true
    state.controls.message = "err"
  },

  [SERVICE.POST_CREATE_SEVENT](state) {
    state.controls.loading = true
    state.controls.error = false
    state.controls.modal.event.status = false
    state.controls.message = ""
  },
  [SERVICE.POST_CREATE_SEVENT_SUCCESS](state, action) {
    state.controls.loading = false
    state.controls.error = false
    if (action.payload?.service) {
      state.controls.details = action.payload.service
      state.controls.budgets = action.payload.service.budgets
      state.controls.events = action.payload.service.events
    } else {
      state.controls.events.push(action.payload.event)
    }
    state.controls.modal.event.status = false
    state.controls.message = "accept"
  },
  [SERVICE.POST_CREATE_SEVENT_ERROR](state, action) {
    state.controls.loading = false
    state.controls.error = true
    state.controls.message = "err"
  },

  [SERVICE.PATCH_SBUDGET_ACCEPT](state) {
    state.controls.loading = true
    state.controls.error = false
    state.controls.message = ""
  },
  [SERVICE.PATCH_SBUDGET_ACCEPT_SUCCESS](state, action) {
    state.controls.loading = false
    state.controls.error = false
    state.controls.budgets = state.controls.budgets.map((obj1) =>
      action.payload.budget.id === obj1.id ? action.payload.budget : obj1
    )
    state.controls.modal.budgetAcceptReject.status = false
    state.controls.message = "accept"
  },
  [SERVICE.PATCH_SBUDGET_ACCEPT_ERROR](state, action) {
    state.controls.loading = false
    state.controls.error = true
    state.controls.modal.budgetAcceptReject.status = false
    state.controls.message = "err"
  },
  [SERVICE.PATCH_SBUDGET_REJECT](state) {
    state.controls.loading = true
    state.controls.error = false
    state.controls.message = ""
  },
  [SERVICE.PATCH_SBUDGET_REJECT_SUCCESS](state, action) {
    state.controls.loading = false
    state.controls.error = false
    state.controls.budgets = state.controls.budgets.map((obj1) =>
      action.payload.budget.id === obj1.id ? action.payload.budget : obj1
    )

    state.controls.modal.budgetAcceptReject.status = false
    state.controls.message = "accept"
  },
  [SERVICE.PATCH_SBUDGET_REJECT_ERROR](state, action) {
    state.controls.loading = false
    state.controls.error = true
    state.controls.modal.budgetAcceptReject.status = false
    state.controls.message = "err"
  },

  [SERVICE.GET_SBUDGET](state) {
    state.controls.loading = true
    state.controls.error = false
    state.controls.message = ""
  },
  [SERVICE.GET_SBUDGET_SUCCESS](state, action) {
    state.controls.loading = false
    state.controls.error = false
    state.controls.budgetDetails = action.payload.budget
    state.controls.modal.budgetDetails.status = !state.controls.modal.budgetDetails.status
    state.controls.message = ""
  },
  [SERVICE.GET_SBUDGET_ERROR](state, action) {
    state.controls.loading = false
    state.controls.error = true
    state.controls.message = "err"
  },
  [SERVICE.GET_SEVENT](state) {
    state.controls.loading = true
    state.controls.error = false
    state.controls.message = ""
  },
  [SERVICE.GET_SEVENT_SUCCESS](state, action) {
    state.controls.loading = false
    state.controls.error = false
    state.controls.eventDetails = action.payload.event
    state.controls.modal.eventDetails.status = !state.controls.modal.eventDetails.status
    state.controls.message = ""
  },
  [SERVICE.GET_SEVENT_ERROR](state, action) {
    state.controls.loading = false
    state.controls.error = true
    state.controls.message = "err"
  },

  [SERVICE.TOGGLE_MODAL_SBUDGET](state, action) {
    state.controls.modal.budget.status = !state.controls.modal.budget.status
    state.edit.budget = false
  },
  [SERVICE.TOGGLE_MODAL_SBUDGET_UPDATE](state, action) {
    state.controls.modal.budget.status = !state.controls.modal.budget.status
    state.edit.budget = true
  },
  [SERVICE.TOGGLE_MODAL_SEVENT](state, action) {
    state.controls.modal.event.status = !state.controls.modal.event.status
  },
  [SERVICE.TOGGLE_MODAL_SBUDGET_DETAILS](state, action) {
    state.controls.modal.budgetDetails.status = !state.controls.modal.budgetDetails.status
  },
  [SERVICE.TOGGLE_MODAL_SEVENT_DETAILS](state, action) {
    state.controls.modal.eventDetails.status = !state.controls.modal.eventDetails.status
  },
  [SERVICE.TOGGLE_MODAL_SBUDGET_ACCEPT_REJECT](state, action) {
    state.controls.modal.budgetAcceptReject.status = !state.controls.modal.budgetAcceptReject.status
  },
})

export default service
