import React from "react";
import { CircularProgress, Typography, Button, Grid } from "@material-ui/core";
import CustomField from "commons/components/CustomField";

const UpdatePasswordForm = (props) => {
  const { classes, onChange, onClickUpdatePassword } = props;

  const handleChange = (event) => {
    event.preventDefault();
    onChange(event);
  };

  return (
    <form onSubmit={onClickUpdatePassword}>
      <Grid container spacing={3} justify="center">
        <Grid item xs={10}>
          <Typography variant="body1" component="div">
            Por favor, ingrese su nueva contraseña.
          </Typography>
        </Grid>

        <Grid item xs={10}>
          <CustomField
            className={classes.field}
            label="Nueva contraseña"
            key="password"
            id="password"
            type="password"
            value={props.password}
            onChange={handleChange}
            style={{ paddingBottom: "8px", marginBottom: "5%" }}
            fullWidth
          />
          <CustomField
            className={classes.field}
            label="Repita su nueva contraseña"
            key="verifyPassword"
            id="verifyPassword"
            type="password"
            value={props.verifyPassword}
            onChange={handleChange}
            style={{ paddingBottom: "8px", marginBottom: "5%" }}
            fullWidth
          />
        </Grid>

        <Grid
          item
          xs={10}
          container
          justify="center"
          alignItems="center"
          className={classes.textAlign}
        >
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.button}
          >
            {props.controls.loading ? (
              <CircularProgress className={classes.progress} />
            ) : (
              "Guardar"
            )}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default UpdatePasswordForm;
