// @ts-nocheck
import React from "react"

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core"

import CustomSwitch from "commons/components/CustomSwitch"
import CustomAutoComplete from "commons/components/CustomAutoComplete"
import ButtonWithLoader from "commons/containers/ButtonWithLoader"
import DatePicker from "commons/components/DatePicker"
import CustomField from "commons/components/CustomField"
import MultifileField from "commons/components/MultifileField"

const FormModalBudget = (props) => {
  const { classes, style, onChange, onChangeSwitch, submit, data, eventTypes, errors } = props

  const handleChange = (event) => {
    onChange(event)
  }

  const handleChangeAutoComp = (event, newValue) => {
    onChange(event, newValue)
  }

  return (
    <Dialog
      fullWidth
      onClose={props.toggleForm}
      aria-labelledby="simple-dialog-title"
      open={props.isOpen}
    >
      <DialogTitle>Ingresar evento</DialogTitle>
      <DialogContent className={style}>
        <form onSubmit={submit}>
          <Grid container spacing={1} justify="center">
            <Grid item xs={10}>
              <DatePicker
                className={classes.field}
                variant="outlined"
                onChange={handleChange}
                id={"date"}
                label={"Fecha del evento"}
                value={data.date}
                error={!!errors.date}
                helperText={errors.date}
                fullWidth={true}
              />
            </Grid>

            <Grid item xs={10}>
              <CustomAutoComplete
                className={classes.field}
                id="eventTypeId"
                label="Tipo de evento"
                value={data.eventTypeId}
                onChange={handleChangeAutoComp}
                defaultValue={null}
                options={eventTypes}
                // options={[
                //   "Recepción",
                //   "Pausa por cambio de prioridades",
                //   "Manejo de repuestos e importaciones",
                //   "Rechazado en control de calidad",
                //   "Entrega",
                // ]}
                error={!!errors.eventTypeId}
                helperText={errors.eventTypeId}
              />
            </Grid>

            <Grid item xs={10}>
              <CustomField
                variant="outlined"
                className={classes.field}
                label="Descripción"
                key="description"
                id="description"
                multiline
                rows={5}
                rowsMax={5}
                value={data.description}
                error={!!errors.description}
                helperText={errors.description}
                onChange={handleChange}
                fullWidth
              />
            </Grid>

            <Grid item xs={10}>
              <MultifileField
                variant="outlined"
                className={classes.field}
                label="Archivos"
                name="files"
                key="files"
                id="files"
                value={data.files}
                error={!!errors.files}
                helperText={errors.files}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.toggleForm} color="primary">
          Cancelar
        </Button>
        <ButtonWithLoader
          onClick={props.submit}
          autoFocus
          buttonId={props.buttonId}
          loading={props.loading}
        >
          Confirmar
        </ButtonWithLoader>
      </DialogActions>
    </Dialog>
  )
}

export default FormModalBudget
