import React from "react"
import { Typography } from "@material-ui/core"

const Parrafo = ({ style, className, title, value, type }) => {
  return (
    <>
      <Typography
        style={style}
        className={className.boxLineaCard}
        variant="body2"
        component="div"
      >
        {type === "inline" ? (
          <div className="parrafo-inline">
            <p>
              <b>{title}</b>: {value}
            </p>
          </div>
        ) : (
          <div className={"parrafo-block"}>
            <p>
              <b>{title}</b>
            </p>
            <p className={className.p}>{value}</p>
          </div>
        )}
      </Typography>
    </>
  )
}

Parrafo.defaultProps = {
  type: "block",
  className: { boxLineaCard: "", p: "" },
  style: {},
}

export default Parrafo
