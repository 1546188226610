import React, { useEffect } from "react"
import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from "@material-ui/lab/Alert"
import { makeStyles } from "@material-ui/core/styles"

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}))

export default function AlertSnackbar({
  pop = false,
  severity = "info",
  duration = 4000,
  message = "",
  onClose,
}) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  const handleClick = () => {
    setOpen(true)
  }

  useEffect(() => {
    setOpen(pop)
  }, [pop])

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    if (onClose) {
      onClose()
    } else {
      setOpen(false)
    }
  }

  return (
    <div className={classes.root}>
      <Snackbar open={open} autoHideDuration={duration} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  )
}
