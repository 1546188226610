import { takeLatest, spawn, put } from "redux-saga/effects"
import { MAINTAINERUSER } from "../reducer"
import { apiSuccess, destroy, get, post, update } from "utils/api"
import { APP } from "commons/reducer"

function* getStateFromApiUsers() {
  yield takeLatest(MAINTAINERUSER.GET_STATE_FROM_API_USERS, function* (action) {
    const response = yield get("api/v1/users")
    yield put(apiSuccess(MAINTAINERUSER.GET_STATE_FROM_API_USERS_SUCCESS, response))
  })
}

function* getStateFromApiRoles() {
  yield takeLatest(MAINTAINERUSER.GET_STATE_FROM_API_ROLES, function* (action) {
    const response = yield get("api/v1/roles")
    yield put(apiSuccess(MAINTAINERUSER.GET_STATE_FROM_API_ROLES_SUCCESS, response))
  })
}

function* addUser() {
  yield takeLatest(MAINTAINERUSER.POST_ADD_USER, function* (action) {
    const body = action.controls
    const response = yield post("api/v1/users", body)
    if (!response.error) {
      yield put(apiSuccess(MAINTAINERUSER.POST_ADD_USER_SUCCESS, response))
      yield put(apiSuccess(APP.SET_SUCCESS, response))
    } else {
      yield put(apiSuccess(MAINTAINERUSER.POST_ADD_USER_ERROR, response))
      yield put(apiSuccess(APP.SET_ERROR, response))
    }
  })
}

function* updateUser() {
  yield takeLatest(MAINTAINERUSER.POST_UPDATE_USER, function* (action) {
    const body = JSON.stringify(action.controls)
    const response = yield update(`api/v1/users/${action.id}`, body)
    if (!response.error) {
      yield put(apiSuccess(MAINTAINERUSER.POST_UPDATE_USER_SUCCESS, response))
      yield put(apiSuccess(APP.SET_SUCCESS, response))
    } else {
      yield put(apiSuccess(MAINTAINERUSER.POST_UPDATE_USER_ERROR, response))
      yield put(apiSuccess(APP.SET_ERROR, response))
    }
  })
}

function* deleteUser() {
  yield takeLatest(MAINTAINERUSER.DELETE_USER, function* (action) {
    const response = yield destroy(`api/v1/users/${action.id}`)
    if (!response.error) {
      yield put(apiSuccess(MAINTAINERUSER.DELETE_USER_SUCCESS, { item: response, type: "users" }))
      yield put(apiSuccess(APP.SET_SUCCESS, response))
    } else {
      yield put(apiSuccess(MAINTAINERUSER.DELETE_USER_ERROR, response))
      yield put(apiSuccess(APP.SET_ERROR, response))
    }
  })
}

function* enableMailer() {
  yield takeLatest(MAINTAINERUSER.PATCH_ENABLE_MAILER, function* (action) {
    const body = JSON.stringify(action.controls)
    const response = yield update(`api/v1/users/${action.id}/enable-or-disable-mailer`, body)
    if (!response.error) {
      yield put(apiSuccess(MAINTAINERUSER.PATCH_ENABLE_MAILER_SUCCESS, response))
      yield put(apiSuccess(APP.SET_SUCCESS, response))
    } else {
      yield put(apiSuccess(MAINTAINERUSER.PATCH_ENABLE_MAILER_ERROR, response))
      yield put(apiSuccess(APP.SET_ERROR, response))
    }
  })
}

export default function* root() {
  yield spawn(getStateFromApiUsers)
  yield spawn(getStateFromApiRoles)
  yield spawn(addUser)
  yield spawn(updateUser)
  yield spawn(deleteUser)
  yield spawn(enableMailer)
}
