import { takeLatest, spawn, put } from "redux-saga/effects"
import { RECOVERYPASSWORD } from "../reducer"
import apiRequest, { apiSuccess } from "utils/api"

function* verifyEmail() {
  yield takeLatest(RECOVERYPASSWORD.POST_VERIFY_EMAIL, function* (action) {
    const body = JSON.stringify(action.controls)
    const response = yield apiRequest("api/v1/auth/recoveryPassword", {
      method: "post",
      body,
    })
    if (!response.error) {
      yield put(
        apiSuccess(RECOVERYPASSWORD.POST_VERIFY_EMAIL_SUCCESS, response)
      )
      // localStorage.setItem('user', JSON.stringify({ token: response.token }));
    } else {
      yield put(
        apiSuccess(RECOVERYPASSWORD.POST_VERIFY_EMAIL_SUCCESS, response)
      )
    }
  })
}

function* updatePassword() {
  yield takeLatest(RECOVERYPASSWORD.POST_UPDATE_PASSWORD, function* (action) {
    const body = JSON.stringify(action.controls)
    const response = yield apiRequest("api/v1/auth/createPassword", {
      method: "post",
      body,
    })
    if (!response.error) {
      yield put(
        apiSuccess(RECOVERYPASSWORD.POST_UPDATE_PASSWORD_SUCCESS, response)
      )
      // localStorage.setItem('user', JSON.stringify({ token: response.token }));
    } else {
      yield put(
        apiSuccess(RECOVERYPASSWORD.POST_UPDATE_PASSWORD_SUCCESS, response)
      )
    }
  })
}

export default function* root() {
  yield spawn(verifyEmail)
  yield spawn(updatePassword)
}
