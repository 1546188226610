import { Button, TableBody, TableCell, TextField } from "@material-ui/core"
import CustomNumberField from "commons/components/CustomNumberField"
import React, { useState } from "react"

const WorkshopTableItem = (props) => {
  const { workshopLabor, updateBudgetLabor, removeBudgetLabor } = props
  console.log({workshopLabor})
  const workshopLaborPrice = workshopLabor.laborCostByVehicleType[0].price
  // const workshopLaborPrice = workshopLabor.price
  const [quantityValue, setQuantityValue] = useState(
    workshopLabor.quantity ? workshopLabor.quantity : 1
  )

  const handleQuantityChange = (event) => {
    setQuantityValue(event.target.value)
    const infoToUpdate = {
      id: workshopLabor.id,
      quantity: Number(event.target.value),
      total: event.target.value * workshopLaborPrice,
    }
    updateBudgetLabor(infoToUpdate)
  }

  return (
    <TableBody>
      <TableCell>{workshopLabor.code}</TableCell>
      <TableCell>{workshopLabor.description}</TableCell>
      <TableCell>
        <CustomNumberField
          variant={"outlined"}
          key={"quantity_value"}
          id={"quantity_value"}
          name={"quantity_value"}
          type={"text"}
          value={quantityValue}
          onHandleChange={handleQuantityChange}
        />
      </TableCell>
      <TableCell>$ {new Intl.NumberFormat("en-DE").format(workshopLaborPrice)}</TableCell>
      <TableCell>
        $ {new Intl.NumberFormat("en-DE").format(workshopLaborPrice * workshopLabor.quantity)}
      </TableCell>
      <TableCell>
        <Button
          variant="outlined"
          style={{ color: "red", borderColor: "red" }}
          onClick={() => removeBudgetLabor(workshopLabor.id)}
        >
          Eliminar
        </Button>
      </TableCell>
    </TableBody>
  )
}

export default WorkshopTableItem
