import React from "react"
import { Fab } from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"
import AuthUserContext from "commons/context/user"

const MenuButton = (props) => {
  const { className, handleToggle } = props
  const authUser = React.useContext(AuthUserContext)

  const options = [
    {
      roles: ["Administrator", "Scheduler"],
    },
  ]

  const filterRole = options.filter((option) => option.roles.includes(authUser?.rol?.name))

  if (!filterRole.length) return <></>
  return (
    <>
      <Fab color="primary" aria-label="add" className={className} onClick={handleToggle}>
        <AddIcon />
      </Fab>
    </>
  )
}

export default MenuButton
