// @ts-nocheck
import React from "react"

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core"

import Parrafo from "commons/components/Parrafo"
import MultifileField from "commons/components/MultifileField"
import CustomField from "commons/components/CustomField"
import ButtonWithLoader from "commons/containers/ButtonWithLoader"
import RoomIcon from "@material-ui/icons/Room"

const FormModalAcceptReject = (props) => {
  const { className, onChange, isModalAccept, submit, data, errors } = props

  const handleChange = (event) => {
    onChange(event)
  }

  const renderTitle = () => {
    if (isModalAccept) {
      return (
        <>
          <RoomIcon />
          <Parrafo title={"Aprobar presupuesto"} value={""} />
        </>
      )
    }
    return (
      <>
        <RoomIcon />
        <Parrafo title={"Rechazar presupuesto"} value={""} />
      </>
    )
  }

  const renderForm = () => {
    if (isModalAccept) {
      return (
        <>
          <Grid item xs={10}>
            <CustomField
              variant="outlined"
              className={className.field}
              label="Ingrese aqui su observación"
              key="budgetObservation"
              id="budgetObservation"
              multiline
              rows={5}
              rowsMax={5}
              value={data.budgetObservation}
              error={!!errors.budgetObservation}
              helperText={errors.budgetObservation}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={10}>
            <MultifileField
              variant="outlined"
              className={className.field}
              label="Archivos"
              name="budgetFiles"
              key="budgetFiles"
              id="budgetFiles"
              value={data.budgetFiles}
              error={!!errors.budgetFiles}
              helperText={errors.budgetFiles}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
        </>
      )
    }
    return (
      <>
        <Grid item xs={10}>
          <CustomField
            variant="outlined"
            className={className.field}
            label="Detalles"
            key="budgetObservation"
            id="budgetObservation"
            multiline
            rows={5}
            rowsMax={5}
            value={data.budgetObservation}
            error={!!errors.budgetObservation}
            helperText={errors.budgetObservation}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
      </>
    )
  }

  const renderLabelAction = () => {
    if (isModalAccept) {
      return "Aprobar"
    }
    return "Rechazar"
  }

  return (
    <Dialog
      fullWidth
      onClose={props.toggleForm}
      aria-labelledby="accept-or-reject-budget"
      open={props.isOpen}
    >
      <DialogTitle style={{ textAlign: "center", color: "red" }}>
        <div className="DialogTitle" style={{ textAlign: "center", color: "red" }}>
          {renderTitle()}
        </div>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={submit}>
          <Grid container spacing={1} justify="center">
            <Grid container spacing={1} justify="center">
              <Parrafo title={""} value={"¿Tiene alguna observación sobre el presupuesto?"} />
              {renderForm()}
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.toggleForm} color="primary">
          Cancelar
        </Button>
        <ButtonWithLoader
          color={isModalAccept ? "" : "secondary"}
          onClick={props.submit}
          autoFocus
          buttonId={props.buttonId}
          loading={props.loading}
        >
          {renderLabelAction()}
        </ButtonWithLoader>
      </DialogActions>
    </Dialog>
  )
}

export default FormModalAcceptReject
