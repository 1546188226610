/* eslint-disable func-names */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/named */
import { takeLatest, spawn, put } from 'redux-saga/effects';
import apiRequest, { get, post, postWithImage, update, destroy, updateWithImage, apiSuccess } from 'utils/api';

import controlledCall from 'utils/services/controlledSaga';

import { falsy, truthty } from 'utils/functions';

//Reducer
import { CONTRACT_DOCUMENTS, contractDocumentsActions } from '../reducer';

function* getStateFromApi() {

}

export default function* root() {
  yield spawn(getStateFromApi);
}