import commonStyles from 'utils/commonStyles'
const styles = (theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  title: {
    marginBottom: "25px",
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(10),
    right: theme.spacing(10),
  },
  modal: {
    padding: "16px",
  },
  image: {
    width: "100%",
    minHeight: "170px",
    maxWidth: "280px",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "25%",
    objectFit: "contain",
    backgroundSize: "contain",
  },
  icon: {
    width: "14px",
    height: "14px",
    display: "inline-block",
    marginRight: "5px",
  },
  p: {
    display: "inline-block",
    textTransform: "lowercase",
    "&::first-letter": {
      textTransform: "uppercase",
    },
  },
  containerFilter: {
    marginBottom: "25px",
  },
  leyendIconContainer: { position: "relative" },
  leyendIcon: {
    position: "absolute",
    top: "-32px",
    right: "0",
    left: "0",
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
    width: "50%",
  },
  leyendIconSpan: {
    padding: "0 10px",
    display: "inline-block",
  },
  boxLineaCard: {
    background: "#FFFFFF",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    padding: "0 10px",
    marginTop: "25px",
    marginBottom: "25px",
  },
  button: {
    paddingTop: "12px",
    paddingBottom: "12px",
    margin: "8px",
  },
  field: {
    paddingBottom: "10px",
    width: "100%",
  },
  ...commonStyles,
})

export default styles
