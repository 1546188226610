import React from "react";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 40,
    height: 18,
    padding: 2,
    display: "flex",
    justifyContent: "center",
    overflow: "inherit",
  },
  switchBase: {
    justifyContent: "center",
    padding: 0,
    color: theme.palette.primary.main,
    "&$checked": {
      transform: "translateX(22px)",
      color: theme.palette.primary.main,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.grey[300],
      },
    },
  },
  thumb: {
    width: 20,
    height: 20,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.grey[500],
  },
  checked: {},
}))(Switch);

const CustomSwitch = ({ name, handleChange, checkedC }) => {
  const changeControls = (event) => {
    handleChange(event, event.target.checked);
  };

  return (
    <>
      <Typography component="div">
        <FormLabel component="legend">Tipo de mantención</FormLabel>
        <Grid
          component="label"
          container
          justify="center"
          alignItems="center"
          style={{ paddingTop: 10 }}
        >
          <Grid item>Preventiva</Grid>
          <Grid item style={{ margin: "4px 12px" }}>
            <AntSwitch
              checked={checkedC}
              onChange={changeControls}
              name={name}
            />
          </Grid>
          <Grid item>Correctiva</Grid>
        </Grid>
      </Typography>
    </>
  );
};

export default CustomSwitch;
