import { Grid } from "@material-ui/core"
import React from "react"

const BudgetSignatures = (props) => {
  const { className } = props
  return (
    <Grid container md={12} lg={12} xs={12} className={className.containerTopMargin}>
      <Grid
        container
        md={12}
        lg={12}
        xs={12}
        justifyContent="center"
        className={className.signatureStyle}
      >
        {" "}
        Aceptación Cotización
      </Grid>
      <Grid
        container
        md={6}
        lg={6}
        xs={6}
        justifyContent="center"
        className={className.signatureStyle}
      >
        Administrador Institucional Contrato _________________________________
      </Grid>
      <Grid
        container
        md={6}
        lg={6}
        xs={6}
        justifyContent="center"
        className={className.signatureStyle}
      >
        Fecha de Aprobación _______________________________
      </Grid>
      <Grid
        container
        md={6}
        lg={6}
        xs={6}
        justifyContent="center"
        className={className.signatureStyle}
      >
        Jefe de Dpto. Apoyo a las Operaciones _________________________________
      </Grid>
      <Grid
        container
        md={6}
        lg={6}
        xs={6}
        justifyContent="center"
        className={className.signatureStyle}
      >
        Fecha de Aprobación _______________________________
      </Grid>
    </Grid>
  )
}

export default BudgetSignatures
