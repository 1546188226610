import camelize from "camelcase-keys-deep"
export default class Vehicle {
  constructor(data) {
    const props = camelize(data)
    Object.assign(this, props)
  }

  entity() {
    let response = null
    if (this.entityId) {
      response = this.env().Entity.findBy("id", this.entityId)
    }
    return response
  }
}
