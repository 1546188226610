import React, { useEffect } from "react"
import saga from "./saga"
import { appActions } from "commons/reducer"
import { useHistory } from "react-router"
import { contractActions } from "./reducer"
import styles from "./styles"

import { composedComponent, formatInteger, getItemInStorage, setInStorage } from "utils/functions"
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Divider,
  Grid,
  Typography,
  withStyles,
} from "@material-ui/core"
import DoughnutChart from "commons/components/DoughnutChart"
import { Skeleton } from "@material-ui/lab"

const Contracts = (props) => {
  const { actions, contracts, classes, loading } = props

  const history = useHistory()

  const contractValues = Object.values(contracts)

  useEffect(() => {
    actions.getContracts()
  }, [])

  useEffect(() => {
    if (Object.keys(contracts).length === 0) return
    if (getItemInStorage("contract")) return

    setInStorage("contract", contracts[Object.keys(contracts)[0]])
  }, [contracts])

  const handleClick = (contract) => {
    actions.setContract(contract)
    setInStorage("contract", contract)
    history.push("/home")
  }
  return (
    <Grid container style={{ marginBottom: "16px" }} spacing={2} justifyContent="space-between">
      <Grid item style={{ marginTop: "32px" }} xs={12}>
        <Typography variant="h4">Resumen servicios contratados</Typography>
      </Grid>
      {contractValues.map((contract, index) => (
        <Grid item xs={4} key={contract.id}>
          <Card className={classes.root}>
            <CardContent>
              <Grid spacing={1} container className={classes.contractTitleContainer}>
                <Grid item>
                  <Avatar
                    alt={`Logo ${contract.name}`}
                    src={contract.logo || ""}
                    className={classes.large}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="h6" component="h3">
                    {contract.name}
                  </Typography>
                  {!loading && (
                    <Typography>Presupuesto: ${formatInteger(contract.budget)}</Typography>
                  )}
                </Grid>
              </Grid>
              {loading ? (
                <Skeleton variant="rect" height={"350px"} />
              ) : (
                <>
                  <DoughnutChart
                    index={index}
                    invoiced={contract.invoiced}
                    processed={contract.processed}
                    contractBudget={contract.budget}
                  />
                  <Typography variant="h6" component="h3">
                    Resumen
                  </Typography>
                  <Grid className={classes.summaryTextContainer} container>
                    <Grid item xs>
                      Equipos ingresados en taller
                    </Grid>
                    <Typography component="div">
                      <Box textAlign="right" fontWeight="fontWeightBold">
                        {contract.inWorkshop}
                      </Box>
                    </Typography>
                  </Grid>
                  <Divider />
                  <Grid className={classes.summaryTextContainer} container>
                    <Grid item xs>
                      Equipos con cotizaciones autorizadas
                    </Grid>
                    <Typography component="div">
                      <Box textAlign="right" fontWeight="fontWeightBold">
                        {contract.waiting}
                      </Box>
                    </Typography>
                  </Grid>
                  <Divider />
                  <Grid className={classes.summaryTextContainer} container>
                    <Grid item xs>
                      Equipos entregados en espera de facturación
                    </Grid>
                    <Typography component="div">
                      <Box textAlign="right" fontWeight="fontWeightBold">
                        {contract.waitingInvoice}
                      </Box>
                    </Typography>
                  </Grid>
                </>
              )}
              <Button
                className={classes.button}
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => handleClick(contract)}
              >
                Ver más
              </Button>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  )
}

export default composedComponent(Contracts, saga, {
  saga: "sagaContracts",
  middlewares: [withStyles(styles)],
  states: ["contracts.contracts", "contracts.loading", "app.objects.contracts"],
  actions: [contractActions, appActions],
})
