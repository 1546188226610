import camelize from "camelcase-keys-deep"
export default class User {
  id = 0
  roleId = 0

  constructor(data) {
    const props = camelize(data)
    Object.assign(this, props)
  }

  entity() {
    let response = null
    if (this.entityId) {
      response = this.env().Entity.findBy("id", this.entityId)
    }
    return response
  }

  get role() {
    let response = null
    if (this.roleId) {
      response = this.env().Role.findBy("id", this.roleId)
    }
    return response
  }
}
