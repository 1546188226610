import React from "react"
import { makeStyles, Badge } from "@material-ui/core"
import classNames from "classnames"

const useStyles = makeStyles({
  badge: (props) => ({
    top: "8px",
    left: "-11px",
    display: "inline-block",
    position: "inherit",
    minWidth: "13px",
    height: "13px",
    padding: "0 4px",
    background: props.badge.backColor,
    border: `2px solid ${props.badge.borderColor}`,
    borderRadius: "10px",
    marginRight: "5px",
  }),
})

const CirculeBadge = ({ badgeContent, type, styleCircule }) => {
  const getTypeBadge = (type, style) => {
    switch (type) {
      case "success":
        return {
          badge: {
            backColor: style === "under" ? "white" : "green",
            borderColor: "green",
          },
        }
        break
      case "wait":
        return {
          badge: {
            backColor: style === "under" ? "white" : "gray",
            borderColor: "gray",
          },
        }
        break
      case "reject":
        return {
          badge: {
            backColor: style === "under" ? "white" : "red",
            borderColor: "red",
          },
        }
        break
      case "hold":
        return {
          badge: {
            backColor: style === "under" ? "white" : "#F2C94C",
            borderColor: "#F2C94C",
          },
        }
        break
      default:
        return {
          badge: {
            backColor: style === "under" ? "white" : "#F2C94C",
            borderColor: "#F2C94C",
          },
        }
    }
  }

  const propsClass = useStyles(getTypeBadge(type, styleCircule))

  return (
    <>
      <Badge
        overlap="circle"
        badgeContent={badgeContent}
        className={classNames(propsClass.badge)}
      ></Badge>
    </>
  )
}

CirculeBadge.defaultProps = {
  styleCircule: "",
  badgeContent: "",
}

export default CirculeBadge
