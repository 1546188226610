import React from "react";
import { CardMedia, Typography } from "@material-ui/core";

const TableLeyend = ({ className, MP_36, MC_36 }) => {
  return (
    <>
      <Typography className={className.leyendIconContainer} component="div">
        <div className={`flex-section-leyend ${className.leyendIcon}`}>
          Tipo de mantención:
          <Typography className={className.leyendIconSpan} component="div">
            <CardMedia
              className={className.icon}
              image={MP_36}
              title="Preventiva"
            />
            Preventiva
          </Typography>
          <Typography className={className.leyendIconSpan} component="div">
            <CardMedia
              className={className.icon}
              image={MC_36}
              title="Correctiva"
            />
            Correctiva
          </Typography>
        </div>
      </Typography>
    </>
  );
};

export default TableLeyend;
