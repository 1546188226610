// @ts-nocheck
import React, { useState, useEffect } from "react"
// material
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import Checkbox from "@material-ui/core/Checkbox"
import Grid from "@material-ui/core/Grid"
// utils
import { truthty } from "utils/functions"

const CustomAutoComplete = (props) => {
  const [value, setValue] = useState(props.defaultValue)
  const [inputValue, setInputValue] = useState("")

  useEffect(() => {
    if (truthty(props.value) && !!props.value.trim()) {
      setValue(props.value)
    }
  }, [props.value])

  useEffect(() => {
    const close = document.getElementsByClassName(`${props.id}`)
    if (!!close.length) {
      close[0]
        .querySelector(`.MuiAutocomplete-clearIndicator`)
        .addEventListener("click", (event) => {
          props.onChange({
            persist: () => {},
            target: { value: "", id: props.id, name: props.id, type: "autoComplete" },
          })
        })
    }
  }, [])

  function changeControls(event, newValue) {
    setValue(newValue)
    props.onChange(event, newValue)
  }

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue)
  }

  return (
    <>
      {/* <div>{`value: ${value !== null ? `'${value}'` : "null"}`}</div>
      <div>{`inputValue: '${inputValue}'`}</div> */}
      <Autocomplete
        className={`${props.className} ${props.id}`}
        // disableScrollLock={true}
        id={props.id}
        options={props.options}
        value={value}
        onChange={changeControls}
        onInputChange={handleInputChange}
        // inputValue={inputValue}
        // style={{ width: "100%" }}
        // getOptionLabel={(option) => option.name}
        // getOptionSelected={(option, value) => option.name === value}
        renderInput={(params) => (
          <TextField
            {...params}
            key={`auto-${props.label}`}
            label={props.label}
            variant="outlined"
            error={props.error}
            helperText={props.helperText}
            fullWidth
          />
        )}
      />
    </>
  )
}

export default CustomAutoComplete
