import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core"
import React from "react"
import LaborsTableItem from "../LaborsTableItem"

const LaborsTable = (props) => {
  const { laborsSelected, removeBudgetLabor } = props
  const columns = [
    { id: "code", label: "CÓDIGO", align: "left" },
    { id: "description", label: "DESCRIPCIÓN", align: "center" },
    { id: "timecost", label: "TEMPARIO", align: "center" },
    { id: "cost", label: "VALOR", align: "center" },
    { id: "actions", label: "ACCIÓN", align: "center" },
  ]

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead style={{ backgroundColor: "#185517" }}>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.id} align="left" style={{ color: "white", fontSize: "15px" }}>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {laborsSelected.map((laborSelected, idx) => (
          <LaborsTableItem key={`${laborSelected.id}-${idx}`} laborSelected={laborSelected} removeBudgetLabor={removeBudgetLabor} />
        ))}
      </Table>
      {laborsSelected.length === 0 && (
        <Typography
          component="div"
          align="center"
          style={{ marginBottom: "7px", marginTop: "7px" }}
        >
          No se han añadido cotizaciones
        </Typography>
      )}
    </TableContainer>
  )
}

export default LaborsTable
