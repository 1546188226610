import { cleanValuesObject } from "utils/functions"
import { createAction, createReducer } from "utils/reducer"

// Local constants
const PATH = "home/"

// Global constants for saga
export const HOME = {
  GET_SERVICES: `${PATH}GET_SERVICES`,
  GET_SERVICES_SUCCESS: `${PATH}GET_SERVICES_SUCCESS`,
  GET_SERVICES_ERROR: `${PATH}GET_SERVICES_ERROR`,
  POST_CREATE_SERVICE: `${PATH}POST_CREATE_SERVICE`,
  POST_CREATE_SERVICE_SUCCESS: `${PATH}POST_CREATE_SERVICE_SUCCESS`,
  POST_CREATE_SERVICE_ERROR: `${PATH}POST_CREATE_SERVICE_ERROR`,
  PATCH_UPDATE_SERVICE: `${PATH}PATCH_UPDATE_SERVICE`,
  PATCH_UPDATE_SERVICE_SUCCESS: `${PATH}PATCH_UPDATE_SERVICE_SUCCESS`,
  PATCH_UPDATE_SERVICE_ERROR: `${PATH}PATCH_UPDATE_SERVICE_ERROR`,

  PATCH_ACCEPT_SERVICE: `${PATH}PATCH_ACCEPT_SERVICE`,
  PATCH_ACCEPT_SERVICE_SUCCESS: `${PATH}PATCH_ACCEPT_SERVICE_SUCCESS`,
  PATCH_ACCEPT_SERVICE_ERROR: `${PATH}PATCH_ACCEPT_SERVICE_ERROR`,
  PATCH_REJECT_SERVICE: `${PATH}PATCH_REJECT_SERVICE`,
  PATCH_REJECT_SERVICE_SUCCESS: `${PATH}PATCH_REJECT_SERVICE_SUCCESS`,
  PATCH_REJECT_SERVICE_ERROR: `${PATH}PATCH_REJECT_SERVICE_ERROR`,

  TOGGLE_MODAL_SERVICES: `${PATH}TOGGLE_MODAL_SERVICES`,
  TOGGLE_UPDATE_MODAL_SERVICES: `${PATH}TOGGLE_UPDATE_MODAL_SERVICES`,
  TOGGLE_MODAL_SERVICE_REJECT: `${PATH}TOGGLE_MODAL_SERVICE_REJECT`,
  TOGGLE_MODAL_SERVICE_ACCEPT: `${PATH}TOGGLE_MODAL_SERVICE_ACCEPT`,

  GET_SERVICE_WORKSHOP: `${PATH}GET_SERVICE_WORKSHOP`,
  GET_SERVICE_WORKSHOP_SUCCESS: `${PATH}GET_SERVICE_WORKSHOP_SUCCESS`,
  GET_SERVICE_WORKSHOP_ERROR: `${PATH}GET_SERVICE_WORKSHOP_ERROR`,
  GET_SERVICE_VEHICLE: `${PATH}GET_SERVICE_VEHICLE`,
  GET_SERVICE_VEHICLE_SUCCESS: `${PATH}GET_SERVICE_VEHICLE_SUCCESS`,
  GET_SERVICE_VEHICLE_ERROR: `${PATH}GET_SERVICE_VEHICLE_ERROR`,
  GET_SERVICE_MAINTENENCE: `${PATH}GET_SERVICE_MAINTENENCE`,
  GET_SERVICE_MAINTENENCE_SUCCESS: `${PATH}GET_SERVICE_MAINTENENCE_SUCCESS`,
  GET_SERVICE_MAINTENENCE_ERROR: `${PATH}GET_SERVICE_MAINTENENCE_ERROR`,

  GET_SERVICE_FILTERS: `${PATH}GET_SERVICE_FILTERS`,
  GET_SERVICE_FILTERS_SUCCESS: `${PATH}GET_SERVICE_FILTERS_SUCCESS`,
  GET_SERVICE_FILTERS_ERROR: `${PATH}GET_SERVICE_FILTERS_ERROR`,

  PATCH_SBUDGET_ACCEPT: `${PATH}PATCH_SBUDGET_ACCEPT`,
  PATCH_SBUDGET_ACCEPT_SUCCESS: `${PATH}PATCH_SBUDGET_ACCEPT_SUCCESS`,
  PATCH_SBUDGET_ACCEPT_ERROR: `${PATH}PATCH_SBUDGET_ACCEPT_ERROR`,
  PATCH_SBUDGET_REJECT: `${PATH}PATCH_SBUDGET_REJECT`,
  PATCH_SBUDGET_REJECT_SUCCESS: `${PATH}PATCH_SBUDGET_REJECT_SUCCESS`,
  PATCH_SBUDGET_REJECT_ERROR: `${PATH}PATCH_SBUDGET_REJECT_ERROR`,
  TOGGLE_MODAL_SBUDGET_ACCEPT_REJECT: `${PATH}TOGGLE_MODAL_SBUDGET_ACCEPT_REJECT`,

  GET_SERVICE_EXPORT: `${PATH}GET_SERVICE_EXPORT`,
  GET_SERVICE_EXPORT_SUCCESS: `${PATH}GET_SERVICE_EXPORT_SUCCESS`,
  GET_SERVICE_EXPORT_ERROR: `${PATH}GET_SERVICE_EXPORT_ERROR`,
}

// actions
export const homeActions = {
  getServices: createAction(HOME.GET_SERVICES, "controls"),
  createService: createAction(HOME.POST_CREATE_SERVICE, "controls"),
  updateService: createAction(HOME.PATCH_UPDATE_SERVICE, "controls"),
  approvatedService: createAction(HOME.PATCH_ACCEPT_SERVICE, "controls"),
  rejectService: createAction(HOME.PATCH_REJECT_SERVICE, "controls"),
  toggleModal: createAction(HOME.TOGGLE_MODAL_SERVICES),
  toggleUpdateModal: createAction(HOME.TOGGLE_UPDATE_MODAL_SERVICES),
  toggleRejectModal: createAction(HOME.TOGGLE_MODAL_SERVICE_REJECT),
  toggleApprovatedModal: createAction(HOME.TOGGLE_MODAL_SERVICE_ACCEPT),
  updateBudgetAccept: createAction(HOME.PATCH_SBUDGET_ACCEPT, "controls"),
  updateBudgetReject: createAction(HOME.PATCH_SBUDGET_REJECT, "controls"),
  toggleSbudgetAcceptRejectModal: createAction(HOME.TOGGLE_MODAL_SBUDGET_ACCEPT_REJECT),
  getServiceWorkShop: createAction(HOME.GET_SERVICE_WORKSHOP, "controls"),
  getServiceVehicle: createAction(HOME.GET_SERVICE_VEHICLE, "controls"),
  getServiceMaintenance: createAction(HOME.GET_SERVICE_MAINTENENCE, "controls"),
  getServiceFilters: createAction(HOME.GET_SERVICE_FILTERS, "controls"),

  downloadServicesExport: createAction(HOME.GET_SERVICE_EXPORT, "controls"),
}

// createService;
const initialState = {
  controls: {
    loading: false,
    message: "",
    error: false,
    not_executed_budget_num: 30900120,
    executed_budget_num: 0,
    executed_budget_porcentual: 0,
    leftover_executed_budget_num: 0,
    services: [],
    filters: {
      startDate: "",
      endDate: "",
      workShop: "",
      plateNumberVehicle: "",
      statusService: "",
      statusBudget: "",
      maintenanceType: "",
      dateService: "",
      canClear: false,
    },
    fields: {
      filters: {
        workshops: [],
        plateNumberVehicles: [],
        statusServices: [],
        statusBudgets: [],
        statusInvoices: [],
        maintenanceTypes: [],
      },
      vehicles: [],
      workshops: [],
      maintenances: [],
    },
    form: {
      // Create-Service
      servicesDate: "",
      workshopName: "",
      vehicleName: "",
      mileage: "",
      description: "",
      doe: "",
      maintenanceType: false,
      preventiveMaintenanceName: "",
      serviceType: "mantención",
      // Accept-Reject-Service
      serviceId: "",
      approvedDate: "",
      rejectedObservation: "",
      // Accept-Reject-Budget
      budgetId: "",
      budgetObservation: "",
      budgetFiles: [],
      typeModal: "",
    },
    modal: {
      status: false,
      reject: { status: false },
      approvated: { status: false },
      budgetAcceptReject: { status: false },
    },
  },
  edit: {
    service: false,
  },
}

const home = createReducer(initialState, {
  [HOME.GET_SERVICES](state) {
    state.controls.loading = true
    state.controls.error = false
  },
  [HOME.GET_SERVICES_SUCCESS](state, action) {
    state.controls.loading = false
    state.controls.error = false
    state.controls.services = action.payload.services
    state.controls.executed_budget_num = action.payload.executed_budget_num
    state.controls.executed_budget_porcentual = action.payload.executed_budget_porcentual
    state.controls.not_executed_budget_num = action.payload.not_executed_budget_num
    state.controls.leftover_executed_budget_num = action.payload.leftover_executed_budget_num
    state.controls.canClear = true
  },
  [HOME.GET_SERVICES_ERROR](state, action) {
    state.controls.loading = false
    state.controls.error = true
    state.controls.canClear = true
  },
  [HOME.POST_CREATE_SERVICE](state, action) {
    state.controls.loading = true
    state.controls.error = false
    state.controls.form = action.controls
  },
  [HOME.POST_CREATE_SERVICE_SUCCESS](state, action) {
    state.controls.loading = false
    state.controls.error = false
    state.controls.services.push(action.payload.service)
    state.controls.form = cleanValuesObject(state.controls.form)
    state.controls.message = action.payload.message
  },
  [HOME.POST_CREATE_SERVICE_ERROR](state, action) {
    state.controls.loading = false
    state.controls.error = true
  },
  [HOME.PATCH_UPDATE_SERVICE](state, action) {
    state.controls.loading = true
    state.controls.error = false
    state.controls.form = action.controls
  },
  [HOME.PATCH_UPDATE_SERVICE_SUCCESS](state, action) {
    state.controls.loading = false
    state.controls.error = false
    state.controls.services = state.controls.services.map((service) =>
      action.payload.service.id === service.id ? action.payload.service : service
    )
    state.controls.form = cleanValuesObject(state.controls.form)
    state.controls.modal.status = false
  },
  [HOME.PATCH_UPDATE_SERVICE_ERROR](state, action) {
    state.controls.loading = false
    state.controls.error = true
  },

  [HOME.PATCH_ACCEPT_SERVICE](state, action) {
    state.controls.loading = true
    state.controls.error = false
    state.controls.form = action.controls
  },
  [HOME.PATCH_ACCEPT_SERVICE_SUCCESS](state, action) {
    state.controls.loading = false
    state.controls.error = false
    state.controls.services = state.controls.services.map((service) =>
      action.payload.service.id === service.id ? action.payload.service : service
    )
    state.controls.form = cleanValuesObject(state.controls.form)
    state.controls.message = action.payload.message
  },
  [HOME.PATCH_ACCEPT_SERVICE_ERROR](state, action) {
    state.controls.loading = false
    state.controls.error = true
  },

  [HOME.PATCH_REJECT_SERVICE](state, action) {
    state.controls.loading = true
    state.controls.error = false
    state.controls.form = action.controls
  },
  [HOME.PATCH_REJECT_SERVICE_SUCCESS](state, action) {
    state.controls.loading = false
    state.controls.error = false
    state.controls.services = state.controls.services.map((service) =>
      action.payload.service.id === service.id ? action.payload.service : service
    )
    state.controls.form = cleanValuesObject(state.controls.form)
    state.controls.message = action.payload.message
  },
  [HOME.PATCH_REJECT_SERVICE_ERROR](state, action) {
    state.controls.loading = false
    state.controls.error = true
  },
  //
  [HOME.TOGGLE_MODAL_SERVICES](state, action) {
    state.controls.modal.status = !state.controls.modal.status
    state.edit.service = false
  },
  [HOME.TOGGLE_UPDATE_MODAL_SERVICES](state, action) {
    state.controls.modal.status = !state.controls.modal.status
    state.edit.service = true
  },
  [HOME.TOGGLE_MODAL_SERVICE_ACCEPT](state, action) {
    state.controls.modal.approvated.status = !state.controls.modal.approvated.status
  },
  [HOME.TOGGLE_MODAL_SERVICE_REJECT](state, action) {
    state.controls.modal.reject.status = !state.controls.modal.reject.status
  },
  //
  [HOME.PATCH_SBUDGET_ACCEPT](state) {
    state.controls.loading = true
    state.controls.error = false
    state.controls.message = ""
  },
  [HOME.PATCH_SBUDGET_ACCEPT_SUCCESS](state, action) {
    state.controls.loading = false
    state.controls.error = false
    state.controls.services = state.controls.services.map((service) => {
      if (action.payload.budget.id === service?.budgets[0]?.id) {
        service.budgets[0] = action.payload.budget
      }
      return service
    })
    state.controls.modal.budgetAcceptReject.status = false
  },
  [HOME.PATCH_SBUDGET_ACCEPT_ERROR](state, action) {
    state.controls.loading = false
    state.controls.error = true
    state.controls.modal.budgetAcceptReject.status = false
  },
  [HOME.PATCH_SBUDGET_REJECT](state) {
    state.controls.loading = true
    state.controls.error = false
  },
  [HOME.PATCH_SBUDGET_REJECT_SUCCESS](state, action) {
    state.controls.loading = false
    state.controls.error = false
    state.controls.services = state.controls.services.map((service) => {
      if (action.payload.budget.id === service?.budgets[0]?.id) {
        service.budgets[0] = action.payload.budget
      }
      return service
    })
    state.controls.modal.budgetAcceptReject.status = false
  },
  [HOME.PATCH_SBUDGET_REJECT_ERROR](state, action) {
    state.controls.loading = false
    state.controls.error = true
    state.controls.modal.budgetAcceptReject.status = false
  },
  [HOME.TOGGLE_MODAL_SBUDGET_ACCEPT_REJECT](state, action) {
    state.controls.modal.budgetAcceptReject.status = !state.controls.modal.budgetAcceptReject.status
  },
  //
  [HOME.GET_SERVICE_WORKSHOP](state, action) {
    // state.controls.loading = true
    // state.controls.error = false
  },
  [HOME.GET_SERVICE_WORKSHOP_SUCCESS](state, action) {
    // state.controls.loading = false
    // state.controls.error = false
    state.controls.fields.workshops = action.payload.workshop.map((workshop) => workshop.name)
  },
  [HOME.GET_SERVICE_WORKSHOP_ERROR](state, action) {
    // state.controls.loading = false
    // state.controls.error = true
  },
  [HOME.GET_SERVICE_VEHICLE](state, action) {
    // state.controls.loading = true
    // state.controls.error = false
  },
  [HOME.GET_SERVICE_VEHICLE_SUCCESS](state, action) {
    // state.controls.loading = false
    // state.controls.error = false
    state.controls.fields.vehicles = action.payload.vehicles.map((vehicle) => vehicle.plateNumber)
  },
  [HOME.GET_SERVICE_VEHICLE_ERROR](state, action) {
    // state.controls.loading = false
    // state.controls.error = true
  },
  [HOME.GET_SERVICE_MAINTENENCE](state, action) {
    // state.controls.loading = true
    // state.controls.error = false
  },
  [HOME.GET_SERVICE_MAINTENENCE_SUCCESS](state, action) {
    // state.controls.loading = false
    // state.controls.error = false
    state.controls.fields.maintenances = action.payload.preventiveMaintenance.map(
      (maintenance) => maintenance.name
    )
  },
  [HOME.GET_SERVICE_MAINTENENCE_ERROR](state, action) {
    // state.controls.loading = false
    // state.controls.error = true
  },
  [HOME.GET_SERVICE_FILTERS](state, action) {},
  [HOME.GET_SERVICE_FILTERS_SUCCESS](state, action) {
    state.controls.fields.filters = action.payload.filters
  },
  [HOME.GET_SERVICE_FILTERS_ERROR](state, action) {},
  [HOME.GET_SERVICE_EXPORT](state, action) {
    state.controls.loading = true
    // state.controls.error = false
  },
  [HOME.GET_SERVICE_EXPORT_SUCCESS](state, action) {
    state.controls.loading = false
    // let MIME_TYPE = "text/csv"
    // let blob = new Blob([action.payload.data], { type: MIME_TYPE })
    // window.location.href = window.URL.createObjectURL(blob)
    // state.controls.error = false
    // state.controls.fields.workshops = action.payload.workshop.map(
    //   (workshop) => workshop.name
    // )
  },
  [HOME.GET_SERVICE_EXPORT_ERROR](state, action) {
    state.controls.loading = false
    // state.controls.error = true
  },
})

export default home
