// @ts-nocheck
import React, { useState, useEffect } from "react"
// material
import TextField from "@material-ui/core/TextField"
import Checkbox from "@material-ui/core/Checkbox"
import Grid from "@material-ui/core/Grid"
// utils
import moment from "moment"
import { truthty } from "utils/functions"
import { IconButton } from "@material-ui/core"
import HighlightOffIcon from "@material-ui/icons/HighlightOff"

const DatePicker = (props) => {
  // const [value, setValue] = useState(moment().format("YYYY-MM-DD"));
  const [value, setValue] = useState("")
  const [active, setActive] = useState(false)

  useEffect(() => {
    if (truthty(props.value)) {
      setValue(moment(props.value).format("YYYY-MM-DD"))
    } else {
      setValue("")
    }
  }, [props.value])

  function changeControls(event) {
    setValue(event.target.value)
    props.onChange(
      event,
      !active && props.disableBox ? null : moment(event.target.value).toISOString()
    )
  }

  function disabledOnChange(event) {
    setActive(event.target.checked)
    props.onCheckboxChange(
      !event.target.checked && props.disableBox ? null : moment(value).toISOString()
    )
  }

  function handleClear(event) {
    setValue("")
    props.onChange({
      persist: () => {},
      target: { value: "", id: props.name, name: props.name, type: "date" },
    })
  }

  return (
    <Grid item container style={props.style}>
      {props.disableBox && (
        <Grid item xs={2}>
          <Checkbox checked={active} onChange={disabledOnChange} />
        </Grid>
      )}
      <Grid item xs>
        <TextField
          className={props.className}
          timezone="[[es-CL]]"
          variant={props.variant}
          disabled={(!active && props.disableBox) || props.disableInput}
          value={value}
          onChange={changeControls}
          fullWidth={props.fullWidth}
          id={props.id || "date"}
          label={props.label || "Fecha"}
          key={`${props.label}-key`}
          name={props.id}
          error={props.error}
          helperText={props.helperText}
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            startAdornment: (
              <IconButton
                onClick={() => handleClear()}
                disabled={!value || props.disableInput}
                hidden={!value}
                style={{ order: 1, padding: "0px", marginRight: "10px" }}
              >
                <HighlightOffIcon fontSize="small"></HighlightOffIcon>
              </IconButton>
            ),
          }}
        />
      </Grid>
    </Grid>
  )
}

DatePicker.defaultProps = {
  style: {
    paddingBottom: "10px",
  },
}

export default DatePicker
