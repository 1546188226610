import { Button, TableBody, TableCell, TextField } from "@material-ui/core"
import CustomNumberField from "commons/components/CustomNumberField"
import React, { useState } from "react"

const SparePartsTableItem = (props) => {
  const { sparePartSelected, updateBudgetSparePart, removeBudgetSparePart } = props
  const costPrice = sparePartSelected?.sparePartCostByVehicleType[0].price
  const [quantityValue, setQuantityValue] = useState(
    sparePartSelected.quantity ? sparePartSelected.quantity : 1
  )

  const handleQuantityChange = (event) => {
    setQuantityValue(event.target.value)
    const infoToUpdate = {
      id: sparePartSelected.id,
      quantity: Number(event.target.value),
      total: Math.round(event.target.value * costPrice),
    }
    updateBudgetSparePart(infoToUpdate)
  }

  return (
    <TableBody>
      <TableCell>{sparePartSelected.code}</TableCell>
      <TableCell>{sparePartSelected.description}</TableCell>
      <TableCell>
        <CustomNumberField
          variant={"outlined"}
          key={"quantity_value"}
          id={"quantity_value"}
          name={"quantity_value"}
          type={"text"}
          value={quantityValue}
          onHandleChange={handleQuantityChange}
        />
      </TableCell>
      <TableCell> $ {new Intl.NumberFormat("en-DE").format(costPrice)} </TableCell>
      <TableCell>
        {" "}
        ${" "}
        {new Intl.NumberFormat("en-DE").format(
          Math.round(costPrice * quantityValue)
        )}{" "}
      </TableCell>
      <TableCell>
        {" "}
        <Button
          variant="outlined"
          style={{ color: "red", borderColor: "red" }}
          onClick={() => removeBudgetSparePart(sparePartSelected.id)}
        >
          Eliminar
        </Button>
      </TableCell>
    </TableBody>
  )
}

export default SparePartsTableItem
