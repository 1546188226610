// @ts-nocheck
import React from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core"
import ButtonWithLoader from "commons/containers/ButtonWithLoader"
import CustomField from "commons/components/CustomField"
import CustomSelectField from "commons/components/CustomSelectField"

const UserForm = (props) => {
  // const customChangeControls = (event) => {
  //   props.changeControls({ [event.target.name]: event.target.value })
  // }
  return (
    <Dialog
      fullWidth
      onClose={props.toggleForm}
      aria-labelledby="simple-dialog-title"
      open={props.isOpen}
    >
      <DialogTitle>{`${props.edit ? "Editar" : "Crear"} usuario`}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item sm={12} xs={12}>
            <CustomField
              value={props.controls.name}
              onChange={props.changeControls}
              fullWidth
              id="name"
              label="Nombre"
              error={props.errors.name}
              helperText={props.errors.name && "Debe ingresar un nombre"}
            />
          </Grid>

          <Grid item sm={12} xs={12}>
            <CustomField
              value={props.controls.email}
              onChange={props.changeControls}
              fullWidth
              id="email"
              label="Email"
              error={props.errors.email}
              helperText={props.errors.email && "Debe ingresar un email valido"}
            />
          </Grid>
          <Grid item sm={12} xs={12}>
            <CustomSelectField
              value={props.controls.roleId}
              className={props.className.select}
              onChange={props.changeControls}
              label={"Rol"}
              name={"roleId"}
              id={"roleId"}
              data={props?.helperFilters?.roles}
              error={props.errors.roleId}
              helperText={props.errors.roleId && "Debe seleccionar un rol"}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.toggleForm} color="primary">
          Cancelar
        </Button>
        <ButtonWithLoader onClick={props.submit} autoFocus buttonId="create-edit-client">
          {props.edit ? "Editar" : "Agregar"}
        </ButtonWithLoader>
      </DialogActions>
    </Dialog>
  )
}

export default UserForm
