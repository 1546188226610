import apiRequest, { apiSuccess, get } from "utils/api"
import { call, put, spawn, takeLatest } from "redux-saga/effects"
import { BUDGETDETAILS } from "../reducer"
import { saveAs } from 'file-saver';
import { APP } from "commons/reducer";

function* getBudget() {
  yield takeLatest(BUDGETDETAILS.GET_BUDGET, function* (action) {
    const response = yield apiRequest(`api/v1/budgets/search/${action.controls.id}`, {
      method: "get",
    })
    if (!response.error) {
      yield put(apiSuccess(BUDGETDETAILS.GET_BUDGET_SUCCESS, response))
    } else {
      yield put(apiSuccess(BUDGETDETAILS.GET_BUDGET_ERROR, response))
    }
  })
}

function* generateExcel() {
  yield takeLatest(BUDGETDETAILS.GENERATE_EXCEL, function* (action) {
    const id = action.controls.id;
    const socketId = action.controls.socketId;

    const response = yield apiRequest(`api/v1/budgets/${id}/generate-excel?socketId=${socketId}`, {
      method: "get",
    });

    if (!response.error) {
      yield put(apiSuccess(BUDGETDETAILS.GENERATE_EXCEL_SUCCESS, response));
      yield put(apiSuccess(APP.SET_SUCCESS, response));
    } else {
      yield put(apiSuccess(BUDGETDETAILS.GET_BUDGET_ERROR, response));
    }
  })
}

export default function* root() {
  yield spawn(getBudget)
  yield spawn(generateExcel)
}
