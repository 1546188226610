import React, { useEffect } from "react"

import { makeStyles } from "@material-ui/core/styles"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormHelperText from "@material-ui/core/FormHelperText"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import { truthty } from "utils/functions"

const useStyles = makeStyles((theme) => ({
  formControlFullwidth: {
    width: "100%",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 220,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },
}))

const CustomSelectField = (props) => {
  const { className, label, name, data, onChange, fullWidth = false } = props
  const classes = useStyles()

  const [value, setValue] = React.useState("")
  useEffect(() => {
    if (truthty(props.value)) {
      setValue(props.value)
    }
  }, [props.value])

  const handleChange = (event) => {
    setValue(event.target.value)
    onChange(event)
  }

  const renderOptions = (data) => {
    return data.map((option, key) => (
      <MenuItem key={key} value={option.value}>
        {option.label}
      </MenuItem>
    ))
  }

  const validatedClassName = () => {
    if (className) return className;

    return fullWidth ? classes.formControlFullwidth : classes.formControl;
  }

  return (
    <>
      <FormControl
        className={validatedClassName()}
        style={props.style}
        error={props.error}
        variant={props.variant}
      >
        <InputLabel htmlFor="name-native-error" id="demo-simple-select-outlined-label">
          {label}
        </InputLabel>
        <Select
          defaultValue=""
          name={name}
          label={label}
          labelId={`"${name}-labelId"`}
          id={name}
          value={value}
          disabled={props.disabled}
          onChange={handleChange}
        >
          {renderOptions(data)}
        </Select>
        <FormHelperText>{props.helperText}</FormHelperText>
      </FormControl>
    </>
  )
}

export default CustomSelectField
