import { createAction, createReducer } from "utils/reducer";

// Local constants
const PATH = "login/";
const DISABLE_ERRORS = `${PATH}DISABLE_ERRORS`;
const CONTROLS_CHANGED = `${PATH}CONTROLS_CHANGED`;

// Global constants for saga
export const LOGIN = {
  LOGIN_USER_SUCCESS: `${PATH}FETCH_LOGIN_USER_SUCCESS`,
  LOGIN_USER_FAIL: `${PATH}FETCH_LOGIN_USER_FAIL`,
  LOGIN_USER: `${PATH}FETCH_LOGIN_USER`,
  GET_USER_SUCCESS: `${PATH}GET_USER_SUCCESS`,
  GET_USER_FAIL: `${PATH}GET_USER_FAIL`,
  GET_USER: `${PATH}GET_USER`,
  VERIFY_SUCCESS: `${PATH}VERIFY_SUCCESS`,
  VERIFY_FAIL: `${PATH}VERIFY_FAIL`,
  VERIFY: `${PATH}VERIFY`,
};

// actions
export const loginActions = {
  disableErrors: createAction(DISABLE_ERRORS),
  // api
  getUser: createAction(LOGIN.GET_USER),
  login: createAction(LOGIN.LOGIN_USER, "controls", "redirectUrl"),
  verify: createAction(LOGIN.VERIFY, "token"),
  controlsChanged: createAction(CONTROLS_CHANGED, "controls"),
};

const initialState = {
  controls: {
    loading: false,
    message: "",
    error: false,
  },
};

const login = createReducer(initialState, {
  [`${DISABLE_ERRORS}`](state, action) {
    state.controls.error = false;
  },
  // api
  [LOGIN.LOGIN_USER](state) {
    state.controls.loading = true;
    state.controls.error = false;
  },
  [LOGIN.LOGIN_USER_SUCCESS](state) {
    state.controls.loading = false;
  },
  [LOGIN.GET_USER_FAIL](state, action) {
    state.controls.loading = false;
    state.controls.error = true;
    state.controls.message = action.payload.errorMsg;
  },
  [LOGIN.LOGIN_USER_FAIL](state, action) {
    state.controls.loading = false;
    state.controls.error = true;
    state.controls.message = action.payload.errorMsg;
  },
  [CONTROLS_CHANGED](state, action) {
    state.controls = {
      ...state.controls,
      ...action.controls,
    };
  },
});

export default login;
