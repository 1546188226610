import { Button, FormControl, InputLabel, MenuItem, Select, Typography } from "@material-ui/core"
import CustomField from "commons/components/CustomField"
import SearchIcon from "@material-ui/icons/Search"
import DeleteIcon from "@material-ui/icons/Delete"
import CustomSelectField from "commons/components/CustomSelectField"
import DatePickerLanguage from "commons/components/DatePickerLanguage"
import ButtonWithLoader from "commons/containers/ButtonWithLoader"
import React, { useState } from "react"
import { KeyboardDatePicker } from "@material-ui/pickers"

const BudgetsFilter = (props) => {
  const { onFilter, className, changeFilter, filters, clearFilters } = props

  const handleSubmit = (event) => {
    onFilter(event, filters)
  }

  return (
    <>
      <form onSubmit={handleSubmit} className={className.containerFilter}>
        <Typography component="div">
          <div className={`flex-section-wraper`}>
            <CustomField
              className={className.field}
              autoComplete={"off"}
              variant={"standard"}
              label={"N° Cotización"}
              key={"cotizationNumber"}
              id={"cotizationNumber"}
              name={"cotizationNumber"}
              type={"text"}
              value={filters.cotizationNumber}
              onChange={changeFilter}
            />

            <CustomField
              className={className.field}
              autoComplete={"off"}
              variant={"standard"}
              label={"Agendador"}
              key={"schedulerName"}
              id={"schedulerName"}
              name={"schedulerName"}
              type={"text"}
              value={filters.schedulerName}
              onChange={changeFilter}
            />

            <KeyboardDatePicker
              className={className.field}
              disableFuture
              disableToolbar
              variant={"dialog"}
              format="dd/MM/yyyy"
              id={"dateService"}
              label={"Fecha Agendamiento"}
              size="small"
              value={filters.dateService}
              name="dateService"
              onChange={(date) => changeFilter({ name: "dateService", value: date })}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />

            <KeyboardDatePicker
              className={className.field}
              disableFuture
              disableToolbar
              variant={"dialog"}
              format="dd/MM/yyyy"
              id={"addedDate"}
              label={"Fecha Ingreso"}
              size="small"
              value={filters.addedDate}
              name="addedDate"
              onChange={(date) => changeFilter({ name: "addedDate", value: date })}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />

            <FormControl
              className={className.formControl}
              style={props.style}
              error={props.error}
              variant={props.variant}
            >
              <InputLabel htmlFor="name-native-error" id="demo-simple-select-outlined-label">
                Estado
              </InputLabel>
              <Select
                name={"statusBudget"}
                label={"Estado"}
                id={"statusBudget"}
                value={filters.statusBudget}
                onChange={changeFilter}
              >
                <MenuItem value="">--</MenuItem>
                <MenuItem value="Borrador">Borrador</MenuItem>
                <MenuItem value="Confirmado">Confirmado</MenuItem>
              </Select>
            </FormControl>

            <CustomField
              autoComplete={"off"}
              variant={"standard"}
              label={"Marca Vehículo"}
              key={"vehicleBrand"}
              id={"vehicleBrand"}
              name={"vehicleBrand"}
              type={"text"}
              value={filters.vehicleBrand}
              onChange={changeFilter}
            />

            <CustomField
              className={className.field}
              autoComplete={"off"}
              variant={"standard"}
              label={"Modelo Vehículo"}
              key={"vehicleModel"}
              id={"vehicleModel"}
              name={"vehicleModel"}
              type={"text"}
              value={filters.vehicleModel}
              onChange={changeFilter}
            />

            <Button
              startIcon={<DeleteIcon />}
              className={className.clearFiltersButton}
              variant="outlined"
              onClick={clearFilters}
            >
              Limpiar Filtros
            </Button>

            <ButtonWithLoader
              onClick={handleSubmit}
              className={className.button}
              startIcon={<SearchIcon fontSize="small" />}
            >
              Buscar
            </ButtonWithLoader>
          </div>
        </Typography>
      </form>
    </>
  )
}

export default BudgetsFilter
