// @ts-nocheck
import React from "react"
import { useHistory } from "react-router-dom"
// material
import { AppBar, CardMedia, IconButton, Button, Typography, Toolbar } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Paths from "utils/paths"
import LOGO from "utils/images/logo-carabinero-h-blanco300-2.png"
import MenuIcon from "@material-ui/icons/Menu"
// styles
import styles from "./styles"

const useStyles = makeStyles(styles)

const NavBar = (props) => {
  const classes = useStyles()
  const history = useHistory()

  function sessionOption() {
    if (Object.values(props.user).length) {
      return (
        <Button onClick={props.logOut} color="inherit">
          Cerrar sesión
        </Button>
      )
    } else {
      return (
        <Button onClick={() => history.push(Paths.LOGIN)} color="inherit">
          Iniciar sesión
        </Button>
      )
    }
  }

  return (
    <AppBar position="static">
      <Toolbar className={`${classes.root}`}>
        <div className={`container_sider_bar ${classes.containerSiderBar}`}>
          <IconButton
            onClick={props.toggleDrawer}
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            style={{ cursor: "pointer" }}
            className={`flex-section-wraper ${classes.title}`}
            onClick={() => history.push(Paths.CONTRACTS)}
          >
            <CardMedia className={classes.icon} image={LOGO} title="Carabineros" />
            CARABINEROS DE CHILE
          </Typography>
        </div>
        {sessionOption()}
      </Toolbar>
    </AppBar>
  )
}

export default NavBar
