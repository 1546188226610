// @ts-nocheck
import React from "react"
// material
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem } from "@material-ui/core"
import ButtonWithLoader from "commons/containers/ButtonWithLoader"
import CustomField from "commons/components/CustomField"
import CustomSelectField from "commons/components/CustomSelectField"

const WorkShopForm = (props) => {
  const formatOptions = (data) => {
    return data.map((option, index) => ({
      label: option.name,
      value: option.id,
    }))
  }

  const vehicleTypesData = formatOptions(Object.values(props.vehicleTypes));

  return (
    <Dialog
      fullWidth
      onClose={props.closeModal}
      aria-labelledby="simple-dialog-title"
      open={props.isOpen}
    >
      <DialogTitle>{`${props.edit ? "Editar" : "Crear"} vehículo`}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item sm={12} xs={12}>
            <CustomSelectField
              name="vehicleTypeId"
              label="Tipo Vehículo"
              data={vehicleTypesData ?? []}
              value={props.controls.vehicleTypeId}
              onChange={props.changeControls}
              error={props.errors.vehicleTypeId}
              helperText={props.errors.vehicleTypeId && "Debe seleccionar un tipo de vehículo"}
              fullWidth={true}
            />
          </Grid>
          <Grid item sm={12} xs={12}>
            <CustomField
              value={props.controls.plateNumber}
              onChange={props.changeControls}
              fullWidth
              id="plateNumber"
              label="Patente"
              error={props.errors.plateNumber}
              helperText={props.errors.plateNumber && "Debe ingresar un número de patente. J-NNN"}
            />
          </Grid>
          <Grid item sm={12} xs={12}>
            <CustomField
              value={props.controls.model}
              onChange={props.changeControls}
              fullWidth
              id="model"
              label="Modelo"
              error={props.errors.model}
              helperText={props.errors.model && "Debe ingresar un modelo. MKXX"}
            />
          </Grid>
          <Grid item sm={12} xs={12}>
            <CustomField
              value={props.controls.vin}
              onChange={props.changeControls}
              fullWidth
              id="vin"
              label="Código Vin"
              error={props.errors.vin}
              helperText={props.errors.vin && "Debe ingresar un código Vin. NNN"}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.closeModal()} color="primary">
          Cancelar
        </Button>
        <ButtonWithLoader onClick={props.submit} autoFocus buttonId="create-edit-client">
          {props.edit ? "Editar" : "Agregar"}
        </ButtonWithLoader>
      </DialogActions>
    </Dialog>
  )
}

export default WorkShopForm
