import { put, spawn, takeLatest } from "redux-saga/effects"
import apiRequest, { apiSuccess } from "utils/api"
import { BUDGETSMAINTAINER } from "../reducer"
import { objectToQuery } from "utils/functions"

function* getBudgets() {
  yield takeLatest(BUDGETSMAINTAINER.GET_BUDGETS, function* (action) {
    let query = action.controls ? objectToQuery(action.controls) : ""
    const response = yield apiRequest(`api/v1/budgets/?${query}`, {
      method: "get",
    })
    if (!response.error) {
      yield put(apiSuccess(BUDGETSMAINTAINER.GET_BUDGETS_SUCCESS, response))
    } else {
      yield put(apiSuccess(BUDGETSMAINTAINER.GET_BUDGETS_ERROR, response))
    }
  })
}

export default function* root() {
  yield spawn(getBudgets)
}
