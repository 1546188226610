import { Box, TextField, Typography } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import React, { useState } from "react"

const WorkshopSearcher = (props) => {
  const { workshopCosts, addBudgetWorkshop } = props

  const [inputValue, setInputValue] = useState("")

  const handler = (event, newValue) => {
    if (newValue !== null) {
      const workshopLaborSelected = workshopCosts.filter(
        (item) => item.code === newValue.split(" ")[0]
      )
      if (workshopLaborSelected[0]) {
        addBudgetWorkshop(workshopLaborSelected[0])
      }
    }
    setInputValue('')
  }

  return (
    <Box sx={{ marginBottom: "30px", marginTop: "20px" }}>
      <Typography style={{ fontWeight: "bold", fontSize: "20px" }}>Trabajo Taller:</Typography>
      <Autocomplete
        freeSolo
        value={''}
        inputValue={inputValue}
        onChange={handler}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue)
        }}
        options={workshopCosts.map((option) => `${option.code} - ${option.description}`)}
        /* getOptionLabel={(option) => option.description} */
        style={{ minWidth: "300px", marginTop: "10px", paddingLeft: "10vh", paddingRight: "10vh" }}
        renderInput={(params) => (
          <TextField {...params} label="Buscar Trabajo Taller" variant="outlined" />
        )}
      />
    </Box>
  )
}

export default WorkshopSearcher
