import React from "react"
import { Typography } from "@material-ui/core"
import CustomDateGrid from "commons/components/CustomDateGrid"
import TableActions from "./Actions"
import { fDateUtc } from "utils/functions"

const TableEvents = ({ className, data, onModal }) => {
  const columns = [
    {
      field: "description",
      headerName: "Descripción",
      flex: 1,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Typography component="div">
          <div className="flex-section">{params.row?.description}</div>
        </Typography>
      ),
    },
    {
      field: "date",
      headerName: "Fecha",
      flex: 1,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Typography component="div">
          <div className="flex-section">
            <Typography variant="body2" className={className.p}>
              {fDateUtc(params.getValue("date"))}
            </Typography>
          </div>
        </Typography>
      ),
    },
    {
      field: "eventType",
      headerName: "Tipo",
      flex: 1,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Typography component="div">
          <div className="flex-section">
            <p>{params.row?.eventType?.name}</p>
          </div>
        </Typography>
      ),
    },
    {
      field: "Actions",
      headerName: "Acciones",
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Typography component="div">
          <div className="flex-section">
            <TableActions id={params.row?.id} handleOpenModal={onModal} />
          </div>
        </Typography>
      ),
      flex: 1,
    },
  ]

  return (
    <div>
      <CustomDateGrid rows={data} columns={columns} height="255px" maxRows={4} />
    </div>
  )
}

export default TableEvents
