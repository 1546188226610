import { createAction, createReducer } from "utils/reducer"

// Local constants
const PATH = "maintainer/user/"

const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`
const SET_INVALID_CONTROLS = `${PATH}SET_INVALID_CONTROLS`

// Global constants for saga
export const MAINTAINERUSER = {
  GET_STATE_FROM_API_USERS: `${PATH}GET_STATE_FROM_API_USERS`,
  GET_STATE_FROM_API_USERS_SUCCESS: `${PATH}GET_STATE_FROM_API_USERS_SUCCESS`,
  GET_STATE_FROM_API_ROLES: `${PATH}GET_STATE_FROM_API_ROLES`,
  GET_STATE_FROM_API_ROLES_SUCCESS: `${PATH}GET_STATE_FROM_API_ROLES_SUCCESS`,
  POST_ADD_USER: `${PATH}POST_ADD_USER`,
  POST_ADD_USER_SUCCESS: `${PATH}POST_ADD_USER_SUCCESS`,
  POST_ADD_USER_ERROR: `${PATH}POST_ADD_USER_ERROR`,
  POST_UPDATE_USER: `${PATH}POST_UPDATE_USER`,
  POST_UPDATE_USER_SUCCESS: `${PATH}POST_UPDATE_USER_SUCCESS`,
  POST_UPDATE_USER_ERROR: `${PATH}POST_UPDATE_USER_ERROR`,
  DELETE_USER: `${PATH}DELETE_USER`,
  DELETE_USER_SUCCESS: `${PATH}DELETE_USER_SUCCESS`,
  DELETE_USER_ERROR: `${PATH}DELETE_USER_ERROR`,
  PATCH_ENABLE_MAILER: `${PATH}PATCH_ENABLE_MAILER`,
  PATCH_ENABLE_MAILER_SUCCESS: `${PATH}PATCH_ENABLE_MAILER_SUCCESS`,
  PATCH_ENABLE_MAILER_ERROR: `${PATH}PATCH_ENABLE_MAILER_ERROR`,

  TOGGLE_CREATE_MODAL: `${PATH}TOGGLE_CREATE_MODAL`,
  TOGGLE_UPDATED_MODAL: `${PATH}TOGGLE_UPDATED_MODAL`,
  TOGGLE_DELETED_MODAL: `${PATH}TOGGLE_DELETED_MODAL`,
  TOGGLE_ENABLE_MAILER: `${PATH}TOGGLE_ENABLE_MAILER`,
}

// actions
export const maintainerUserActions = {
  getStateFromApiUsers: createAction(MAINTAINERUSER.GET_STATE_FROM_API_USERS),
  getStateFromApiRoles: createAction(MAINTAINERUSER.GET_STATE_FROM_API_ROLES),
  addUser: createAction(MAINTAINERUSER.POST_ADD_USER, "controls"),
  updateUser: createAction(MAINTAINERUSER.POST_UPDATE_USER, "id", "controls"),
  deleteUser: createAction(MAINTAINERUSER.DELETE_USER, "id"),
  enableMailer: createAction(MAINTAINERUSER.PATCH_ENABLE_MAILER, "id", "controls"),

  toggleCreateModal: createAction(MAINTAINERUSER.TOGGLE_CREATE_MODAL, "item"),
  toggleUpdatedModal: createAction(MAINTAINERUSER.TOGGLE_UPDATED_MODAL, "item"),
  toggleDeletedModal: createAction(MAINTAINERUSER.TOGGLE_DELETED_MODAL, "id"),
  toggleEnableMailerModal: createAction(MAINTAINERUSER.TOGGLE_ENABLE_MAILER, "id", "info"),
  changeControls: createAction(CHANGE_CONTROLS, "controls", "invalidControls"),
  setInvalidControls: createAction(SET_INVALID_CONTROLS, "controls"),
}

const defaultControls = {
  name: "",
  email: "",
  roleId: "",
}

const defaultInvalidControls = {
  name: false,
  email: false,
  roleId: false,
}

const initialState = {
  formControls: {
    name: "",
    email: "",
    roleId: "",
  },
  invalidControls: {
    name: false,
    email: false,
    roleId: false,
  },
  controls: {
    toDeleteId: "",
    toEditId: "",
    loading: false,
    error: false,
    message: "",
  },
  modal: {
    form: {
      status: false,
    },
    deleted: {
      status: false,
    },
    enableMailer: {
      status: false,
      info: {
        title: "",
        description: "",
        labelAction: "",
        colorActionBtn: "",
        enable: false,
      },
    },
  },
  edit: false,
}

const maintainerUser = createReducer(initialState, {
  [CHANGE_CONTROLS](state, action) {
    state.formControls = {
      ...state.formControls,
      ...action.controls,
    }
    state.invalidControls = {
      ...state.invalidControls,
      ...(action.invalidControls || {}),
    }
  },
  [SET_INVALID_CONTROLS](state, action) {
    state.invalidControls = {
      ...state.invalidControls,
      ...action.controls,
    }
  },
  [MAINTAINERUSER.POST_ADD_USER](state) {
    state.controls.loading = true
    state.controls.error = false
    state.controls.message = ""
  },
  [MAINTAINERUSER.POST_ADD_USER_SUCCESS](state, action) {
    state.controls.loading = false
    state.controls.error = false
    state.controls.message = "Usuario agregado al sistema."
    state.controls.toDeleteId = ""
    state.controls.toEditId = ""
    state.modal.form.status = !state.modal.form.status
  },
  [MAINTAINERUSER.POST_ADD_USER_ERROR](state, action) {
    state.controls.loading = false
    state.controls.error = true
    state.controls.message = "."
  },

  [MAINTAINERUSER.POST_UPDATE_USER](state) {
    state.controls.loading = true
    state.controls.error = false
  },
  [MAINTAINERUSER.POST_UPDATE_USER_SUCCESS](state, action) {
    state.controls.loading = false
    state.controls.error = false
    state.controls.message = "Los datos del usuario fueron actualizados con exito."
    state.controls.toDeleteId = ""
    state.controls.toEditId = ""
    state.modal.form.status = !state.modal.form.status
  },
  [MAINTAINERUSER.POST_UPDATE_USER_ERROR](state, action) {
    state.controls.loading = false
    state.controls.error = true
    state.controls.message = "."
  },

  [MAINTAINERUSER.DELETE_USER](state, action) {
    state.controls.loading = true
    state.deleteIsOpen = false
    state.toDeleteId = ""
  },
  [MAINTAINERUSER.DELETE_USER_SUCCESS](state, action) {
    state.controls.loading = false
    state.controls.error = false
    state.controls.message = "El usuario fue eliminado con exito."
    state.controls.toDeleteId = ""
    state.controls.toEditId = ""
    state.modal.deleted.status = !state.modal.deleted.status
  },
  [MAINTAINERUSER.DELETE_USER_ERROR](state, action) {
    state.controls.loading = false
    state.controls.error = true
    state.controls.message = "."
  },

  [MAINTAINERUSER.PATCH_ENABLE_MAILER](state) {
    state.controls.loading = true
    state.controls.error = false
  },
  [MAINTAINERUSER.PATCH_ENABLE_MAILER_SUCCESS](state, action) {
    state.controls.loading = false
    state.controls.error = false
    state.controls.message = "---"
    state.controls.toDeleteId = ""
    state.controls.toEditId = ""
    state.controls.toEnableId = ""
    state.modal.enableMailer.status = !state.modal.enableMailer.status
  },
  [MAINTAINERUSER.PATCH_ENABLE_MAILER_ERROR](state, action) {
    state.controls.loading = false
    state.controls.error = true
    state.controls.message = "."
  },

  [MAINTAINERUSER.TOGGLE_CREATE_MODAL](state) {
    state.controls.toDeleteId = ""
    state.controls.toEditId = ""
    state.formControls = { ...defaultControls }
    state.invalidControls = { ...defaultInvalidControls }
    state.modal.form.status = !state.modal.form.status
    state.edit = false
  },
  [MAINTAINERUSER.TOGGLE_UPDATED_MODAL](state, action) {
    state.invalidControls = { ...defaultInvalidControls }
    state.controls.toDeleteId = ""
    state.controls.toEditId = action?.item?.id || ""
    state.formControls.name = action?.item?.name
    state.formControls.email = action?.item?.email || ""
    state.formControls.roleId = action?.item?.roleId || ""
    state.modal.form.status = !state.modal.form.status
    state.edit = true
  },
  [MAINTAINERUSER.TOGGLE_DELETED_MODAL](state, action) {
    state.controls.toDeleteId = action?.id || ""
    state.controls.toEditId = ""
    state.modal.deleted.status = !state.modal.deleted.status
  },
  [MAINTAINERUSER.TOGGLE_ENABLE_MAILER](state, action) {
    state.controls.toEnableId = action?.id || ""
    state.modal.enableMailer.info = { ...action.info }
    state.controls.toDeleteId = ""
    state.controls.toEditId = ""
    state.modal.enableMailer.status = !state.modal.enableMailer.status
  },
})

export default maintainerUser
