const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paperAnchorLeft: {
    width: "300px",
  },
  titles: {
    marginTop: "15px",
    textAlign: "center",
  },
  icon: {
    width: "144px",
    height: "162px",
    // width: "160px",
    // height: "45px",
    display: "inline-block",
    marginRight: "5px",
  },
  nested: {
    paddingLeft: theme.spacing(7),
  },
})

export default styles
