// @ts-nocheck
import React from "react"

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core"

import CustomSwitch from "commons/components/CustomSwitch"
import CustomAutoComplete from "commons/components/CustomAutoComplete"
import ButtonWithLoader from "commons/containers/ButtonWithLoader"
import DatePicker from "commons/components/DatePicker"
import CustomField from "commons/components/CustomField"
import CustomNumberField from "commons/components/CustomNumberField"
import CustomSelectField from "commons/components/CustomSelectField"

const FormModal = (props) => {
  const { className, style, onChange, onChangeCustom, onChangeSwitch, submit, data, errors } = props

  const handleChange = (event) => {
    onChange(event)
  }

  const handleChangeAutoComp = (event, newValue) => {
    onChange(event, newValue)
  }

  const handleChangeCustom = (event) => {
    onChangeCustom(event, event.target.value)
  }

  return (
    <Dialog
      fullWidth
      onClose={props.toggleForm}
      aria-labelledby="simple-dialog-title"
      open={props.isOpen}
    >
      <DialogTitle>Agendar servicio</DialogTitle>
      <DialogContent className={style}>
        <form onSubmit={submit}>
          <Grid container spacing={1} justify="center">
            <Grid item xs={10}>
              <CustomField
                variant="outlined"
                className={className.field}
                label="DOE"
                key="doe"
                id="doe"
                value={data.doe}
                error={!!errors.doe}
                helperText={errors.doe}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={10}>
              <CustomAutoComplete
                className={className.field}
                label="Taller"
                key={"workshopName"}
                id={"workshopName"}
                name={"workshopName"}
                value={data.workshopName}
                onChange={handleChangeAutoComp}
                defaultValue={null}
                options={props.helperWorkshops}
                error={!!errors.workshopName}
                helperText={errors.workshopName}
              />
            </Grid>

            <Grid item xs={10}>
              <DatePicker
                className={className.field}
                variant="outlined"
                onChange={handleChange}
                id={"date"}
                label={"Fecha solicitada para el agendamiento"}
                value={data.date}
                error={!!errors.date}
                helperText={errors.date}
                helperText={"Ingrese la fecha tentativa para el servicio"}
                fullWidth={true}
                autoComplete="off"
              />
            </Grid>

            <Grid item xs={10}>
              <CustomSelectField
                className={className.field}
                variant="outlined"
                onChange={handleChangeCustom}
                label={"Tipo de servicio"}
                name={"serviceType"}
                value={data.serviceType}
                error={!!errors.serviceType}
                helperText={errors.serviceType}
                disabled={props.edit}
                data={[
                  { value: "mantención", label: "Mantención" },
                  { value: "entrega de repuestos", label: "Entrega de repuestos" },
                ]}
              />
            </Grid>

            {!!data?.serviceType?.localeCompare("entrega de repuestos") ? (
              <>
                <Grid item xs={10}>
                  <CustomAutoComplete
                    className={className.field}
                    label="Vehículo"
                    key={"vehicleName"}
                    id={"vehicleName"}
                    name={"vehicleName"}
                    value={data.vehicleName}
                    onChange={handleChangeAutoComp}
                    defaultValue={null}
                    options={props.helperVehicles}
                    error={!!errors.vehicleName}
                    helperText={errors.vehicleName}
                  />
                </Grid>
                <Grid item xs={10}>
                  <CustomNumberField
                    className={className.field}
                    autoComplete={"off"}
                    variant={"outlined"}
                    label={"Kilometraje"}
                    key={"mileage"}
                    id={"mileage"}
                    name={"mileage"}
                    type={"text"}
                    value={data.mileage}
                    error={!!errors.mileage}
                    helperText={errors.mileage}
                    onHandleChange={handleChange}
                  />
                </Grid>
                <Grid item xs={10}>
                  <CustomSwitch
                    id="maintenanceType"
                    name="maintenanceType"
                    handleChange={onChangeSwitch}
                    checkedC={data.maintenanceType}
                  />
                </Grid>{" "}
                {!data.maintenanceType ? (
                  <Grid item xs={10}>
                    <CustomAutoComplete
                      className={className.field}
                      id="preventiveMaintenanceName"
                      label="Seleccione la mantención"
                      value={data.preventiveMaintenanceName}
                      onChange={handleChangeAutoComp}
                      defaultValue={null}
                      options={props.helperMaintenances}
                      error={!!errors.preventiveMaintenanceName}
                      helperText={errors.preventiveMaintenanceName}
                    />
                  </Grid>
                ) : (
                  ""
                )}
              </>
            ) : (
              <></>
            )}

            <Grid item xs={10}>
              {!data.maintenanceType && !data.serviceType.localeCompare("Mantenedores") ? (
                <CustomField
                  variant="outlined"
                  className={className.field}
                  label="Detalles"
                  key="description"
                  id="description"
                  value={data.description}
                  error={!!errors.description}
                  helperText={errors.description}
                  onChange={handleChange}
                  fullWidth
                />
              ) : (
                <CustomField
                  variant="outlined"
                  className={className.field}
                  label="Detalles"
                  key="description"
                  id="description"
                  multiline
                  rows={5}
                  rowsMax={5}
                  value={data.description}
                  error={!!errors.description}
                  helperText={errors.description}
                  onChange={handleChange}
                  fullWidth
                />
              )}
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.toggleForm} color="primary">
          Cancelar
        </Button>
        <ButtonWithLoader
          onClick={props.submit}
          autoFocus
          buttonId={props.buttonId}
          loading={props.loading}
        >
          Confirmar
        </ButtonWithLoader>
      </DialogActions>
    </Dialog>
  )
}

export default FormModal
