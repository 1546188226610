import { InsertEmoticon } from "@material-ui/icons"
import { fUtcDateToLocal } from "utils/functions"
import { createAction, createReducer } from "utils/reducer"

const PATH = "budget/"

export const BUDGET = {
  GET_SERVICE_DETAILS: `${PATH}GET_SERVICE_DETAILS`,
  GET_SERVICE_DETAILS_SUCCESS: `${PATH}GET_SERVICE_DETAILS_SUCCESS`,
  GET_SERVICE_DETAILS_ERROR: `${PATH}GET_SERVICE_DETAILS_ERROR`,

  GET_SPARE_PARTS_COSTS: `${PATH}GET_SPARE_PARTS_COSTS`,
  GET_SPARE_PARTS_COSTS_SUCCESS: `${PATH}GET_SPARE_PARTS_COSTS_SUCCESS`,
  GET_SPARE_PARTS_COSTS_ERROR: `${PATH}GET_SPARE_PARTS_COSTS_ERROR`,

  GET_LABOR_COSTS: `${PATH}GET_LABOR_COSTS`,
  GET_LABOR_COSTS_SUCCESS: `${PATH}GET_LABOR_COSTS_SUCCESS`,
  GET_LABOR_COSTS_ERROR: `${PATH}GET_LABOR_COSTS_ERROR`,

  ADD_SPARE_PART_TO_BUDGET: `${PATH}ADD_SPARE_PART_TO_BUDGET`,
  UPDATE_SPARE_PART_IN_BUDGET: `${PATH}UPDATE_SPARE_PART_IN_BUDGET`,
  REMOVE_SPARE_PART_IN_BUDGET: `${PATH}REMOVE_SPARE_PART_IN_BUDGET`,

  ADD_LABOR_TO_BUDGET: `${PATH}ADD_LABOR_TO_BUDGET`,
  REMOVE_LABOR_IN_BUDGET: `${PATH}REMOVE_LABOR_IN_BUDGET`,
  UPDATE_LABOR_IN_BUDGET: `${PATH}UPDATE_LABOR_IN_BUDGET`,

  UPDATE_ASSISTS_INFO_IN_BUDGET: `${PATH}UPDATE_ASSISTS_INFO_IN_BUDGET`,

  UPDATE_QUOTATION_INFO: `${PATH}UPDATE_QUOTATION_INFO`,

  CREATE_BUDGET: `${PATH}CREATE_BUDGET`,
  CREATE_BUDGET_ERROR: `${PATH}CREATE_BUDGET_ERROR`,
  CREATE_BUDGET_SUCCESS: `${PATH}CREATE_BUDGET_SUCCESS`,

  CREATE_BUDGET_LABORS: `${PATH}CREATE_BUDGET_LABORS`,
  CREATE_BUDGET_LABORS_SUCCESS: `${PATH}CREATE_BUDGET_LABORS_SUCCESS`,
  CREATE_BUDGET_LABORS_ERROR: `${PATH}CREATE_BUDGET_LABORS_ERROR`,

  CREATE_BUDGET_SPARE_PARTS: `${PATH}CREATE_BUDGET_SPARE_PARTS`,
  CREATE_BUDGET_SPARE_PARTS_SUCCESS: `${PATH}CREATE_BUDGET_SPARE_PARTS_SUCCESS`,
  CREATE_BUDGET_SPARE_PARTS_ERROR: `${PATH}CREATE_BUDGET_SPARE_PARTS_ERROR`,

  GET_BUDGET: `${PATH}GET_BUDGET`,
  GET_BUDGET_SUCCESS: `${PATH}GET_BUDGET_SUCCESS`,
  GET_BUDGET_ERROR: `${PATH}GET_BUDGET_ERROR`,

  UPDATE_BUDGET: `${PATH}UPDATE_BUDGET`,
  UPDATE_BUDGET_SUCCESS: `${PATH}UPDATE_BUDGET_SUCCESS`,
  UPDATE_BUDGET_ERROR: `${PATH}UPDATE_BUDGET_ERROR`,

  RESET_QUOTATION_INFO: `${PATH}RESET_QUOTATION_INFO`,
}

export const budgetActions = {
  getService: createAction(BUDGET.GET_SERVICE_DETAILS, "controls"),
  getSparePartsCosts: createAction(BUDGET.GET_SPARE_PARTS_COSTS, "controls"),
  getLaborCosts: createAction(BUDGET.GET_LABOR_COSTS, "controls"),
  addBudgetSparePart: createAction(BUDGET.ADD_SPARE_PART_TO_BUDGET, "controls"),
  updateSparePartInBudget: createAction(BUDGET.UPDATE_SPARE_PART_IN_BUDGET, "controls"),
  removeSparePartInBudget: createAction(BUDGET.REMOVE_SPARE_PART_IN_BUDGET, "controls"),
  addBudgetLabor: createAction(BUDGET.ADD_LABOR_TO_BUDGET, "controls"),
  removeBudgetLabor: createAction(BUDGET.REMOVE_LABOR_IN_BUDGET, "controls"),
  updateBudgetLabor: createAction(BUDGET.UPDATE_LABOR_IN_BUDGET, "controls"),
  updateAssistsInfo: createAction(BUDGET.UPDATE_ASSISTS_INFO_IN_BUDGET, "controls"),
  updateQuotationInfo: createAction(BUDGET.UPDATE_QUOTATION_INFO, "controls"),
  createBudget: createAction(BUDGET.CREATE_BUDGET, "controls"),
  createBudgetLabors: createAction(BUDGET.CREATE_BUDGET_LABORS, "controls"),
  createBudgetSpareParts: createAction(BUDGET.CREATE_BUDGET_SPARE_PARTS, "controls"),
  getBudget: createAction(BUDGET.GET_BUDGET, "controls"),
  updateBudget: createAction(BUDGET.UPDATE_BUDGET, "controls"),
  resetQuotationInfo: createAction(BUDGET.RESET_QUOTATION_INFO, "controls"),
}

const initialState = {
  controls: {
    loading: false,
    getInfo: false,
    error: false,
    id: null,
    details: {},
    budgets: [],
    events: [],
    sparePartsCosts: [],
    budgetSpareParts: [],
    budgetLabors: [],
    laborCosts: [],
    quotationInfo: {
      net_value: 0,
      assists: {
        quantity: 0,
        total: 0,
      },
      vat: 0,
      total: 0,
      cotization_number: "",
      scheduler_name: "",
      vehicle_brand: "",
      date_added: "",
      date: "",
      repairment_days: "",
      order_number: "",
    },
  },
}

const budget = createReducer(initialState, {
  [BUDGET.GET_SERVICE_DETAILS](state, action) {
    state.controls.loading = true
    state.controls.getInfo = true
    state.controls.error = false
    state.controls.message = ""
    state.controls.id = action.controls.id
  },
  [BUDGET.GET_SERVICE_DETAILS_SUCCESS](state, action) {
    state.controls.loading = false
    state.controls.getInfo = false
    state.controls.error = false
    state.controls.details = action.payload.service
    state.controls.budgets = action.payload.service.budgets
    state.controls.events = action.payload.service.events
  },
  [BUDGET.GET_SERVICE_DETAILS_ERROR](state, action) {
    state.controls.loading = false
    state.controls.getInfo = false
    state.controls.error = true
    state.controls.message = ""
  },
  [BUDGET.GET_SPARE_PARTS_COSTS](state, action) {
    state.controls.loading = true
    state.controls.getInfo = true
    state.controls.error = false
    state.controls.message = ""
  },
  [BUDGET.GET_SPARE_PARTS_COSTS_SUCCESS](state, action) {
    state.controls.loading = false
    state.controls.getInfo = false
    state.controls.error = false
    state.controls.sparePartsCosts = action.payload.sparePartsCosts
  },
  [BUDGET.GET_SPARE_PARTS_COSTS_ERROR](state, action) {
    state.controls.loading = false
    state.controls.getInfo = false
    state.controls.error = true
    state.controls.message = ""
  },
  [BUDGET.GET_LABOR_COSTS](state, action) {
    state.controls.loading = true
    state.controls.getInfo = true
    state.controls.error = false
    state.controls.message = ""
  },
  [BUDGET.GET_LABOR_COSTS_SUCCESS](state, action) {
    state.controls.loading = false
    state.controls.getInfo = false
    state.controls.error = false
    state.controls.laborCosts = action.payload.laborCosts
  },
  [BUDGET.GET_LABOR_COSTS_ERROR](state, action) {
    state.controls.loading = false
    state.controls.getInfo = false
    state.controls.error = true
    state.controls.message = ""
  },
  [BUDGET.ADD_SPARE_PART_TO_BUDGET](state, action) {
    state.controls.loading = false
    state.controls.getInfo = false
    state.controls.error = false
    const found = state.controls.budgetSpareParts.some(
      (sparePart) => sparePart.id === action.controls.id
    )
    if (!found) state.controls.budgetSpareParts.push(action.controls)
  },
  [BUDGET.UPDATE_SPARE_PART_IN_BUDGET](state, action) {
    state.controls.loading = false
    state.controls.getInfo = false
    state.controls.error = false
    const upd_obj = state.controls.budgetSpareParts.findIndex((obj) => obj.id == action.controls.id)
    state.controls.budgetSpareParts[upd_obj].quantity = action.controls.quantity
    state.controls.budgetSpareParts[upd_obj].total = action.controls.total
  },
  [BUDGET.REMOVE_SPARE_PART_IN_BUDGET](state, action) {
    state.controls.loading = false
    state.controls.getInfo = false
    state.controls.error = false
    const rmv_obj = state.controls.budgetSpareParts.findIndex((obj) => obj.id == action.controls.id)
    state.controls.budgetSpareParts.splice(rmv_obj, 1)
  },
  [BUDGET.ADD_LABOR_TO_BUDGET](state, action) {
    state.controls.loading = false
    state.controls.getInfo = false
    state.controls.error = false
    if (action.controls.isWorkshop) {
      const found = state.controls.budgetLabors.some((labor) => labor.id === action.controls.id)
      if (!found) state.controls.budgetLabors.push(action.controls)
    } else state.controls.budgetLabors.push(action.controls)
  },
  [BUDGET.REMOVE_LABOR_IN_BUDGET](state, action) {
    state.controls.loading = false
    state.controls.getInfo = false
    state.controls.error = false
    const rmv_obj = state.controls.budgetLabors.findIndex((obj) => obj.id == action.controls.id)
    state.controls.budgetLabors.splice(rmv_obj, 1)
  },
  [BUDGET.UPDATE_LABOR_IN_BUDGET](state, action) {
    state.controls.loading = false
    state.controls.getInfo = false
    state.controls.error = false
    const upd_obj = state.controls.budgetLabors.findIndex((obj) => obj.id == action.controls.id)
    state.controls.budgetLabors[upd_obj].quantity = action.controls.quantity
    state.controls.budgetLabors[upd_obj].total = action.controls.total
  },
  [BUDGET.UPDATE_ASSISTS_INFO_IN_BUDGET](state, action) {
    state.controls.loading = false
    state.controls.getInfo = false
    state.controls.error = false
    state.controls.quotationInfo.assists.quantity = action.controls.quantity
    state.controls.quotationInfo.assists.total = action.controls.total
  },
  [BUDGET.UPDATE_QUOTATION_INFO](state, action) {
    state.controls.loading = false
    state.controls.getInfo = false
    state.controls.error = false
    for (const prop in action.controls) {
      state.controls.quotationInfo[prop] = action.controls[prop]
    }
    /*     state.controls.quotationInfo[Object.keys(action.controls)[0]] = Object.values(
      action.controls
    )[0] */
  },
  [BUDGET.CREATE_BUDGET](state, action) {
    state.controls.loading = true
    state.controls.error = false
  },
  [BUDGET.CREATE_BUDGET_ERROR](state, action) {
    state.controls.loading = false
    state.controls.error = true
  },
  [BUDGET.CREATE_BUDGET_SUCCESS](state, action) {
    state.controls.loading = false
    state.controls.error = false
  },
  [BUDGET.CREATE_BUDGET_LABORS](state, action) {
    state.controls.loading = true
    state.controls.error = false
  },
  [BUDGET.CREATE_BUDGET_LABORS_ERROR](state, action) {
    state.controls.loading = false
    state.controls.error = true
  },
  [BUDGET.CREATE_BUDGET_LABORS_SUCCESS](state, action) {
    state.controls.loading = false
    state.controls.error = false
  },
  [BUDGET.CREATE_BUDGET_SPARE_PARTS](state, action) {
    state.controls.loading = true
    state.controls.error = false
  },
  [BUDGET.CREATE_BUDGET_SPARE_PARTS_ERROR](state, action) {
    state.controls.loading = false
    state.controls.error = true
  },
  [BUDGET.CREATE_BUDGET_SPARE_PARTS_SUCCESS](state, action) {
    state.controls.loading = false
    state.controls.error = false
  },
  [BUDGET.GET_BUDGET](state, action) {
    state.controls.loading = true
    state.controls.error = false
  },
  [BUDGET.GET_BUDGET_SUCCESS](state, action) {
    state.controls.loading = false
    state.controls.error = false
    state.controls.details = action.payload.budget[0].service
    const budgetSpareParts = action.payload.budget[0].budget_spare_parts.map((item) => ({
      id: item.spare_parts_cost.id,
      budgetSparePart: item.id,
      quantity: item.quantity,
      total: item.price,
      price: item.spare_parts_cost.price,
      code: item.spare_parts_cost.code,
      description: item.spare_parts_cost.description,
      sparePartCostByVehicleType: [item.spare_parts_cost.sparePartCostByVehicleType]
    }))
    const budgetLabors = action.payload.budget[0].budget_labor.map((item) => ({
      id: item.labor_cost.id,
      budgetLaborId: item.id,
      total: item.price,
      quantity: item.quantity,
      price: item.labor_cost.price,
      code: item.labor_cost.code,
      manHours: item.labor_cost.manHours,
      description: item.labor_cost.description,
      laborTypeId: item.labor_cost.laborTypeId,
      laborCostByVehicleType: [item.labor_cost.laborCostByVehicleType]
    }))
    const quotationInfo = {
      assists: {
        quantity: action.payload.budget[0].assists_number,
        total:
          Number(action.payload.budget[0].assists_number) *
          process.env.REACT_APP_UNIT_VALUE_PER_ASSISTANCE,
      },
      net_value: action.payload.budget[0].net_value,
      vat: action.payload.budget[0].vat,
      total: action.payload.budget[0].total,
      cotization_number: action.payload.budget[0].cotizationNumber,
      scheduler_name: action.payload.budget[0].scheduler_name,
      vehicle_brand: action.payload.budget[0].vehicle_brand,
      repairment_days: action.payload.budget[0].repairment_days,
      order_number: action.payload.budget[0].order_number,
      date: fUtcDateToLocal(action.payload.budget[0].date),
      date_added: fUtcDateToLocal(action.payload.budget[0].date_added),
    }
    state.controls.budgetSpareParts = budgetSpareParts
    state.controls.budgetLabors = budgetLabors
    state.controls.quotationInfo = quotationInfo
  },
  [BUDGET.GET_BUDGET_ERROR](state, action) {
    state.controls.loading = false
    state.controls.error = true
  },
  [BUDGET.UPDATE_BUDGET](state, action) {
    state.controls.loading = true
    state.controls.error = false
  },
  [BUDGET.UPDATE_BUDGET_ERROR](state, action) {
    state.controls.loading = false
    state.controls.error = true
  },
  [BUDGET.UPDATE_BUDGET_SUCCESS](state, action) {
    state.controls.loading = false
    state.controls.error = false
  },
  [BUDGET.RESET_QUOTATION_INFO](state, action) {
    state.controls.quotationInfo = initialState.controls.quotationInfo
    state.controls.budgetSpareParts = initialState.controls.budgetSpareParts
    state.controls.budgetLabors = initialState.controls.budgetLabors
  },
})

export default budget
