import { APP } from "commons/reducer"
import { takeLatest, put, spawn } from "redux-saga/effects"
import apiRequest, { apiSuccess, destroy, update } from "utils/api"
import { BUDGET } from "../reducer"

function* getService() {
  yield takeLatest(BUDGET.GET_SERVICE_DETAILS, function* (action) {
    const response = yield apiRequest(`api/v1/services/${action.controls.id}`, {
      method: "get",
    })
    if (!response.error) {
      yield put(apiSuccess(BUDGET.GET_SERVICE_DETAILS_SUCCESS, response))
    } else {
      yield put(apiSuccess(BUDGET.GET_SERVICE_DETAILS_ERROR, response))
    }
  })
}

function* getSparePartsCosts() {
  yield takeLatest(BUDGET.GET_SPARE_PARTS_COSTS, function* (action) {
    const vehicleTypeId = action.controls.vehicleTypeId;

    const baseUrl = 'api/v1/spare-parts'
    const endpoint = vehicleTypeId ? `${baseUrl}?vehicleTypeId=${vehicleTypeId}` : baseUrl;

    const response = yield apiRequest(endpoint, {
      method: "get",
    })
    if (!response.error) {
      yield put(apiSuccess(BUDGET.GET_SPARE_PARTS_COSTS_SUCCESS, response))
    } else {
      yield put(apiSuccess(BUDGET.GET_SPARE_PARTS_COSTS_ERROR, response))
    }
  })
}

function* getLaborCosts() {
  yield takeLatest(BUDGET.GET_LABOR_COSTS, function* (action) {
    const vehicleTypeId = action.controls.vehicleTypeId;

    const baseUrl = 'api/v1/labor-costs'
    const endpoint = vehicleTypeId ? `${baseUrl}?vehicleTypeId=${vehicleTypeId}` : baseUrl;

    const response = yield apiRequest(endpoint, {
      method: "get",
    })

    if (!response.error) {
      yield put(apiSuccess(BUDGET.GET_LABOR_COSTS_SUCCESS, response))
    } else {
      yield put(apiSuccess(BUDGET.GET_LABOR_COSTS_ERROR, response))
    }
  })
}

function* getBudget() {
  yield takeLatest(BUDGET.GET_BUDGET, function* (action) {
    const response = yield apiRequest(`api/v1/budgets/search/${action.controls.id}`, {
      method: "get",
    })
    if (!response.error) {
      yield put(apiSuccess(BUDGET.GET_BUDGET_SUCCESS, response))
    } else {
      yield put(apiSuccess(BUDGET.GET_BUDGET_ERROR, response))
    }
  })
}

function* createBudget() {
  yield takeLatest(BUDGET.CREATE_BUDGET, function* (action) {
    const response = yield apiRequest(`api/v1/budgets/`, {
      method: "post",
      body: JSON.stringify(action.controls.budgetToStore),
    })
    if (!response.error) {
      const budgetLaborsToSave = action.controls.budgetLaborsToStore.map((item) => ({
        ...item,
        budgetId: response.budget.id,
      }))
      const budgetLaborsResponse = yield apiRequest(`api/v1/budget-labors/`, {
        method: "post",
        body: JSON.stringify(budgetLaborsToSave),
      })
      if (!budgetLaborsResponse.error) {
        const budgetSparePartsToSave = action.controls.budgetSparePartsToStore.map((item) => ({
          ...item,
          budgetId: response.budget.id,
        }))
        const budgetSparePartsSavedResponse = yield apiRequest(`api/v1/budget-spare-parts/`, {
          method: "post",
          body: JSON.stringify(budgetSparePartsToSave),
        })
        if (!budgetSparePartsSavedResponse.error) {
          yield put(apiSuccess(BUDGET.CREATE_BUDGET_SUCCESS, response))
          if (response.budget.save_status === "Borrador") {
            yield put(apiSuccess(APP.SET_SUCCESS, { msg: "¡Creación de borrador exitosa!" }))
          } else {
            yield put(apiSuccess(APP.SET_SUCCESS, response))
          }
          /* yield put(apiSuccess(APP.SET_SUCCESS, response)) */
          action.controls.history.push(`/service/${action.controls.id}`)
        } else {
          yield put(apiSuccess(BUDGET.CREATE_BUDGET_ERROR, budgetSparePartsSavedResponse))
          yield put(apiSuccess(APP.SET_ERROR, budgetSparePartsSavedResponse))
        }
      } else {
        yield put(apiSuccess(BUDGET.CREATE_BUDGET_ERROR, budgetLaborsResponse))
        yield put(apiSuccess(APP.SET_ERROR, budgetLaborsResponse))
      }
    } else {
      yield put(apiSuccess(BUDGET.CREATE_BUDGET_ERROR, response))
      yield put(apiSuccess(APP.SET_ERROR, response))
    }
  })
}

function* updateBudget() {
  yield takeLatest(BUDGET.UPDATE_BUDGET, function* (action) {
    const body = JSON.stringify(action.controls.budgetToStore)
    const budgetResponse = yield update(`api/v1/budgets/${action.controls.id}`, body)
    if (!budgetResponse.error) {
      const budgetLaborsResponse = yield destroy(
        `api/v1/budget-labors/delete-by-budget-id/${action.controls.id}`
      )
      if (!budgetLaborsResponse.error) {
        const budgetLaborsToSave = action.controls.budgetLaborsToStore.map((item) => ({
          ...item,
          budgetId: action.controls.id,
        }))
        const budgetLaborsSavedResponse = yield apiRequest(`api/v1/budget-labors/`, {
          method: "post",
          body: JSON.stringify(budgetLaborsToSave),
        })
        if (!budgetLaborsSavedResponse.error) {
          const budgetSparePartsResponse = yield destroy(
            `api/v1/budget-spare-parts/delete-by-budget-id/${action.controls.id}`
          )
          if (!budgetSparePartsResponse.error) {
            const budgetSparePartsToSave = action.controls.budgetSparePartsToStore.map((item) => ({
              ...item,
              budgetId: action.controls.id,
            }))
            const budgetSparePartsSavedResponse = yield apiRequest(`api/v1/budget-spare-parts/`, {
              method: "post",
              body: JSON.stringify(budgetSparePartsToSave),
            })
            yield put(apiSuccess(BUDGET.UPDATE_BUDGET_SUCCESS, budgetResponse))
            yield put(apiSuccess(APP.SET_SUCCESS, budgetResponse))
            action.controls.history.push(`/budgets/`)
          }
        }
      } else {
        yield put(apiSuccess(BUDGET.UPDATE_BUDGET_ERROR, budgetLaborsResponse))
        yield put(apiSuccess(APP.SET_ERROR, budgetLaborsResponse))
      }
    } else {
      yield put(apiSuccess(BUDGET.UPDATE_BUDGET_ERROR, budgetResponse))
      yield put(apiSuccess(APP.SET_ERROR, budgetResponse))
    }
  })
}

export default function* root() {
  yield spawn(getService)
  yield spawn(getSparePartsCosts)
  yield spawn(getLaborCosts)
  yield spawn(createBudget)
  yield spawn(getBudget)
  yield spawn(updateBudget)
}
