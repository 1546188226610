import commonStyles from "utils/commonStyles"

const styles = (theme) => ({
  topInfoText: {
    paddingLeft: "20px",
  },
  titleBoldText: {
    fontWeight: "bold",
    fontSize: "25px",
  },
  borderCell: {
    border: "1px solid grey",
    paddingLeft: "5px",
    paddingRight: "5px",
    fontWeight: "bold",
  },
  dividerMargin: {
    marginTop: "30px",
    marginBottom: "10px",
  },
  containerTopMargin: {
    marginTop: "20px",
  },
  bottomRight: {
    justifyContent: "flex-end",
  },
  outerColumn: {
    borderRight: "1px solid grey",
    borderBottom: "1px solid grey",
    borderLeft: "1px solid grey",
  },
  centerColumn: {
    borderBottom: "1px solid grey",
  },
  tableHeader: {
    backgroundColor: "#C5DFB7",
    paddingLeft: "5px",
    fontWeight: "bold",
  },
  alignedRight: {
    paddingRight: "5px",
  },
  alignedLeft: {
    paddingLeft: "5px",
  },
  leftCell: {
    borderLeft: "1px solid grey",
    paddingLeft: "5px",
  },
  rightCell: {
    borderRight: "1px solid grey",
    paddingRight: "5px",
  },
  alignedRightBold: {
    fontWeight: "bold",
    paddingRight: "5px",
  },
  buttonContained: {
    color: "white",
    borderColor: "#185517",
    backgroundColor: "#185517",
    marginTop: "20px",
    marginBottom: "20px",
  },
  fontSize: {
    fontSize: "12px",
  },
  boldText: {
    fontWeight: "bold",
  },
  signatureStyle: {
    fontSize: "11px",
    marginTop: "30px",
    marginBottom: "30px",
  },
})

export default styles
