import React from "react"

// Material
import { Box, Grid, Card, CardContent, Container, Typography } from "@material-ui/core"

//Components
import TableDocuments from "./TableDocuments"

//Utils
import ContractHeader from "commons/components/ContractHeader"

const ContractDocumentsComponent = (props) => {
  const { classes } = props

  return (
    <Container className={classes.container} maxWidth={false}>
      <Grid
        container
        spacing={4}
        justifyContent="center"
        alignItems="center"
        className={classes.container}
      >
        <ContractHeader />

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Box marginBottom="30px">
                <Typography variant="h3" component="div">
                  Documentos asociados al contrato
                </Typography>
              </Box>
              <TableDocuments />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  )
}

export default ContractDocumentsComponent
