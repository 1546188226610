import React from "react"
import { Typography } from "@material-ui/core"
import CustomDateGrid from "commons/components/CustomDateGrid"
import CirculeBadge from "commons/components/CirculeBadge"
import moment from "moment"

import { fCurrency, fDateUtc } from "utils/functions"
import TableActions from "./Actions"

const TableBudgets = ({
  className,
  data,
  serviceStatus,
  onModal,
  onModalEdit,
  onModalBudgetAccept,
  onModalBudgetReject,
}) => {
  const getStateColor = (state) => {
    switch (state) {
      case "Aprobado":
        return "success"
        break
      case "Rechazado":
        return "reject"
        break
      case "En espera":
        return "hold"
        break
      default:
        return "wait"
        break
    }
  }

  const columns = [
    {
      field: "cotizationNumber",
      headerName: "N° Cotización",
      flex: 1,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Typography component="div">
          <div className="flex-section">
            <CirculeBadge type={getStateColor(params.row?.status)} badgeContent={""} />
            {params.row?.cotizationNumber}
          </div>
        </Typography>
      ),
    },
    {
      field: "date",
      headerName: "Fecha",
      flex: 1,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Typography component="div">
          <div className="flex-section">
            <Typography variant="body2" className={className.p}>
              {fDateUtc(params.getValue("date"))}
            </Typography>
          </div>
        </Typography>
      ),
    },
    {
      field: "total",
      headerName: "Monto total",
      flex: 1,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Typography component="div">
          <div className="flex-section">
            <p>{fCurrency.format(params.getValue("total"))}</p>
          </div>
        </Typography>
      ),
    },
    {
      field: "Actions",
      headerName: "Acciones",
      flex: 1,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Typography component="div">
          <div className="flex-section">
            <TableActions
              id={params.row?.id}
              withFiles={params.row?.files?.length > 0}
              cotizationNumber={params.row?.cotizationNumber}
              status={params.row?.status}
              serviceStatus={serviceStatus}
              handleOpenModal={onModal}
              handleOpenModalEdit={onModalEdit}
              handleOpenModalModelBudgetAccept={onModalBudgetAccept}
              handleOpenModalBudgetRecject={onModalBudgetReject}
            />
          </div>
        </Typography>
      ),
    },
  ]

  return (
    <div>
      <CustomDateGrid rows={data} columns={columns} height="255px" maxRows={4} />
    </div>
  )
}

export default TableBudgets
