// @ts-nocheck
import React from "react"

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core"

import ButtonWithLoader from "commons/containers/ButtonWithLoader"
import DatePicker from "commons/components/DatePicker"
import CustomField from "commons/components/CustomField"
import CustomNumberField from "commons/components/CustomNumberField"
import MultifileField from "commons/components/MultifileField"
import FileCopyIcon from "@material-ui/icons/FileCopy"
import Parrafo from "commons/components/Parrafo"
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline"

const FormModalBudget = (props) => {
  const { classes, style, onChange, submit, data, errors } = props

  const handleChange = (event) => {
    onChange(event)
  }

  const renderAttachFiles = () => {
    return !!props?.data?.currentFiles?.length ? (
      props?.data?.currentFiles.map((file, key) => (
        <p key={key}>
          <a target="_blank" rel="noopener noreferrer" href={file.path}>
            <FileCopyIcon />
            {file.name}
            <RemoveCircleOutlineIcon
              onClick={() => {
                removeFile(key)
              }}
            />
          </a>
        </p>
      ))
    ) : (
      <p>Sin archivos adjuntos</p>
    )
  }

  const removeFile = () => {}

  return (
    <Dialog
      fullWidth
      onClose={props.toggleForm}
      aria-labelledby="simple-dialog-title"
      open={props.isOpen}
    >
      <DialogTitle>{props.edit ? "Actualizar" : "Ingresar"} presupuesto</DialogTitle>
      <DialogContent className={style}>
        <form onSubmit={submit}>
          <Grid container spacing={1} justify="center">
            <Grid item xs={10}>
              <DatePicker
                variant="outlined"
                className={classes.field}
                onChange={handleChange}
                id={"date"}
                name={"date"}
                label={"Fecha del presupuesto"}
                value={data.date}
                error={!!errors.date}
                helperText={errors.date}
                fullWidth={true}
              />
            </Grid>

            <Grid item xs={10}>
              <CustomField
                variant="outlined"
                className={classes.field}
                label="Número de cotización"
                key="cotizationNumber"
                id="cotizationNumber"
                value={data.cotizationNumber}
                error={!!errors.cotizationNumber}
                helperText={errors.cotizationNumber}
                onChange={handleChange}
                fullWidth
              />
            </Grid>

            {/* <Grid item xs={10}>
              <CustomField
                variant="outlined"
                className={classes.field}
                label="Número SAP"
                key="sapNumber"
                id="sapNumber"
                value={data.sapNumber}
                error={!!errors.sapNumber}
                helperText={errors.sapNumber}
                onChange={handleChange}
                fullWidth
              />
            </Grid> */}

            <Grid item xs={10}>
              <CustomNumberField
                variant={"outlined"}
                className={classes.field}
                label={"Total del presupuesto"}
                key={"total"}
                id={"total"}
                name={"total"}
                type={"text"}
                value={data.total}
                error={!!errors.total}
                helperText={errors.total}
                onHandleChange={handleChange}
              />
            </Grid>

            <Grid item xs={10}>
              <CustomField
                variant="outlined"
                className={classes.field}
                label="Comentario"
                key="commentary"
                id="commentary"
                multiline
                rows={5}
                rowsMax={5}
                value={data.commentary}
                error={!!errors.commentary}
                helperText={errors.commentary}
                onChange={handleChange}
                fullWidth
              />
            </Grid>

            {/* {props.edit ? (
              <Grid item xs={10}>
                <Parrafo title={"Archivos actualmente adjuntos"} value={""} />
                {renderAttachFiles()}
              </Grid>
            ) : (
              ""
            )} */}

            {!props.edit ? (
              <Grid item xs={10}>
                <MultifileField
                  variant="outlined"
                  className={classes.field}
                  label="Archivos"
                  name="files"
                  key="files"
                  id="files"
                  value={data.files}
                  error={!!errors.files}
                  helperText={errors.files}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.toggleForm} color="primary">
          Cancelar
        </Button>
        <ButtonWithLoader
          onClick={props.submit}
          autoFocus
          buttonId={props.buttonId}
          loading={props.loading}
        >
          {props.edit ? "Actualizar" : "Confirmar"}
        </ButtonWithLoader>
      </DialogActions>
    </Dialog>
  )
}

export default FormModalBudget
