import React from "react"
import { CardMedia, Typography, Grid } from "@material-ui/core"
import CustomDateGrid from "commons/components/CustomDateGrid"
import CirculeBadge from "commons/components/CirculeBadge"
import moment from "moment"

import { capitalize, fCurrency, fDateUtc, fNumber } from "utils/functions"
import MC_36 from "utils/images/MC_36.png"
import MP_36 from "utils/images/MP_36.png"
import RP_36 from "utils/images/RP_36.png"

import TableActions from "./Actions"
import TableLeyend from "./Leyend"
import TableFilter from "../Filters"
import Parrafo from "commons/components/Parrafo"
import Skeleton from "@material-ui/lab/Skeleton"

const TableServices = ({
  className,
  data,
  executed_budget_num,
  executed_budget_porcentual,
  leftover_executed_budget_num,
  loading,
  onFilter,
  onModalEdit,
  onModalReject,
  onModalApprovated,
  onModalBudgetAccept,
  onModalBudgetReject,
  helperFilters,
}) => {
  const getStateColor = (state) => {
    switch (state) {
      case "Aprobado":
        return "success"
        break
      case "Rechazado":
        return "reject"
        break
      case "En espera":
        return "hold"
        break
      default:
        return "wait"
        break
    }
  }

  const getMaintenanceType = (type, maintenance) => {
    switch (type) {
      case "preventiva":
        return maintenance?.preventiveMaintenance?.name
        break
      case "correctiva":
        return maintenance?.description
        break
      default:
        return maintenance?.description
        break
    }
  }

  const getStateBudget = (status) => {
    return status ?? "Sin presupuesto"
  }

  const renderStatusInvoice = (statusInvoice) => {
    switch (statusInvoice) {
      case "Facturado":
        return (
          <Typography variant="body2" className={className.p} style={{ color: "black" }}>
            {statusInvoice}
          </Typography>
        )
      case "No facturado":
        return (
          <Typography variant="body2" className={className.p} style={{ color: "#d2d2d2" }}>
            {statusInvoice}
          </Typography>
        )
    }
  }

  const columns = [
    {
      field: "date",
      headerName: "Fecha solicitada",
      flex: 0.80,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Typography component="div">
          <div className="flex-section">
            <p>{fDateUtc(params.getValue("date"))}</p>
          </div>
        </Typography>
      ),
    },
    {
      field: "cotizationNumber",
      headerName: "N° Cotización",
      flex: 0.75,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Typography component="div">
          <div className="flex-section">
            <Typography variant="body2" className={className.p}>
              {params?.row?.budgets[0]?.cotizationNumber
                ? `${params?.row?.budgets[0]?.cotizationNumber}`
                : "--"}
            </Typography>
          </div>
        </Typography>
      ),
    },
    {
      field: "workshop",
      headerName: "Taller",
      flex: 0.75,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Typography component="div">
          <div className="flex-section">{params.row?.workshop?.name}</div>
        </Typography>
      ),
    },
    {
      field: "vehicle",
      headerName: "Vehículo",
      flex: 0.75,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Typography component="div">
          <div className="flex-section">
            {params.row?.vehicle ? params.row?.vehicle?.plateNumber : "--"}
          </div>
        </Typography>
      ),
    },
    {
      field: "status",
      headerName: "Estado del servicio",
      flex: 0.75,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Typography component="div">
          <div className="flex-section">
            <CirculeBadge type={getStateColor(params.getValue("status"))} />
            <Typography variant="body2" className={className.p}>
              {params.getValue("status")}
            </Typography>
          </div>
        </Typography>
      ),
    },
    {
      field: "budgets",
      headerName: "Presupuesto",
      flex: 1.25,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Typography component="div">
          <div className="flex-section">
            <CirculeBadge
              type={getStateColor(params?.row?.budgets[0]?.status)}
              styleCircule={"under"}
            />
            {getStateBudget(params?.row?.budgets[0]?.status)}
          </div>
        </Typography>
      ),
    },
    {
      field: "mileage",
      headerName: "Mantención",
      flex: 1.25,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Typography component="div">
          <div className="flex-section">
            <CardMedia
              className={className.icon}
              image={
                !params?.row?.type?.localeCompare("entrega de repuestos")
                  ? RP_36
                  : params?.row?.preventiveMaintenance
                  ? MP_36
                  : MC_36
              }
              title={capitalize(params?.row?.maintenanceType)}
            />
            {getMaintenanceType(params?.row?.maintenanceType, params?.row)}
          </div>
        </Typography>
      ),
    },
    {
      field: "Total",
      headerName: "Total presup.",
      flex: 0.75,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Typography component="div">
          <div className="flex-section">
            {params?.row?.budgets[0]?.total
              ? fCurrency.format(params?.row?.budgets[0]?.total)
              : "--"}
          </div>
        </Typography>
      ),
    },
    {
      field: "hasInvoices",
      headerName: "Facturación",
      flex: 0.75,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Typography component="div">
          <div className="flex-section">{renderStatusInvoice(params.getValue("hasInvoices"))}</div>
        </Typography>
      ),
    },
    {
      field: "Actions",
      headerName: "Acciones",
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Typography component="div">
          <div className="flex-section">
            <TableActions
              id={params.row?.id}
              budgetId={params.row?.budgets[0]?.id}
              status={params.row?.status}
              budgetStatus={params.row?.budgets[0]?.status ?? ""}
              handleOpenEditModal={onModalEdit}
              handleOpenModalReject={onModalReject}
              handleOpenModalAccept={onModalApprovated}
              handleOpenModalModelBudgetAccept={onModalBudgetAccept}
              handleOpenModalBudgetRecject={onModalBudgetReject}
            />
          </div>
        </Typography>
      ),
      flex: 0.75,
    },
  ]

  return (
    <>
      <TableFilter
        className={{
          button: className.button,
          containerFilter: className.containerFilter,
        }}
        onFilter={onFilter}
        loading={loading}
        helperFilters={helperFilters}
      />
      {loading ? (
        <Skeleton variant="rect" height={"400px"} />
      ) : (
        <>
          <CustomDateGrid rows={data} columns={columns} maxRows={8} />
          <TableLeyend className={className} MP_36={MP_36} MC_36={MC_36} />
        </>
      )}
    </>
  )
}

export default TableServices
