import React from "react"
import { DataGrid } from "@material-ui/data-grid"
import CustomNoRowsOverlay from "commons/components/IconNotRows"
import { makeStyles } from "@material-ui/core"

const CustomDateGrid = ({ rows, columns, maxRows, height }) => {
  const useStyles = makeStyles({
    root: {
      "& .super-app-theme--header": {
        backgroundColor: "rgb(24 85 23)",
        color: "white",
      },
    },
  })

  const classes = useStyles()

  return (
    <div style={{ height: `${height}`, width: "100%" }} className={classes.root}>
      <DataGrid
        components={{
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
        disableColumnMenu={true}
        density="compact"
        pageSize={maxRows}
        scrollbarSize={maxRows}
        columns={columns}
        rows={rows}
      />
    </div>
  )
}

CustomDateGrid.defaultProps = {
  rows: [],
  columns: [],
  maxRows: 8,
  height: "400px",
}

export default CustomDateGrid
