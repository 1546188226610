// @ts-nocheck
import React from "react"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { compose } from "redux"
// Project
import { selectState } from "utils/reduxActions"
import { ReactReduxContext } from "config/configureStore"
// material
import CircularProgress from "@material-ui/core/CircularProgress"
import Button from "@material-ui/core/Button"

const ButtonWithLoader = (props) => {
  const loading = props.loading
  // const loading = props.loaders[props.buttonId];

  return (
    <Button
      color={props.color || "primary"}
      onClick={props.onClick}
      variant="contained"
      disabled={props.disabled || loading}
      startIcon={loading ? <CircularProgress size={15} /> : props.startIcon}
      autoFocus={props.autoFocus}
      fullWidth={props.fullWidth}
      className={props.className}
      size={props.size}
    >
      {props.children}
    </Button>
  )
}

const withConnect = connect(selectState("app.loaders"), null, null, {
  context: ReactReduxContext,
})

export default compose(withRouter, withConnect)(ButtonWithLoader)
