import { createAction, createReducer } from "utils/reducer"

// Local constants
const PATH = "maintainer/workshop/"

const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`
const SET_INVALID_CONTROLS = `${PATH}SET_INVALID_CONTROLS`

// Global constants for saga
export const MAINTAINERWORKSHOP = {
  GET_STATE_FROM_API: `${PATH}GET_STATE_FROM_API`,
  GET_STATE_FROM_API_SUCCESS: `${PATH}GET_STATE_FROM_API_SUCCESS`,
  POST_ADD_WORKSHOP: `${PATH}POST_ADD_WORKSHOP`,
  POST_ADD_WORKSHOP_SUCCESS: `${PATH}POST_ADD_WORKSHOP_SUCCESS`,
  POST_ADD_WORKSHOP_ERROR: `${PATH}POST_ADD_WORKSHOP_ERROR`,
  POST_UPDATE_WORKSHOP: `${PATH}POST_UPDATE_WORKSHOP`,
  POST_UPDATE_WORKSHOP_SUCCESS: `${PATH}POST_UPDATE_WORKSHOP_SUCCESS`,
  POST_UPDATE_WORKSHOP_ERROR: `${PATH}POST_UPDATE_WORKSHOP_ERROR`,
  DELETE_WORKSHOP: `${PATH}DELETE_WORKSHOP`,
  DELETE_WORKSHOP_SUCCESS: `${PATH}DELETE_WORKSHOP_SUCCESS`,
  DELETE_WORKSHOP_ERROR: `${PATH}DELETE_WORKSHOP_ERROR`,

  TOGGLE_CREATE_MODAL: `${PATH}TOGGLE_CREATE_MODAL`,
  TOGGLE_UPDATED_MODAL: `${PATH}TOGGLE_UPDATED_MODAL`,
  TOGGLE_DELETED_MODAL: `${PATH}TOGGLE_DELETED_MODAL`,
}

// actions
export const maintainerWorkshopActions = {
  getStateFromApi: createAction(MAINTAINERWORKSHOP.GET_STATE_FROM_API),
  addWorkshop: createAction(MAINTAINERWORKSHOP.POST_ADD_WORKSHOP, "controls"),
  updateWorkshop: createAction(MAINTAINERWORKSHOP.POST_UPDATE_WORKSHOP, "id", "controls"),
  deleteWorkshop: createAction(MAINTAINERWORKSHOP.DELETE_WORKSHOP, "id"),

  toggleCreateModal: createAction(MAINTAINERWORKSHOP.TOGGLE_CREATE_MODAL, "item"),
  toggleUpdatedModal: createAction(MAINTAINERWORKSHOP.TOGGLE_UPDATED_MODAL, "item"),
  toggleDeletedModal: createAction(MAINTAINERWORKSHOP.TOGGLE_DELETED_MODAL, "id"),
  changeControls: createAction(CHANGE_CONTROLS, "controls", "invalidControls"),
  setInvalidControls: createAction(SET_INVALID_CONTROLS, "controls"),
}

const defaultControls = {
  name: "",
}
const defaultInvalidControls = {
  name: false,
}

const initialState = {
  formControls: {
    name: "",
  },
  invalidControls: {
    plateNumber: false,
    model: false,
    vin: false,
  },
  controls: {
    toDeleteId: "",
    toEditId: "",
    loading: false,
    error: false,
    message: "",
  },
  modal: {
    form: {
      status: false,
    },
    deleted: {
      status: false,
    },
  },
  edit: false,
}

const maintainerWorkshop = createReducer(initialState, {
  [CHANGE_CONTROLS](state, action) {
    state.formControls = {
      ...state.formControls,
      ...action.controls,
    }
    state.invalidControls = {
      ...state.invalidControls,
      ...(action.invalidControls || {}),
    }
  },
  [SET_INVALID_CONTROLS](state, action) {
    state.invalidControls = {
      ...state.invalidControls,
      ...action.controls,
    }
  },
  [MAINTAINERWORKSHOP.POST_ADD_WORKSHOP](state) {
    state.controls.loading = true
    state.controls.error = false
    state.controls.message = ""
  },
  [MAINTAINERWORKSHOP.POST_ADD_WORKSHOP_SUCCESS](state, action) {
    state.controls.loading = false
    state.controls.error = false
    state.controls.message = "Taller agregado al sistema."
    state.controls.toDeleteId = ""
    state.controls.toEditId = ""
    state.modal.form.status = !state.modal.form.status
  },
  [MAINTAINERWORKSHOP.POST_ADD_WORKSHOP_ERROR](state, action) {
    state.controls.loading = false
    state.controls.error = true
    state.controls.message = "."
  },

  [MAINTAINERWORKSHOP.POST_UPDATE_WORKSHOP](state) {
    state.controls.loading = true
    state.controls.error = false
  },
  [MAINTAINERWORKSHOP.POST_UPDATE_WORKSHOP_SUCCESS](state, action) {
    state.controls.loading = false
    state.controls.error = false
    state.controls.message = "Los datos del taller fueron actualizados con exito."
    state.controls.toDeleteId = ""
    state.controls.toEditId = ""
    state.modal.form.status = !state.modal.form.status
  },
  [MAINTAINERWORKSHOP.POST_UPDATE_WORKSHOP_ERROR](state, action) {
    state.controls.loading = false
    state.controls.error = true
    state.controls.message = "."
  },

  [MAINTAINERWORKSHOP.DELETE_WORKSHOP](state, action) {
    state.controls.loading = true
    state.deleteIsOpen = false
    state.toDeleteId = ""
  },
  [MAINTAINERWORKSHOP.DELETE_WORKSHOP_SUCCESS](state, action) {
    state.controls.loading = false
    state.controls.error = false
    state.controls.message = "El taller fue eliminado con exito."
    state.controls.toDeleteId = ""
    state.controls.toEditId = ""
    state.modal.deleted.status = !state.modal.deleted.status
  },
  [MAINTAINERWORKSHOP.DELETE_WORKSHOP_ERROR](state, action) {
    state.controls.loading = false
    state.controls.error = true
    state.controls.message = "."
  },

  [MAINTAINERWORKSHOP.TOGGLE_CREATE_MODAL](state) {
    state.controls.toDeleteId = ""
    state.controls.toEditId = ""
    state.formControls = { ...defaultControls }
    state.invalidControls = { ...defaultInvalidControls }
    state.modal.form.status = !state.modal.form.status
    state.edit = false
  },
  [MAINTAINERWORKSHOP.TOGGLE_UPDATED_MODAL](state, action) {
    state.invalidControls = { ...defaultInvalidControls }
    state.controls.toDeleteId = ""
    state.controls.toEditId = action?.item?.id || ""
    state.formControls.name = action?.item?.name
    state.modal.form.status = !state.modal.form.status
    state.edit = true
  },
  [MAINTAINERWORKSHOP.TOGGLE_DELETED_MODAL](state, action) {
    state.controls.toDeleteId = action?.id || ""
    state.controls.toEditId = ""
    state.modal.deleted.status = !state.modal.deleted.status
  },
})

export default maintainerWorkshop
