import React from "react"
import { CircularProgress, Typography } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import Alert from "@material-ui/lab/Alert"
import IconButton from "@material-ui/core/IconButton"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"

const CustomField = TextField

const recoveryForm = (props) => {
  const { history, classes, onChange, onClickVerifyEmail } = props

  return (
    <form onSubmit={onClickVerifyEmail}>
      <Grid container spacing={3} justify="center">
        <Grid item xs={10}>
          <Typography variant="body1" component="div">
            Por favor, ingrese su correo para recuperar su contraseña
          </Typography>
        </Grid>

        <Grid item xs={10}>
          <CustomField
            className={classes.field}
            label="Correo electrónico"
            key="email"
            id="email"
            value={props.email}
            onChange={onChange}
            style={{ paddingBottom: "8px" }}
            fullWidth
            error={!!props.errors.email}
            helperText={props.errors.email}
          />
        </Grid>

        <Grid
          item
          xs={10}
          container
          justify="space-between"
          alignItems="center"
          className={classes.textAlign}
        >
          <IconButton edge="start" onClick={() => history.goBack()}>
            <ArrowBackIcon />
          </IconButton>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ marginTop: "5px" }}
            className={classes.button}
          >
            {props.controls.loading ? <CircularProgress className={classes.progress} /> : "Enviar"}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default recoveryForm
