import { createAction, createReducer } from "utils/reducer"

// Local constants
const PATH = "maintainer/eventtype/"

const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`
const SET_INVALID_CONTROLS = `${PATH}SET_INVALID_CONTROLS`

// Global constants for saga
export const MAINTAINEREVENTTYPE = {
  GET_STATE_FROM_API: `${PATH}GET_STATE_FROM_API`,
  GET_STATE_FROM_API_SUCCESS: `${PATH}GET_STATE_FROM_API_SUCCESS`,
  POST_ADD_EVENTTYPE: `${PATH}POST_ADD_EVENTTYPE`,
  POST_ADD_EVENTTYPE_SUCCESS: `${PATH}POST_ADD_EVENTTYPE_SUCCESS`,
  POST_ADD_EVENTTYPE_ERROR: `${PATH}POST_ADD_EVENTTYPE_ERROR`,
  POST_UPDATE_EVENTTYPE: `${PATH}POST_UPDATE_EVENTTYPE`,
  POST_UPDATE_EVENTTYPE_SUCCESS: `${PATH}POST_UPDATE_EVENTTYPE_SUCCESS`,
  POST_UPDATE_EVENTTYPE_ERROR: `${PATH}POST_UPDATE_EVENTTYPE_ERROR`,
  DELETE_EVENTTYPE: `${PATH}DELETE_EVENTTYPE`,
  DELETE_EVENTTYPE_SUCCESS: `${PATH}DELETE_EVENTTYPE_SUCCESS`,
  DELETE_EVENTTYPE_ERROR: `${PATH}DELETE_EVENTTYPE_ERROR`,

  TOGGLE_CREATE_MODAL: `${PATH}TOGGLE_CREATE_MODAL`,
  TOGGLE_UPDATED_MODAL: `${PATH}TOGGLE_UPDATED_MODAL`,
  TOGGLE_DELETED_MODAL: `${PATH}TOGGLE_DELETED_MODAL`,
}

// actions
export const maintainerEventTypeActions = {
  getStateFromApi: createAction(MAINTAINEREVENTTYPE.GET_STATE_FROM_API),
  addEventType: createAction(MAINTAINEREVENTTYPE.POST_ADD_EVENTTYPE, "controls"),
  updateEventType: createAction(MAINTAINEREVENTTYPE.POST_UPDATE_EVENTTYPE, "id", "controls"),
  deleteEventType: createAction(MAINTAINEREVENTTYPE.DELETE_EVENTTYPE, "id"),

  toggleCreateModal: createAction(MAINTAINEREVENTTYPE.TOGGLE_CREATE_MODAL, "item"),
  toggleUpdatedModal: createAction(MAINTAINEREVENTTYPE.TOGGLE_UPDATED_MODAL, "item"),
  toggleDeletedModal: createAction(MAINTAINEREVENTTYPE.TOGGLE_DELETED_MODAL, "id"),
  changeControls: createAction(CHANGE_CONTROLS, "controls", "invalidControls"),
  setInvalidControls: createAction(SET_INVALID_CONTROLS, "controls"),
}

const defaultControls = {
  name: "",
}

const defaultInvalidControls = {
  name: false,
}

const initialState = {
  formControls: {
    name: "",
  },
  invalidControls: {
    name: "",
  },
  controls: {
    toDeleteId: "",
    toEditId: "",
    loading: false,
    error: false,
    message: "",
  },
  modal: {
    form: {
      status: false,
    },
    deleted: {
      status: false,
    },
  },
  edit: false,
}

const maintainerEventType = createReducer(initialState, {
  [CHANGE_CONTROLS](state, action) {
    state.formControls = {
      ...state.formControls,
      ...action.controls,
    }
    state.invalidControls = {
      ...state.invalidControls,
      ...(action.invalidControls || {}),
    }
  },
  [SET_INVALID_CONTROLS](state, action) {
    state.invalidControls = {
      ...state.invalidControls,
      ...action.controls,
    }
  },
  [MAINTAINEREVENTTYPE.POST_ADD_EVENTTYPE](state) {
    state.controls.loading = true
    state.controls.error = false
    state.controls.message = ""
  },
  [MAINTAINEREVENTTYPE.POST_ADD_EVENTTYPE_SUCCESS](state, action) {
    state.controls.loading = false
    state.controls.error = false
    state.controls.message = "Tipo de evento agregado al sistema."
    state.controls.toDeleteId = ""
    state.controls.toEditId = ""
    state.modal.form.status = !state.modal.form.status
  },
  [MAINTAINEREVENTTYPE.POST_ADD_EVENTTYPE_ERROR](state, action) {
    state.controls.loading = false
    state.controls.error = true
    state.controls.message = "."
  },

  [MAINTAINEREVENTTYPE.POST_UPDATE_EVENTTYPE](state) {
    state.controls.loading = true
    state.controls.error = false
  },
  [MAINTAINEREVENTTYPE.POST_UPDATE_EVENTTYPE_SUCCESS](state, action) {
    state.controls.loading = false
    state.controls.error = false
    state.controls.message = "Los datos del tipo de evento fueron actualizados con exito."
    state.controls.toDeleteId = ""
    state.controls.toEditId = ""
    state.modal.form.status = !state.modal.form.status
  },
  [MAINTAINEREVENTTYPE.POST_UPDATE_EVENTTYPE_ERROR](state, action) {
    state.controls.loading = false
    state.controls.error = true
    state.controls.message = "."
  },

  [MAINTAINEREVENTTYPE.DELETE_EVENTTYPE](state, action) {
    state.controls.loading = true
    state.deleteIsOpen = false
    state.toDeleteId = ""
  },
  [MAINTAINEREVENTTYPE.DELETE_EVENTTYPE_SUCCESS](state, action) {
    state.controls.loading = false
    state.controls.error = false
    state.controls.message = "El Typo de evento fue eliminado con exito."
    state.controls.toDeleteId = ""
    state.controls.toEditId = ""
    state.modal.deleted.status = !state.modal.deleted.status
  },
  [MAINTAINEREVENTTYPE.DELETE_EVENTTYPE_ERROR](state, action) {
    state.controls.loading = false
    state.controls.error = true
    state.controls.message = "."
  },

  [MAINTAINEREVENTTYPE.TOGGLE_CREATE_MODAL](state) {
    state.controls.toDeleteId = ""
    state.controls.toEditId = ""
    state.formControls = { ...defaultControls }
    state.invalidControls = { ...defaultInvalidControls }
    state.modal.form.status = !state.modal.form.status
    state.edit = false
  },
  [MAINTAINEREVENTTYPE.TOGGLE_UPDATED_MODAL](state, action) {
    state.invalidControls = { ...defaultInvalidControls }
    state.controls.toDeleteId = ""
    state.controls.toEditId = action?.item?.id || ""
    state.formControls.name = action?.item?.name
    state.modal.form.status = !state.modal.form.status
    state.edit = true
  },
  [MAINTAINEREVENTTYPE.TOGGLE_DELETED_MODAL](state, action) {
    state.controls.toDeleteId = action?.id || ""
    state.controls.toEditId = ""
    state.modal.deleted.status = !state.modal.deleted.status
  },
})

export default maintainerEventType
