import React from "react"
import "./Spinner.css"

export default (props) => {
  return (
    <div
      id="spinner-modal-background"
      className={props.show ? "show-spinner" : "hidden-spinner"}
    >
      <div className="content-spinner">
        <p>{props.text}</p>
      </div>
    </div>
  )
}
