import * as React from "react"
import NumberFormat from "react-number-format"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import { fNumber, truthty } from "utils/functions"

const useStyles = makeStyles((theme) => ({
  root: {
    // paddingBottom: "10px",
    "& > *": {
      //   margin: theme.spacing(1),
    },
  },
}))

const CustomNumberField = (props) => {
  const classes = useStyles()
  const [value, setValue] = React.useState("")
  const { onHandleChange, ...filterProps } = props

  React.useEffect(() => {
    if (truthty(props.value)) {
      setValue(props.value)
    } else {
      setValue("")
    }
  }, [props.value])

  const handleChange = (event) => {
    setValue(event.target.value)
    props.onHandleChange(event, event.target.value)
  }

  return (
    <div className={props.className}>
      <NumberFormat
        {...filterProps}
        className={props.className}
        label={props.label}
        name={props.name}
        key={`${props.label}-key`}
        id={props.id}
        customInput={TextField}
        onValueChange={(values) => {
          handleChange({
            persist: () => {},
            target: {
              type: "number",
              id: props.id,
              name: props.name,
              value: isNaN(parseFloat(values?.value)) ? "" : parseFloat(values?.value),
            },
          })
        }}
        error={props.error}
        helperText={props.helperText}
        thousandSeparator={"."}
        decimalSeparator={","}
        prefix={!props?.type?.toString().localeCompare("$") ? "$" : ""}
        fullWidth
      />
    </div>
  )
}
export default CustomNumberField
