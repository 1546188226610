import { validateEmail } from "./functions"

const passwordRegex = /^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*)$/

export const VALIDATE = {
  REQUIRED: (value) => value != null && value.toString().trim().length > 0,
  PASSWORD: (value) => VALIDATE.MIN_LENGTH(value, 6) && VALIDATE.MAX_LENGTH(value, 20),
  SIGNUP_PASSWORD: (value) => {
    const reg = passwordRegex.test(value)
    return VALIDATE.MIN_LENGTH(value, 6) && VALIDATE.MAX_LENGTH(value, 20) && reg
  },
  MIN_LENGTH: (value, length) =>
    VALIDATE.REQUIRED(value) && value.toString().trim().length >= length,
  MAX_LENGTH: (value, length) =>
    VALIDATE.REQUIRED(value) && value.toString().trim().length <= length,
  LENGTH: (value, min, max) => VALIDATE.MIN_LENGTH(value, min) && VALIDATE.MAX_LENGTH(value, max),
  EMAIL: (value) => validateEmail(value),
  NUMBER: (value) => !isNaN(value),
}

export const VALIDATIONS = {
  REQUIRED: { check: VALIDATE.REQUIRED, message: "Campo requerido" },
  PASSWORD: {
    check: VALIDATE.PASSWORD,
    message: "Campo requerido, entre 6 y 20 carácteres",
  },
  SIGNUP_PASSWORD: {
    check: VALIDATE.SIGNUP_PASSWORD,
    message: "Campo requerido, entre 6 y 20 carácteres. Min. 1 mayúscula, minúscula y número",
  },
  MIN_LENGTH: (length) => ({
    check: (value) => VALIDATE.MIN_LENGTH(value, length),
    message: `Mínimo ${length} carácteres`,
  }),
  MAX_LENGTH: (length) => ({
    check: (value) => VALIDATE.MAX_LENGTH(value, length),
    message: `Máximo ${length} carácteres`,
  }),
  LENGTH: (min, max) => ({
    check: (value) => VALIDATE.LENGTH(value, min, max),
    message: `Campo debe tener entre ${min} y ${max} carácteres`,
  }),
  IS_EMAIL: { check: VALIDATE.EMAIL, message: "Debe ser un email válido" },
  IS_NUMBER: { check: VALIDATE.NUMBER, message: "Debe ser un número válido" },
}
